import { RiFolderSettingsLine } from '@remixicon/react';
import {
	App,
	Button,
	ColorPicker,
	DatePicker,
	Flex,
	Form,
	Input,
	Modal,
	Radio,
	Spin,
	TreeSelectProps,
	Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { useGetClassifiersGroups } from 'entities/classifiers/classifiersGroups/classifier.model';
import { TaskType, useAsyncOperation } from 'entities/events';
import { useGetCurrentMeausureGroup } from 'entities/metadata/measures';
import {
	ClassifierAttributeDeclarationResponse,
	useLazyGetCatalogItemsQuery,
	useLazyGetClassifierItemsQuery, useUpdateClassifierAttributeDeclarationAsyncMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper, isEmpty } from 'shared/helpers';
import { translateType } from 'shared/helpers/CellTypeParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { ItemValues } from 'shared/helpers/types';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { DropdownLink, DropdownSelect } from 'shared/ui';
import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';

interface ISetupClassifierAttribute {
	attributeDeclaration: ClassifierAttributeDeclarationResponse;
}

const boolOptions = [
	{ label: 'Правда', value: true },
	{ label: 'Ложь', value: false },
];

const SetupClassifierAttributeDeclarationUi: React.FC<ISetupClassifierAttribute> = ({ attributeDeclaration }) => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();

	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const [ linkData, setLinkData ] = useState<Omit<DefaultOptionType, 'label'>[]>([]);
	const [ scopeIdsList, setScopeIdsList ] = useState<Omit<DefaultOptionType, 'label'>[]>([]);
	const [ selectedScopeIds, setSelectedScopeIds ] = useState<Array<string>>([]);
	const [ loading, setLoading ] = useState<boolean>(false);

	const [ form ] = Form.useForm();

	const [ getCatalogItems, { error: catalogItemsError, isFetching: isGetCatalogItemsLoading } ] =
		useLazyGetCatalogItemsQuery();
	const [ getClassifierItems, { error: classifierItemsError, isLoading: isClassifierItemsLoading } ] =
		useLazyGetClassifierItemsQuery();

	const { execute: addUpdateEvent4 } = useAsyncOperation(
		useUpdateClassifierAttributeDeclarationAsyncMutation,
		TaskType.UPDATE_CLASSIFIER_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK,
	);

	const {
		currentMeasureGroup,
		getData,
		loading: measuresLoading,
		error: measuresError,
	} = useGetCurrentMeausureGroup();

	const {
		catalogList,
		getCatalogs,
		error: catalogListErr,
		loading: catalogListLoading,
	} = useCatalogGroups();

	const {
		classifiersGroups,
		loading: classifierGroupsLoading,
		error: catalogError,
	} = useGetClassifiersGroups();

	const showModal = () => setIsModalOpen(true);
	const handleCancel = () => setIsModalOpen(false);
	const handleChange = (value: Array<string>) => setSelectedScopeIds(value);

	const editData = async (classifier: ClassifierAttributeDeclarationResponse, v: ItemValues) => {
		try {
			setLoading(true);

			await addUpdateEvent4({
					id: classifier.id,
					updateClassifierAttributeDeclarationRequest: {
						restrictions: {
							...v,
						} as unknown as { [key: string]: object; },
					},
				});
		} catch (error) {
			errorHelper(
				'Ошибка при редактировании декларации атрибута классификатора!',
				error,
				notification,
			);
		} finally {
			setLoading(false);
		}

	};

	const onFinish = async (values) => {
		const v = values.defaultValue;

		await editData(attributeDeclaration, {
			...values,
			...(attributeDeclaration.attribute.type === CellTypesEnum.COLOUR && {
				defaultValue: `#${v}`,
			}),
			...(attributeDeclaration.attribute.type === CellTypesEnum.DATETIME && {
				defaultValue: dayjs(values.defaultValue).format(),
			}),
			...(attributeDeclaration.attribute.type === CellTypesEnum.INT && {
				defaultValue: Number(v),
			}),
			...(attributeDeclaration.attribute.type === CellTypesEnum.FLOAT && {
				defaultValue: parseFloat(v),
			}),
			...(attributeDeclaration.attribute.type === CellTypesEnum.RELATION && {
				defaultValue: v?.map((item) => item.value),
				scopeId: selectedScopeIds,
			}),
		});
		setIsModalOpen(false);
	};

	const onLoadData: TreeSelectProps['loadData'] = ({ id }) => {
		return getClassifierItems({
			parentItemId: id,
		})
			.unwrap()
			.then((res) => {
				setLinkData((prev) => {
					return [
						...prev,
						...res.data.map((c) => ({
							id: c.id,
							pId: c.parentItemId,
							value: c.id,
							title: c.displayName || t(l => l.common.defaultNames.emptyName),
							isLeaf: !c.parent,
						})),
					];
				});
			});
	};

	const onLoadScopeIds: TreeSelectProps['loadData'] = ({ id }) => {
		return getCatalogs(id).then((res) => {
			setScopeIdsList((prev) => {
				return [
					...prev,
					...res.map((c) => ({
						id: c.id,
						pId: c.parentId,
						value: c.id,
						title: c.displayName || t(l => l.common.defaultNames.emptyName),
						isLeaf: !c.parent,
					})),
				];
			});
		});
	};

	useEffect(() => {
		form.setFieldsValue(attributeDeclaration.restrictions);
		form.setFieldValue('defaultValue', []);
		const v = attributeDeclaration?.attribute?.restrictions?.defaultValue as unknown;
		if (attributeDeclaration.attribute.type === CellTypesEnum.DATETIME && v !== null) {
			form.setFieldValue(
				'defaultValue',
				dayjs(
					new Date(attributeDeclaration?.attribute?.restrictions?.defaultValue as unknown as string),
				),
			);
		}

		if (attributeDeclaration.attribute.type === CellTypesEnum.COLOUR && v !== null) {
			form.setFieldValue('defaultValue', { v });
		}

		if (
			'scopeId' in attributeDeclaration.attribute.restrictions &&
			(attributeDeclaration.attribute.restrictions.scopeId as Array<string>)?.length
		) {
			if (classifiersGroups) {
				const classifier = classifiersGroups.find(
					(item) => item.id === attributeDeclaration.attribute.restrictions.scopeId[0],
				);
				form.setFieldValue('scopeId', [
					{
						label: classifier.displayName,
						value: classifier.id,
					},
				]);
			}
			getClassifierItems({
				classifierId: attributeDeclaration.attribute.restrictions.scopeId[0],
				root: true,
			})
				.unwrap()
				.then((res) => {
					const options = res.data.map((c) => ({
						id: c.id,
						pId: c.parentItemId,
						value: c.id,
						title: c.displayName || t(l => l.common.defaultNames.emptyName),
						isLeaf: !c.parent,
					}));
					setLinkData(options);

					if (
						attributeDeclaration.restrictions.defaultValue &&
						Array.isArray(attributeDeclaration.restrictions.defaultValue)
					) {
						form.setFieldValue(
							'defaultValue',
							options.filter((opt) =>
								(attributeDeclaration.restrictions.defaultValue as Array<string>).includes(
									opt.id,
								),
							),
						);
					} else {
						form.setFieldValue('defaultValue', []);
					}
				});
		} else {
			if (isEmpty(scopeIdsList)) {
				setScopeIdsList(
					catalogList.map((item) => ({
						id: item.id,
						pId: item.parentId,
						value: item.id,
						title: item.displayName,
						isLeaf: !item.parent,
					})),
				);
				if (attributeDeclaration.restrictions.scopeId && catalogList) {
					const ids = (attributeDeclaration.restrictions.scopeId as Array<string>).filter(
						(item) => !!item,
					);
					setSelectedScopeIds(ids);
				}
			}
		}
	}, [ attributeDeclaration, classifiersGroups, catalogList ]);

	useEffect(() => {
		if (selectedScopeIds) {
			if (attributeDeclaration.attribute.restrictions.scopeId) {
				const promises = selectedScopeIds?.map((item) =>
					getClassifierItems({ classifierId: item, root: true }),
				);
				Promise.all(promises).then((res) => {
					const data = res.flat(Infinity);

					setLinkData(
						data
							.map((v) =>
								v.data.data.map((c) => ({
									id: c.id,
									pId: c.parentItemId,
									value: c.id,
									title: c.displayName,
									isLeaf: !c.parent,
								})),
							)
							.flat(),
					);
				});
			} else {

				getCatalogItems({
					catalogIds: selectedScopeIds,
				}).unwrap()
					.then(res => {
						const { data } = res;
						if (data) {
							setLinkData(data
								.map((c) => ({
									id: c.id,
									value: c.id,
									title: c.displayName || t(l => l.common.defaultNames.emptyName),
									isLeaf: true,
								})));
						}
					});
			}
		}
	}, [ selectedScopeIds ]);

	useEffect(() => {
		if (
			attributeDeclaration.attribute.type === CellTypesEnum.FLOAT &&
			!currentMeasureGroup &&
			attributeDeclaration.attribute.measurementGroupId
		) {
			getData(attributeDeclaration.attribute.measurementGroupId);
		}
	}, [ currentMeasureGroup, attributeDeclaration ]);

	useEffect(() => {
		if (attributeDeclaration.restrictions.defaultValue && linkData) {
			const opts = linkData.filter((item) =>
				(attributeDeclaration.restrictions.defaultValue as Array<string>).includes(item.id),
			);
			opts
				? form.setFieldValue('defaultValue', opts)
				: form.setFieldValue('defaultValue', []);
		}
	}, [ linkData ]);

	return (
		<>
			<DropdownLink
				icon={<RiFolderSettingsLine size={16}/>}
				title="Настроить декларацию атрибута"
				callback={showModal}
			/>

			<Modal
				title={
					<Typography.Title level={1}>
						<Flex gap={4} justify="flex-start" vertical>
							Управление декларацией атрибута:
							<span style={{ color: colors.primary }}>
								{translateType(attributeDeclaration?.attribute?.type, 'AttributeTransfer')}
							</span>
						</Flex>
					</Typography.Title>
				}
				open={isModalOpen}
				footer={null}
				closable={false}
			>
				<Spin spinning={loading}>
					<Form layout="vertical" form={form} onFinish={onFinish}>
						{attributeDeclaration.attribute.type === CellTypesEnum.STRING && (
							<>
								<Form.Item label="Маска ввода" name="mask">
									<Input/>
								</Form.Item>
								<Form.Item label="Максимальная длина" name="maxLength">
									<Input/>
								</Form.Item>
							</>
						)}
						{(attributeDeclaration.attribute.type === CellTypesEnum.STRING ||
							attributeDeclaration.attribute.type === CellTypesEnum.TEXT) && (
							<Form.Item label="Значение по умолчанию" name="defaultValue">
								<Input/>
							</Form.Item>
						)}
						{attributeDeclaration.attribute.type === CellTypesEnum.RELATION && (
							<Form.Item label="Значение по умолчанию" name="defaultValue">
								<DropdownTreeSelect
									info={
										!(attributeDeclaration.restrictions.scopeId as string[])?.length &&
										'Для выбора значения по умолчанию, пожалуйста, заполните поле "Область"'
									}
									treeCheckStrictly
									treeDataSimpleMode
									loading={isClassifierItemsLoading || isGetCatalogItemsLoading}
									loadData={onLoadData}
									errorText={classifierItemsError
										? 'Не удалось загрузить список записей классификаторов!'
										: (catalogItemsError
												? 'Не удалось загрузить список записей каталогов!'
												: ''
										)
									}
									treeData={linkData}
									error={!!classifierItemsError || !!catalogItemsError}
								/>
							</Form.Item>
						)}
						{(attributeDeclaration.attribute.type === CellTypesEnum.FLOAT ||
							attributeDeclaration.attribute.type === CellTypesEnum.INT) && (
							<Form.Item label="Значение по умолчанию" name="defaultValue">
								<Input type="number"/>
							</Form.Item>
						)}

						{attributeDeclaration.attribute.type === CellTypesEnum.FLOAT && (
							<>
								<Form.Item label="Точность" name="accuracy">
									<Input/>
								</Form.Item>
								<Form.Item label="Единица измерения" name="measurmentId">
									<DropdownSelect
										loading={measuresLoading}
										error={
											!!measuresError || !attributeDeclaration?.attribute?.measurementGroupId
										}
										errorText={
											!attributeDeclaration?.attribute?.measurementGroupId
												? 'У атрибута нет назначенной группы единиц измерения!'
												: 'Не удалось загрузить список единиц измерения!'
										}
										disabled={!!measuresError}
										options={currentMeasureGroup?.childrenMeasures?.map(
											(measure) => ({
												label: measure.displayName,
												value: measure.id,
											}),
										)}
									/>
								</Form.Item>
							</>
						)}
						{attributeDeclaration.attribute.type === CellTypesEnum.BOOLEAN && (
							<Form.Item
								label="Значение по умолчанию"
								name="defaultValue"
								initialValue={boolOptions[0]}
								valuePropName="checked"
							>
								<Radio.Group options={boolOptions}/>
							</Form.Item>
						)}
						{attributeDeclaration.attribute.type === CellTypesEnum.DATETIME && (
							<Form.Item label="Значение по умолчанию" name="defaultValue">
								<DatePicker/>
							</Form.Item>
						)}
						{attributeDeclaration.attribute.type === CellTypesEnum.RELATION &&
							(attributeDeclaration.attribute.restrictions.scopeId as unknown as Array<string>)
								?.length && (
								<Form.Item label="Область" name="scopeId">
									<DropdownSelect
										mode="multiple"
										options={
											!catalogError && attributeDeclaration.attribute.restrictions.scopeId
												? classifiersGroups?.map((item) => ({
													label: item.displayName,
													value: item.id,
												}))
												: catalogList.map((item) => ({
													label: item.displayName,
													value: item.id,
												}))
										}
										onChange={handleChange}
										loading={classifierGroupsLoading || catalogListLoading}
										error={!!catalogError}
										disabled={
											!!(
												attributeDeclaration.attribute.restrictions
													.scopeId as unknown as Array<string>
											)?.length
										}
									/>
								</Form.Item>
							)}
						{attributeDeclaration.attribute.type === CellTypesEnum.RELATION &&
							!(attributeDeclaration.attribute.restrictions.scopeId as unknown as Array<string>) && (
								<Form.Item label="Область">
									<DropdownTreeSelect
										treeDataSimpleMode
										treeCheckStrictly
										loading={catalogListLoading}
										value={selectedScopeIds}
										loadData={onLoadScopeIds}
										onChange={(v) => {
											setSelectedScopeIds(v.map((item) => item.value));
										}}
										errorText={
											catalogListErr
												? 'Не удалось загрузить список справочников!'
												: ''
										}
										treeData={scopeIdsList}
										error={!!catalogListErr}
									/>
								</Form.Item>
							)}
						{attributeDeclaration.attribute.type === CellTypesEnum.COLOUR && (
							<Form.Item
								label="Значение по умолчанию"
								name="defaultValue"
								getValueFromEvent={(color) => {
									return color.toHex();
								}}
							>
								<ColorPicker size="small" showText allowClear format="hex"/>
							</Form.Item>
						)}

						<Flex justify="flex-end" gap={4}>
							<Button type="text" onClick={handleCancel}>
								Отменить
							</Button>
							<Button type="primary" htmlType="submit">
								Сохранить
							</Button>
						</Flex>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export const SetupClassifierAttributeDeclaration = React.memo(SetupClassifierAttributeDeclarationUi);
