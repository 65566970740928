import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IConstraintTable {
	id?: string;
	displayName?: string;
	itemCount?: number;
	description?: string;
}

interface IDeleteTableAction {
	id: string;
}

interface IRenameTableAction {
	id: string;
	displayName: string;
	description?: string;
}

interface IConstraintTablesStore {
	constraintTables: IConstraintTable[] | null;
}

const initialState: IConstraintTablesStore = {
	constraintTables: null,
};

const constraintTablesSlice = createSlice({
	name: 'constraintTables',
	initialState,
	reducers: {
		setConstraintTables(state, { payload }: PayloadAction<IConstraintTable[]>) {
			state.constraintTables = payload;
		},
		renameConstraintTable(state, { payload }: PayloadAction<IRenameTableAction>) {
			state.constraintTables = state.constraintTables.map((item) => {
				if (item.id === payload.id) {
					return {
						...item,
						...payload,
					};
				}
				return item;
			});
		},
		pushConstraintTable(state, { payload }: PayloadAction<IConstraintTable>) {
			state.constraintTables = [payload, ...state.constraintTables];
		},
		deleteConstraintTable(state, { payload }: PayloadAction<IDeleteTableAction>) {
			state.constraintTables = state.constraintTables.filter(
				(table) => table.id !== payload.id
			);
		},
	},
});

export const constraintTablesReducer = constraintTablesSlice.reducer;
export const {
	setConstraintTables,
	pushConstraintTable,
	deleteConstraintTable,
	renameConstraintTable,
} = constraintTablesSlice.actions;
