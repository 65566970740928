import { useEffect } from 'react';
import { useAppSelector } from 'shared/hooks';
import { useStomp } from 'shared/stomp/useStomp';
import { TaskType } from './taskType';

const useRealtimeEvent = (taskType: TaskType, handler: (data: any) => void, deps: any[] = []) => {
	const { subscribe } = useStomp();

	const userId = useAppSelector((state) => state.entities.session.userId);

	useEffect(() => {

		const isPrivateTopic = taskType != TaskType.TRANSACTION_COMMIT;

		if (isPrivateTopic && !userId) {
			return;
		}

		const topic = isPrivateTopic
			? `/user/${userId}/topic/${taskType.toLowerCase()}`
			: `/topic/${taskType.toLowerCase()}`;

		const unsubscribe = subscribe(topic, handler);

		return () => unsubscribe(topic, handler);
	}, [ userId, ...deps ]);
};

export {
	useRealtimeEvent,
};