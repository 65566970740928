import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ClassifierItemResponse } from 'shared/api/generatedApi/mdmgApi';
import { useLazyGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { errorHelper } from 'shared/helpers';
import { useDebounce } from 'shared/hooks/useDebounce';

export const useSearchInnerClassifiersItems = () => {
	const [searchedClassifierItems, setSearchedClassifierItems] = useState<
		Array<ClassifierItemResponse>
	>([]);
	const [searchParams, setSearchParams] = useSearchParams();

	const { classifierItemId } = useParams();

	const [getSearchResult, { isFetching: isSearchItemsLoading }] = useLazyGetSearchResultQuery();

	const searchHandler = useDebounce((e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length > 0) {
			searchParams.set('searchValue', e.target.value);
			setSearchParams(searchParams);
		} else {
			searchParams.delete('searchValue');
			setSearchParams(searchParams);
		}
	}, 1000);

	const fetchSearch = (searchValue: string) => {
		getSearchResult({
			entityType: 'CLASSIFIER_ITEM',
			textRequest: searchValue,
			parentId: classifierItemId,
		})
			.unwrap()
			.then((res) => {
				setSearchedClassifierItems(res as ClassifierItemResponse[]);
			})
			.catch((err) => {
				errorHelper(
					'Ошибка при получении списка ограничительных таблиц!',
					err,
					notification
				);
			});
	};

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (searchValue) {
			fetchSearch(searchValue);
		} else {
			setSearchedClassifierItems([]);
		}
	}, [searchParams]);

	return {
		searchHandler,
		searchValue: searchParams.get('searchValue'),
		isLoading: isSearchItemsLoading,
		searchedClassifierItems,
	};
};
