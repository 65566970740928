import { Flex, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { ConstraintTables } from 'widgets/Metadata/ConstraintTables';
import { MeasureWidget } from 'widgets/Metadata/Measures';
import { MetadataAttributesListWidget } from 'widgets/Metadata/MetadataAttributesListWidget';
import { MetadataGroupTreeWidget } from 'widgets/Metadata/MetadataGroupTreeWidget';
import { Transactions } from 'widgets/Transactions';
import { RealtimeBaseEvent, TaskType, useRealtimeEvent } from 'entities/events';
import { deleteMetaAttributesGroup } from 'entities/metadata/attributesGroups/attributesGroups.store';
import { deleteMetaAttributeItems } from 'entities/metadata/metaAttributes/metaAttributes.store';
import { mdmgApi, useGetAttributeGroup1Query } from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { useAppDispatch, useHandleQueryError } from 'shared/hooks';
import { setSubMenuCollapsed } from 'shared/ui';
import { SubSider } from 'shared/ui/components/SubSider';

const items: TabsProps['items'] = [
	{
		key: 'attributes',
		label: 'Атрибуты',
		children: (
			<>
				<SubSider>
					<MetadataGroupTreeWidget/>
				</SubSider>
				<MetadataAttributesListWidget/>
			</>
		),
	},
	{
		key: 'measures',
		label: 'Единицы измерения',
		children: <MeasureWidget/>,
	},
	{
		key: 'constraintTables',
		label: 'Ограничительные таблицы',
		children: <ConstraintTables/>,
	},
];

export const MetadataPage: React.FC = () => {
	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { metaAttributeGroupId: attributeGroupId } = useParams();

	const [ activeKey, setActiveKey ] = useState<string>('');
	const [ title, setTitle ] = useState<string>('Метаданные');

	const { data: currAttrGroup } = useHandleQueryError(
		useGetAttributeGroup1Query({
			id: attributeGroupId,
		}, {
			skip: !attributeGroupId,
		}),
		l => l.attributes.attributeGroupErr);

	const onChange = (key: string) => {
		setActiveKey(key);
		navigate(`/metadata/${key}`);
	};

	useEffect(() => {
		dispatch(setSubMenuCollapsed(false));
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	useEffect(() => {
		if (pathname.includes('attributes')) {
			setActiveKey('attributes');
			if (currAttrGroup) {
				setTitle(currAttrGroup.displayName);
			} else {
				setTitle('Метаданные');
			}
		} else if (pathname.includes('measures')) {
			setActiveKey('measures');
			setTitle('Метаданные');
		} else if (pathname.includes(routes.metadata.constraintTables)) {
			setActiveKey('constraintTables');
			setTitle('Метаданные');
		} else {
			setActiveKey('attributes');
			setTitle('Метаданные');
		}
	}, [ pathname, currAttrGroup ]);

	useRealtimeEvent(TaskType.DELETE_ATTRIBUTE_GROUPS_BACKGROUND_TASK, ({ payload }: RealtimeBaseEvent) => {
		const deletedAttributeGroupIds = (payload.entityIds as string[]);

		dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
		dispatch(mdmgApi.util.invalidateTags([ 'AttributeGroups' ]));

		if (deletedAttributeGroupIds.includes(attributeGroupId)) {
			dispatch(deleteMetaAttributesGroup(attributeGroupId));
			navigate(`/${routes.metadata.main}`);
		}
	});

	useRealtimeEvent(TaskType.DELETE_ATTRIBUTES_BACKGROUND_TASK, ({ payload }: RealtimeBaseEvent) => {
		const deletedAttributeIds = (payload.entityIds as string[]);

		dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
		dispatch(mdmgApi.util.invalidateTags([ 'Attributes' ]));
		dispatch(deleteMetaAttributeItems(deletedAttributeIds));
	});

	return (
		<>
			<Flex vertical align="flex-end">
				<Flex justify="space-between" style={{ width: '100%' }}>
					<Typography.Title level={1}>{title}</Typography.Title>
					<Transactions/>
				</Flex>

				<Tabs
					items={items}
					style={{ paddingBottom: 65, width: '100%' }}
					destroyInactiveTabPane
					activeKey={activeKey}
					onChange={onChange}
				/>
			</Flex>
		</>
	);
};
