import { RiDatabase2Line, RiNodeTree, RiListView, RiShareForwardBoxLine, RiDraftLine, RiPieChartLine, RiUserSettingsLine } from '@remixicon/react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { routes } from './routes';

// Конфигурация Keycloak Admin Console
const keycloakAdminConsoleUrl = `${process.env.REACT_APP_KEYCLOAK_URL}` + 
    `admin/${process.env.REACT_APP_KEYCLOAK_REALM}/console` +
    `?referrer=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}` + 
    `&referrer_uri=${window.location.origin}`;

export const navItems = [
	// {
	// 	label: <NavLink to={routes.main.main}>Главная</NavLink>,
	// 	title: '',
	// 	icon: <RiHome3Line size={16} />,
	// },
	{
		label: <NavLink to={routes.catalogs.main}>Справочники</NavLink>,
		title: 'Справочники',
		icon: <RiNodeTree size={16} />,
	},
	{
		label: <NavLink to={routes.classifiers.main}>Классификаторы</NavLink>,
		title: 'Классификаторы',
		icon: <RiListView size={16} />,
	},
	{
		label: <NavLink to={routes.metadata.main}>Метаданные</NavLink>,
		title: 'Метаданные',
		icon: <RiDatabase2Line size={16} />,
	},
	{
		label: <NavLink to={routes.integrations.main}>Интеграции</NavLink>,
		title: 'Интеграции',
		icon: <RiShareForwardBoxLine size={16} />,
	},
	{
		label: <NavLink to={routes.applications.main}>Заявки</NavLink>,
		title: 'Заявки',
		icon: <RiDraftLine size={16} />,
	},
	{
		label: <NavLink to={routes.dataQuality.main}>Отчеты</NavLink>,
		title: 'Качество данных',
		icon: <RiPieChartLine size={16} />,
	},
	{
		label: <NavLink to={keycloakAdminConsoleUrl} target="_blank" rel="noopener noreferrer">Пользователи</NavLink>,
		title: 'Управление пользователями',
		icon: <RiUserSettingsLine size={16} />,
	},
	// {
	// 	label: (
	// 		<NavLink
	// 			to={routes.settings.main}
	// 			onClick={() => window.location.assign(routes.settings.main)}
	// 		>
	// 			Настройки
	// 		</NavLink>
	// 	),
	// 	title: 'settings',
	// 	icon: <RiSettings4Line size={16} />,
	// },
];
