import { RiLogoutBoxLine } from '@remixicon/react';
import React from 'react';
import { useTypedTranslation } from 'shared/hooks';
import { useLogout } from './logout.model';
import { styles } from './logout.styles';

export const LogoutUi = () => {
    const { t } = useTypedTranslation();
	const { logout } = useLogout();

	return (
		<a onClick={logout} type="text" style={styles}>
			<RiLogoutBoxLine />
			{t(l => l.profile.logoutButton)}
		</a>
	);
};
