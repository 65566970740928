import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
	removeCurrentMetaAttribute,
	setCurrentMetaAttribute,
	updateMetaAttributeItem,
} from 'entities/metadata/metaAttributes/metaAttributes.store';
import { useTransactions } from 'entities/transactions';
import {
	AttributeResponse,
	useLazyGetAttributeQuery,
} from 'shared/api/generatedApi/mdmgApi';
import {
	useCreateAttributeMutation,
	useUpdateAttributeMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { NEW_ENTITY } from 'shared/helpers/Constants';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';

export const useEditSimpleAttribute = () => {
	const [formVals, setFormVals] = useState<AttributeResponse>(null);

	const { currentAttribute } = useAppSelector((state) => state.entities.metadata.metaAttributes);
	const { transactionRollback } = useAppSelector((state) => state.entities.transactions);

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const [getAttribute, { isLoading, error: getAttributeError }] = useLazyGetAttributeQuery();
	const [updateAttribute] = useUpdateAttributeMutation();
	const [createAttribute] = useCreateAttributeMutation();

	const { metaAttributeId, metaAttributeGroupId } = useParams();
	const navigate = useNavigate();

	const { getTransactions } = useTransactions();
	const { t } = useTypedTranslation();

	const handleUpdateAttribute = async (attr) => {
		if (!attr.displayName || attr.displayName.length === 0 || !attr.type) {
			metaAttributeId !== NEW_ENTITY &&
				errorHelper(
					t((l) => l.attributes.attributeErr),
					new Error(t((l) => l.common.error.emptyRequiresFields)),
					notification
				);
			setFormVals({ ...currentAttribute });
			return;
		}

		if (metaAttributeId !== NEW_ENTITY) {
			await updateAttribute({
				attributeGroupId: metaAttributeGroupId,
				id: metaAttributeId,
				updateAttributeRequest: { ...attr },
			})
				.unwrap()
				.then((res) => {
					getTransactions();

					dispatch(updateMetaAttributeItem(res));
					dispatch(setCurrentMetaAttribute(res));
				})
				.catch((err) => {
					errorHelper(
						t((l) => l.attributes.attributeErr),
						err,
						notification
					);
					setFormVals({ ...currentAttribute });
				});
		} else {
			createAttribute({
				attributeGroupId: metaAttributeGroupId,
				createAttributeRequest: { ...attr, list: !!attr.list },
			})
				.unwrap()
				.then((res) => {
					dispatch(updateMetaAttributeItem(res));
					dispatch(setCurrentMetaAttribute(res));
					navigate(
						`/${routes.metadata.main}/${routes.metadata.group}/${metaAttributeGroupId}/${routes.metadata.attribute}/${res.id}`
					);
				})
				.then(() => getTransactions())
				.catch((err) => {
					errorHelper(
						t((l) => l.attributes.attributeErr),
						err,
						notification
					);
				});
		}
	};

	const getAttributeData = async () => {
		const data = await getAttribute({
			id: metaAttributeId,
		});

		if ('data' in data) {
			dispatch(setCurrentMetaAttribute(data.data));
		}
	};

	useEffect(() => {
		if (!currentAttribute && metaAttributeId !== NEW_ENTITY) {
			getAttributeData();
		}

		return () => {
			dispatch(removeCurrentMetaAttribute());
			setFormVals(null);
		};
	}, []);

	useEffect(() => {
		if (metaAttributeId === NEW_ENTITY) return;
		getAttributeData();
	}, [transactionRollback]);

	useEffect(() => {
		if (currentAttribute)
			setFormVals(currentAttribute);
	}, [currentAttribute]);

	return {
		formVals,
		isLoading,
		getAttributeError,
		handleUpdateAttribute,
	};
};
