import { RiCheckboxCircleFill } from '@remixicon/react';
import { App, Flex, List, Result, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { setCurrentClassifier } from 'entities/classifiers/classifiersGroups/classifier.store';
import { TaskType, useRealtimeEvent } from 'entities/events';
import {
	CatalogResponse, mdmgApi,
	useGetClassifierAttributeDeclarationsQuery,
	useLazyGetCatalogsQuery,
	useLazyGetClassifierQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/newCreateApiFile';
import { errorHelper } from 'shared/helpers';
import { translateType } from 'shared/helpers/CellTypeParser';
import { declarationRestrictionParser } from 'shared/helpers/declarationRestrictionParser';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Placeholder } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { EditClassifierMainInfo } from '../../DetailClassifier/EditClassifierMainInfo';
import { DeleteClassifierAttributeDeclaration } from '../DeleteClassifierAttribiteDeclaration';
import { SetupClassifierAttributeDeclaration } from '../SetupClassifierAttributeDeclaration';

const ClassifierAttributeDeclarationsListUi = () => {

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const { classifierGroupId } = useParams();

	const [ descriptions, setDescriptions ] = useState<{ [key: string]: React.ReactNode }>(null);
	const [ catalogs, setCatalogs ] = useState<Array<CatalogResponse>>([]);

	const { currentClassifierGroup } = useAppSelector(
		(state) => state.entities.classifiers.classifierGroups,
	);

	const [ fetchGetCatalogs ] = useLazyGetCatalogsQuery();
	const [ fetchCurrentClassifier ] = useLazyGetClassifierQuery();

	const {
		data: classifierAttributeDeclarations,
		isLoading: loading,
		error,
	} = useGetClassifierAttributeDeclarationsQuery({
		classifierId: classifierGroupId,
	});

	const handler = () => {
		dispatch(mdmgApi.util.invalidateTags([ 'ClassifierAttributeDeclarations' ]));
		dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
	};

	useRealtimeEvent(TaskType.CREATE_CLASSIFIER_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK, handler);
	useRealtimeEvent(TaskType.DELETE_CLASSIFIER_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK, handler);
	useRealtimeEvent(TaskType.UPDATE_CLASSIFIER_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK, handler);

	const getCatalogs = (ids: string[]) => {
		fetchGetCatalogs({ ids })
			.unwrap()
			.then((res) => setCatalogs(res))
			.catch((err) =>
				errorHelper('Ошибка при получении названия каталога', err, notification),
			);
	};

	useEffect(() => {
		if (!classifierAttributeDeclarations) {
			return;
		}
		const newDescriptions = Object.fromEntries(
			classifierAttributeDeclarations?.map((item) => [
				item.id,
				<>
					<span>
						Тип данных:
						<span style={{ color: colors.text }}>
							{` ${translateType(item?.attribute?.type)}`}
						</span>
					</span>
					{Object.keys(item?.restrictions)
						.filter((r) => item?.restrictions[r] !== null)
						.map((r) =>
							declarationRestrictionParser(
								item?.attribute?.type,
								r,
								item?.restrictions[r],
								catalogs,
							),
						)}
				</>,
			]),
		);
		setDescriptions(newDescriptions);
	}, [ catalogs, classifierAttributeDeclarations ]);

	useEffect(() => {
		if (!classifierAttributeDeclarations) {
			return;
		}
		let ids = [];
		classifierAttributeDeclarations?.forEach((d) => {
			if ('scopeId' in d.restrictions && d?.restrictions?.scopeId !== null) {
				ids = [ ...ids, ...(d?.restrictions?.scopeId as string[]) ];
			}
		});

		if (!ids.length) {
			return;
		}
		getCatalogs(ids);
	}, [ classifierAttributeDeclarations ]);

	useEffect(() => {
		fetchCurrentClassifier({ id: classifierGroupId })
			.unwrap()
			.then((res) => {
				dispatch(setCurrentClassifier(res));
			})
			.catch((err) => errorHelper('Ошибка при получении классификатора', err, notification));
	}, []);

	if (error) {
		return <Result title="Ошибка!" subTitle={JSON.stringify(error)}/>;
	}

	return (
		<>
			{loading && (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Spin size="large"/>
				</div>
			)}
			{!loading && !classifierAttributeDeclarations?.length && (
				<Placeholder
					title="Пока не создано ни одного атрибута"
					subTitle="Для добавления нового атрибута нажмите на кнопку «Добавить атрибут»"
				/>
			)}
			{!loading && classifierAttributeDeclarations?.length > 0 && (
				<List
					itemLayout="horizontal"
					dataSource={classifierAttributeDeclarations}
					renderItem={(item) => (
						<List.Item
							actions={[
								<ItemActions
									key={item.id}
									items={[
										currentClassifierGroup?.displayNameAttributeDeclarationId !==
										item?.id
											? {
												label: (
													<EditClassifierMainInfo
														classifier={currentClassifierGroup}
														isDropdownLink={true}
														displayNameAttributeDeclarationId={
															item?.id
														}
													/>
												),
												key: `${item?.id}-edit`,
											}
											: null,
										{
											label: <SetupClassifierAttributeDeclaration attributeDeclaration={item}/>,
											key: `${item?.id}-setup`,
										},
										{
											label: <DeleteClassifierAttributeDeclaration id={item?.id}/>,
											key: `${item?.id}-delete`,
										},
									]}
								/>,
							]}
						>
							<List.Item.Meta
								title={
									currentClassifierGroup?.displayNameAttributeDeclarationId !==
									item?.id ? (
										item?.attribute?.displayName
									) : (
										<Flex gap={6}>
											{item?.attribute?.displayName}

											<Flex align="center" gap={2}>
												<RiCheckboxCircleFill
													color={colors.success}
													size={14}
												/>
												<Typography.Text style={{ fontWeight: 400 }}>
													Используется в названии справочной записи
												</Typography.Text>
											</Flex>
										</Flex>
									)
								}
								description={descriptions ? descriptions[item?.id] : []}
							/>
						</List.Item>
					)}
				/>
			)}
		</>
	);
};

export const ClassifierAttributeDeclarationsList = React.memo(ClassifierAttributeDeclarationsListUi);
