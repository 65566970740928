import { App, Result, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ItemStatusDto } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import {
	ClassifierItemResponse, mdmgApi,
	useGetClassifierAttributeDeclarationsQuery,
	useGetClassifierItemQuery,
	useUpdateClassifierItemMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { ItemValuesType, RecordType, Values } from 'shared/helpers/types';
import { useAppDispatch } from 'shared/hooks';
import { WorkTable } from 'shared/ui';
import { ChipStatusEnum } from 'shared/ui/components/Chip/chipStylehelper';
import { EditRecordStatus } from '../EditRecordStatus/EditRecordStatus';

const columns = [
	{
		title: 'Наименование атрибута',
		dataIndex: 'attributeName',
		key: 'attributeName',
	},
	{
		title: 'Значение',
		dataIndex: 'value',
		key: 'value',
	},
];

const updateValue = (values: any, update?: { key: string; value: any }) => {
	return Object.fromEntries(
		Object.entries(values as Values).map(([ key, { value } ]) => [
			key,
			(key == update?.key ? update.value : value) as object,
		]),
	);
};

const EditClassifierRecordUi: React.FC = () => {

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const { classifierGroupId, classifierItemId } = useParams();

	const [ recordsList, setRecordsList ] = useState<RecordType[]>([]);

	const { data: classifierAttributeDeclarations, isLoading } = useGetClassifierAttributeDeclarationsQuery({
		classifierId: classifierGroupId,
	});

	const { data: record, error: recordsError, isFetching: isRecordsLoading } = useGetClassifierItemQuery({
		id: classifierItemId,
	});

	const [ fetchUpdateItem ] = useUpdateClassifierItemMutation();

	const mapValues = (data?: ClassifierItemResponse) => {
		const editItem = (declarationId: string, newValue: ItemValuesType) =>
			updateItem(
				updateValue(data.values, { key: declarationId, value: newValue }),
				data.status,
				data.parentItemId,
			);

		const onStatusChange = (status: ChipStatusEnum) =>
			updateItem(
				updateValue(data.values),
				status.toUpperCase() as ItemStatusDto,
				data.parentItemId,
			);

		const flattenedRecords: RecordType[] = Object
			.entries(data.values as unknown as Values)
			.map(([key, record]) => {
				const { attribute } = classifierAttributeDeclarations.find(x => x.id === key);

				return {
					id: key,
					key: key,
					attributeName: record?.attributeName,
					value: CellInputParser(
						key,
						record?.value,
						attribute.type,
						editItem,
					),
				};
			},
		);

		flattenedRecords.unshift({
			attributeName: 'Статус позиции',
			id: 'status',
			key: 'status',
			value: <EditRecordStatus data={data} onChange={onStatusChange}/>,
		});
		setRecordsList(flattenedRecords);
	};

	const updateItem = async (
		values: { [key: string]: object },
		status: ItemStatusDto,
		parentId: string | null,
	) => {
		await fetchUpdateItem({
			id: classifierItemId,
			classifierId: classifierGroupId,
			updateClassifierItemRequest: {
				parentItemId: parentId,
				status,
				values,
			},
		})
			.unwrap()
			.then(() => {
				dispatch(mdmgApi.util.invalidateTags([ 'ClassifierItems' ]));
				dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
			})
			.catch((err) => {
				errorHelper('Ошибка при редактировании записи', err, notification);
			});
	};

	useEffect(() => {
		if (record && classifierAttributeDeclarations) {
			mapValues(record);
		}
	}, [ classifierAttributeDeclarations, record ]);

	if (recordsError) {
		return <Result title="Ошибка!" subTitle={JSON.stringify(recordsError)}/>;
	}

	const loading = isRecordsLoading || isLoading;

	return (
		<>
			{loading ? (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<Spin size="large"/>
				</div>
			) : (
				<WorkTable
					pagination={false}
					dataSource={recordsList}
					columns={columns}
					noFilters
				/>
			)}
		</>
	);
};

export const EditClassifierRecord = React.memo(EditClassifierRecordUi);
