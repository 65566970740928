import { RiUserFill, RiArrowDownSLine } from '@remixicon/react';
import { Avatar, Button, Dropdown, Flex, Typography } from 'antd';
import React, { useEffect } from 'react';
import { LogoutFeature } from 'features/auth/logout';
import { GoToUserProfileButton } from 'features/profile/GoToUserProfileButton';
import { setToken, setUserId, setUserName, setUserRoles } from 'entities/session';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import keycloak from '../../../keycloak';
import s from './UserProfileMenu.module.scss';

const items = [
	{
		label: <GoToUserProfileButton />,
		key: '0',
	},{
		label: <LogoutFeature />,
		key: '1',
	},
];
function UserProfileMenu() {
	const name = useAppSelector((state) => state.entities.session.userName);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (keycloak.authenticated) {
			const kcToken = keycloak.tokenParsed;

			const familyName = kcToken.family_name
				? `${kcToken.family_name[0].toUpperCase()}${kcToken.family_name.slice(1)} `
				: '';
			const givenName = kcToken.given_name ? `${kcToken.given_name[0].toUpperCase()}.` : '';
			const uName = `${familyName}${givenName}`;

			dispatch(setUserName(uName));
			dispatch(setUserRoles(kcToken.realm_access.roles));
			dispatch(setToken(keycloak.token));
			dispatch(setUserId(kcToken.sub));

			keycloak.onAuthRefreshSuccess = () => {
				dispatch(setToken(keycloak.token));
			};
		}
	}, [keycloak]);

	return (
		<Flex dir="column" align="center" gap={7}>
			<>
				<Avatar icon={<RiUserFill />} className={s.avatarSkeleton} />
				<Typography.Title level={3} className={s.titleFontSize}>
					{name}
				</Typography.Title>
			</>

			<Dropdown menu={{ items }} trigger={['click']} placement="bottomLeft">
				<Button icon={<RiArrowDownSLine />} type="text" />
			</Dropdown>
		</Flex>
	);
}

export default UserProfileMenu;
