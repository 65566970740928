import { Flex } from 'antd';
import React from 'react';
import { AddAttributeDeclaration } from 'features/catalogs/AttrbuteDeclarations/AddAttributeDeclaration';
import { EditAttributeDeclarationList } from 'features/catalogs/AttrbuteDeclarations/EditAttributeDeclarationList';

const AttributesWidgetUi: React.FC = () => {
	return (
		<Flex vertical gap={24}>
			<AddAttributeDeclaration />
			<EditAttributeDeclarationList />
		</Flex>
	);
};

export const AttributesWidget = React.memo(AttributesWidgetUi);
