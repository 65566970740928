import { Client, StompConfig, StompSubscription } from '@stomp/stompjs';
import React, { createContext, FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

export interface IStompContextState {
	stompClient: Client | null;
	subscribe: any;
}

const defaultValue = {
	stompClient: null,
	subscribe: () => {
	},
};

export const StompContext = createContext<IStompContextState>(defaultValue);

interface IStopProviderProps {
	children: ReactNode;
	config: StompConfig;
	onConnected?: (client: Client) => void;
}

export const StompProvider: FC<IStopProviderProps> = ({ children, config, onConnected }) => {
	const [ stompClient ] = useState(() => new Client(config));

	const subscriptionsRef = useRef<Map<string, StompSubscription>>(new Map());
	const multiplexSubscriptionsRef = useRef<Map<string, Set<any>>>(new Map());

	useEffect(() => {
		if (!stompClient) {
			return;
		}

		stompClient?.activate();

		stompClient.onConnect = () => {
			updateSubscriptions();
		};

		onConnected?.(stompClient);
	}, [ stompClient ]);

	const updateSubscriptions = useCallback(() => {
		if (!stompClient || !stompClient.connected) {
			return;
		}

		const subscriptions = subscriptionsRef.current;
		const multiplexSubscriptions = multiplexSubscriptionsRef.current;

		//	актуализируем подписки на топики
		for (const topic of multiplexSubscriptions.keys()) {

			if (subscriptions.has(topic)) {
				continue;
			}

			subscriptions.set(
				topic,
				stompClient.subscribe(
					topic,
					message => {
						const data = JSON.parse(message.body);
						multiplexSubscriptions.get(topic)?.forEach(s => s(data));
					}));
		}

		//	отписываемся от неиспользуемых топиков
		for (const key of subscriptions.keys()) {
			if ((multiplexSubscriptions.get(key)?.size ?? 0) > 0) {
				continue;
			}
			subscriptions.get(key).unsubscribe();
			subscriptions.delete(key);
		}
	}, [ stompClient ]);

	const subscribe = (topic: string, callback: (data: any) => void) => {
		const set = multiplexSubscriptionsRef.current.get(topic) ?? new Set();
		set.add(callback);
		multiplexSubscriptionsRef.current.set(topic, set);
		updateSubscriptions();

		return () => {
			const set = multiplexSubscriptionsRef.current.get(topic);
			set?.delete(callback);
			if (set.size == 0) {
				multiplexSubscriptionsRef.current.delete(topic);
			} else {
				multiplexSubscriptionsRef.current.set(topic, set);
			}
			updateSubscriptions();
		};
	};

	return (
		<StompContext.Provider value={{
			stompClient,
			subscribe,
		}}>
			{children}
		</StompContext.Provider>
	);
};