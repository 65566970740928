import { DatePicker, Input, Flex, Alert } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import React from 'react';
import { ColorInput, RelationToCatalogInput, RelationToClassifierInput } from 'features/attributes';
import { CatalogAttributeDeclarationResponse } from 'shared/api/generatedApi/mdmgApi';
import { ItemValuesType } from 'shared/helpers/types';
import { DropdownSelect } from 'shared/ui';
import { BooleanInput } from 'shared/ui/components/BooleanInput';

export const AttributeInputFactory = (
	value: ItemValuesType,
	attributeDeclaration: CatalogAttributeDeclarationResponse,
	editInfo: (declarationId: string, value: ItemValuesType) => void,
	selectOptions?: DefaultOptionType[],
) => {
	const { id, attribute, restrictions } = attributeDeclaration;
	if (attribute.type) {
		if (selectOptions?.length > 0) {
			return (
				<DropdownSelect key={id}
								allowClear
								options={selectOptions}
								defaultValue={value}
								onChange={(e) => editInfo(id, e)}
				/>
			);
		}

		switch (attribute.type) {
			case 'COLOR':
				return Array.isArray(value) ? (
					<Flex vertical gap={4}>
						{value.map((item, idx) => (
							<ColorInput key={`${id}-${idx}`}
										value={item as string}
										onChange={(color) => {
											return color
												? editInfo(id, [
													...value,
													color,
												])
												: editInfo(id, [
													...value.filter((v) => v !== item),
												]);
										}}
							/>))}
					</Flex>
				) : (
					<ColorInput key={id}
								value={value as string}
								onChange={(color) =>
									editInfo(id, color)
								}
					/>
				);
			case 'DATE_TIME':
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v, idx) => (
							<DatePicker key={idx}
										{...(v !== '' ? { value: dayjs(new Date(v as string)) } : {})}
										style={{ maxWidth: 'fit-content' }}
										onChange={(_, dateString) => {
											dateString
												? editInfo(id, [
													...value,
													new Date(`${dateString}`).getTime(),
												])
												: editInfo(id, [
													...value.filter((item) => item !== v),
												]);
										}}
							/>
						))}
					</Flex>
				) : (
					<DatePicker key={id}
								{...(value ? { value: dayjs(new Date(value as string)) } : {})}
								onChange={(_, dateString) =>
									editInfo(id, dateString)
								}
					/>
				);
			case 'BOOLEAN':
				return (<BooleanInput key={id}
									  value={value}
									  onChange={(e) =>
										  editInfo(id, e)
									  }
					/>
				);
			case 'INTEGER':
			case 'FLOAT':
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v, idx) => (
							<Input key={idx}
								   defaultValue={v as string | number}
								   onBlur={(e) =>
									   editInfo(id, [
										   ...value,
										   e.target.value,
									   ])
								   }
								   allowClear
								   type="number"
							/>
						))}
					</Flex>
				) : (
					<Input key={id}
						   type="number"
						   defaultValue={value as string | number}
						   onBlur={(e) =>
							   editInfo(id, e.target.value)
						   }
						   allowClear
					/>
				);
			case 'RELATION': {
				const classifierId = (attribute.restrictions.scopeIds as (string[] | null))?.[0];
				if (classifierId) {
					return <RelationToClassifierInput key={id}
													  classifierId={classifierId}
													  value={value as string | null}
													  onChange={(e) =>
														  editInfo(id, e)
													  }/>;
				}

				const catalogIds = restrictions.scopeIds as (string[] | null);
				if (catalogIds?.length > 0) {
					return <RelationToCatalogInput key={id}
												   catalogIds={catalogIds}
												   value={value as string}
												   onChange={(e) => editInfo(id, e)}
					/>;
				}

				return <Alert type="error"
							  message="Атрибут не связан ни с одним из справочников или классификатором"
				/>;
			}
			default:
				return Array.isArray(value) ? (
					<Flex gap={4} vertical>
						{value.map((v, idx) => (
							<Input.TextArea key={idx}
											autoSize
											defaultValue={v as string | number}
											allowClear
											onBlur={(e) =>
												editInfo(id, [ ...value, e.target.value ])
											}
							/>
						))}
					</Flex>
				) : (
					<Input.TextArea key={id}
									autoSize
									defaultValue={value as string | number}
									allowClear
									onBlur={(e) =>
										editInfo(id, e.target.value)
									}
					/>
				);
		}
	}
};
