import { Col, Flex, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { EditCatalogRecord } from 'widgets/Catalogs';
import { Transactions } from 'widgets/Transactions';
import { TransactionsList } from 'widgets/Transactions/TransactionsList';
import { clearBreadcrumbs, setBreadcrumbs } from 'entities/breadcrumbs';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { TransactionResponse } from 'shared/api/generatedApi/transactionServiceApi';
import { routes } from 'shared/config';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DetailFooter } from 'shared/ui';

const tarnsactionsMock: TransactionResponse[] = [
	{
		transaction: {
			id: 'd04ffcc0-8dd6-47d9-a4a2-00d1c90edf18',
			transactionStatus: 'ACTIVE',
			userId: 'e87a8081-b0b6-4d96-a382-71696e2ec126',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2024-12-09T16:24:03.836518',
			updatedAt: '2024-12-09T16:24:03.836518',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: 'fd10637e-8524-4ee8-b164-d769a05776ae',
			transactionStatus: 'ACTIVE',
			userId: 'e1fc888a-ffc4-40f4-8f32-3ac175e7dbc8',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2024-12-12T14:56:45.611147',
			updatedAt: '2024-12-12T14:56:45.611147',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '261b0c74-5b2a-4b25-bd77-035ae056e6ae',
			transactionStatus: 'ACTIVE',
			userId: 'edb3f6ec-a72b-4289-8c68-17115d6b27fd',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2024-12-13T08:03:50.957652',
			updatedAt: '2024-12-13T08:03:50.957652',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '1ab5a73b-ca27-4309-9b6e-681a694fc34c',
			transactionStatus: 'ACTIVE',
			userId: 'b4f648fa-68e5-4932-b644-87018ba51e4f',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-15T15:50:19.041402',
			updatedAt: '2025-01-15T15:50:19.041402',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '41edf108-ec0c-4e0f-b162-5b9725d70419',
			transactionStatus: 'ACTIVE',
			userId: 'fd0958ed-d8cc-437d-b0b5-e3dc3a899ae6',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-15T16:02:29.13288',
			updatedAt: '2025-01-15T16:02:29.13288',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: 'cce3ca61-71ad-4596-bcf8-b58cc3153a3d',
			transactionStatus: 'ACTIVE',
			userId: 'ca920a79-6e26-4e88-a3c3-7458c9c7f4ee',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2024-12-17T08:48:47.774178',
			updatedAt: '2024-12-17T08:48:47.774178',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: 'bbdbb5ef-a15f-4e12-a506-9fc2085c8640',
			transactionStatus: 'ACTIVE',
			userId: '6fe7183b-dbdd-45bd-9862-5d6d60db1e34',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2024-12-19T19:23:49.649573',
			updatedAt: '2024-12-19T19:23:49.649573',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: 'a96beda4-1356-4043-8778-80db57c60ce4',
			transactionStatus: 'ACTIVE',
			userId: 'a16f2830-4a9a-4f28-be26-74452adb8a54',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2024-12-20T13:59:19.3961',
			updatedAt: '2024-12-20T13:59:19.3961',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '3dcb22b1-cb1f-4a82-9c1e-fe251b910601',
			transactionStatus: 'ACTIVE',
			userId: '8d4f494a-9482-484d-92f1-97e61e102d31',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2024-12-20T14:16:56.883659',
			updatedAt: '2024-12-20T14:16:56.883659',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '7c813443-1d45-4a6d-9ca7-e1f3d31a8cd1',
			transactionStatus: 'ACTIVE',
			userId: 'f50c4d76-465c-4107-9c38-63db5fa01c21',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-20T16:39:07.253196',
			updatedAt: '2025-01-20T16:39:07.253196',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '80daa698-e171-4581-973b-488c3a8e25f9',
			transactionStatus: 'ACTIVE',
			userId: '2873d26d-52c9-4ab1-b21f-605d104b4f6f',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-16T17:27:40.968484',
			updatedAt: '2025-01-16T17:27:40.968484',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '44f84747-3e1f-4577-9c35-0a5124fc91dc',
			transactionStatus: 'ACTIVE',
			userId: 'c35442c3-e92a-4efd-9c11-e3db30b6fd67',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-20T16:50:05.538397',
			updatedAt: '2025-01-20T16:50:05.538397',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '58b7a7e7-17ec-4196-aea4-7d4b50dbdfd2',
			transactionStatus: 'ACTIVE',
			userId: 'fadab990-ae10-4b23-a2b3-5177f7be437b',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-20T17:10:07.039371',
			updatedAt: '2025-01-20T17:10:07.039371',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '62b0cfed-2cc2-43a0-80a1-4ddf95d9efdf',
			transactionStatus: 'ACTIVE',
			userId: '0d66bf9d-2f3d-421d-81e4-cf355957c27c',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-28T16:31:10.109312',
			updatedAt: '2025-01-28T16:31:10.109312',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: 'a096c77d-8c8a-4676-bfdf-a0b959e062e9',
			transactionStatus: 'ACTIVE',
			userId: '32c2a5e7-3843-4155-ba86-8700d17cf656',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-29T13:40:42.268657',
			updatedAt: '2025-01-29T13:40:42.268657',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: 'f716ebfa-62e8-4a3b-9997-264413ce2ca5',
			transactionStatus: 'ACTIVE',
			userId: '33661bb3-c7b9-47f0-88b3-44c800b544e7',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-29T14:19:07.659686',
			updatedAt: '2025-01-29T14:19:07.659686',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: 'c9e70fd0-101a-4865-8480-6fedaed7ab3c',
			transactionStatus: 'ACTIVE',
			userId: 'e8974354-19d2-45f8-bac0-97fcc0fabaa0',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-29T22:51:29.946093',
			updatedAt: '2025-01-29T22:51:29.946093',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '44aad9a2-97df-4ae4-99b6-12923d9705c1',
			transactionStatus: 'ACTIVE',
			userId: 'a03490ab-e7b4-42b7-9a04-6e132743cf18',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-30T11:56:13.981898',
			updatedAt: '2025-01-30T11:56:13.981898',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '4787c0c7-7d59-4740-a478-d5763a7c8701',
			transactionStatus: 'ACTIVE',
			userId: 'e94b3717-a0b7-47fa-a8b5-e73c3f74b924',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-30T12:16:37.482288',
			updatedAt: '2025-01-30T12:16:37.482288',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '09a4475d-e69b-46f8-bfe8-a07d70279c73',
			transactionStatus: 'ACTIVE',
			userId: '5a08af5d-1a6d-4e8b-a8f9-ac4579b900e6',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-30T12:41:04.914008',
			updatedAt: '2025-01-30T12:41:04.914008',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
	{
		transaction: {
			id: '73112700-cc14-42bb-b276-bf2e919f5fa7',
			transactionStatus: 'ACTIVE',
			userId: 'a337bde4-d736-488a-9323-3c5023f87c47',
			displayName: 'Auto-generated transaction',
			description: 'Automatically generated transaction',
			createdAt: '2025-01-30T12:52:07.684234',
			updatedAt: '2025-01-30T12:52:07.684234',
			committedAt: null,
			lastCommitAttempt: null,
		},
		events: null,
	},
];

const passTransactionIds = (ids: string[]) => {
	console.log(ids);
};

export const RecordDetailPageUi: React.FC = () => {
	const { t } = useTypedTranslation();

	const dispatch = useAppDispatch();
	const { selectedCatalog } = useCatalogGroups();
	const navigate = useNavigate();

	const { recordId, catalogGroupId } = useParams();

	const items: TabsProps['items'] = [
		{
			key: '1',
			label: t((l) => l.catalogs.records.recordsValues),
			children: <EditCatalogRecord />,
		},
		{
			key: '5',
			label: t((l) => l.catalogs.records.historyTabTitle),
			children: <TransactionsList transactions={tarnsactionsMock} cb={passTransactionIds} />,
		},
	];

	useEffect(() => {
		if (selectedCatalog) {
			dispatch(
				setBreadcrumbs({
					displayName: t((l) => l.catalogs.catalogs),
					url: `${routes.catalogs.main}`,
					child: {
						displayName: `${selectedCatalog.displayName}`,
						url: `${routes.catalogs.main}/${selectedCatalog.id}`,
						child: {
							displayName: t((l) => l.catalogs.records.editRecord),
							url: `${routes.catalogs.main}/${selectedCatalog.id}/record/${recordId}`,
						},
					},
				})
			);
		}
	}, [selectedCatalog, recordId]);

	useEffect(() => {
		return () => {
			dispatch(clearBreadcrumbs());
		};
	}, []);

	const handleBackClick = () => {
		navigate(`/${routes.catalogs.main}/${catalogGroupId}`);
	};

	return (
		<>
			<Flex vertical gap={12} style={{ paddingBottom: 65 }}>
				<Flex justify="space-between">
					<Flex vertical gap={12} justify="start">
						<Typography.Title level={1}>
							{t((l) => l.catalogs.records.recordCard)}
						</Typography.Title>
					</Flex>

					<Transactions />
				</Flex>
				<Col xl={18} xs={24} sm={24} md={24} lg={18}>
					<Tabs items={items} destroyInactiveTabPane />
				</Col>
			</Flex>

			<DetailFooter customHandleBack={handleBackClick} />
		</>
	);
};

export const RecordDetailPage = React.memo(RecordDetailPageUi);
