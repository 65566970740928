import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLazyExportItemsXlsxQuery } from 'shared/api/generatedApi/exportApi';
import {
	useGetCatalogAttributeDeclarationsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useHandleQueryError, useTypedTranslation } from 'shared/hooks';

export type ICatalogRecordsColumnsWithChecked = Array<{
	key: string,
	title: string,
	checked?: boolean;
}>;

export const useExportCatalogRecord = (catalogId: string) => {
	const { t } = useTypedTranslation();

	const { data: catalogRecordsColumns } = useHandleQueryError(
		useGetCatalogAttributeDeclarationsQuery({
			catalogId: catalogId,
		}),
		(l) => l.catalogs.records.declarationsListErr);

	const [ fetchExportItems ] = useLazyExportItemsXlsxQuery();
	const [ catalogRecordsColumnsWithChecked, setCatalogRecordsColumnsWithChecked ] =
		useState<ICatalogRecordsColumnsWithChecked>([]);
	const [ searchColumnsCatalogRecord, setSearchColumnsCatalogRecord ] =
		useState<ICatalogRecordsColumnsWithChecked>([]);
	const { catalogGroupId } = useParams();
	const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
	const [ isAllChecked, setIsAllChecked ] = useState<boolean>(true);

	const openModal = () => setIsModalOpen(true);

	const closeModal = () => setIsModalOpen(false);

	const { notification } = App.useApp();

	const exportItems = () => {
		const allChecked = catalogRecordsColumnsWithChecked.every(item => item.checked);
		const params = {
			id: catalogGroupId,
			...(allChecked ? {} : {
					attributeDeclarationIds: catalogRecordsColumnsWithChecked
						.filter(item => item.checked)
						.map(item => item.key),
				}
			),
		};
		fetchExportItems(params)
			.unwrap()
			.then((res: any) => {
				const fileUrl = res.fileUrl;
				const contentDisposition = res.filenameHeader;
				const today = new Date().toLocaleDateString('ru-RU').replace(/\./g, '-');
				let filename = today + '.xlsx';

				if (contentDisposition) {
					const match = contentDisposition.match(/filename="(.+)"/);
					if (match) {
						filename = today + '-' + match[1];
					}
				}

				const a = document.createElement('a');
				a.href = fileUrl;
				a.download = filename;
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				URL.revokeObjectURL(fileUrl);
			})
			.catch((err) => {
				errorHelper(
					t(l => l.catalogs.export.fetchErr),
					err,
					notification,
				);
			})
			.finally(() => {
				setCatalogRecordsColumnsWithChecked([]);
				setSearchColumnsCatalogRecord([]);
				setIsAllChecked(true);
				changeAllChecked(true, catalogRecordsColumns);
			});
	};

	const changeAllChecked = (isChecked: boolean, arr: ICatalogRecordsColumnsWithChecked | any) => {
		const newCatalogRecordsColumnsWithChecked = arr
			.map((column) => {
				return {
					...column,
					checked: isChecked,
				};
			});
		setCatalogRecordsColumnsWithChecked(newCatalogRecordsColumnsWithChecked);
	};

	const updateColumnCheckedState = (isChecked: boolean, columnKey: string) => {
		const updatedColumnsWithChecked = catalogRecordsColumnsWithChecked.map((column) => {
			if (columnKey === column.key) {
				const updatedSearchColumns = searchColumnsCatalogRecord.map((item) =>
					item.key === column.key ? { ...item, checked: isChecked } : item,
				);
				setSearchColumnsCatalogRecord(updatedSearchColumns);
				return { ...column, checked: isChecked };
			}
			return column;
		});
		const isAllChecked = updatedColumnsWithChecked.every((column) => column.checked);
		setIsAllChecked(isAllChecked);
		setCatalogRecordsColumnsWithChecked(updatedColumnsWithChecked);
	};

	const searchColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newCatalogRecordsColumnsWithChecked = [];
		catalogRecordsColumnsWithChecked.forEach((column) => {
			if (
				typeof column.title === 'string' &&
				e.target.value !== '' &&
				column.title.toLowerCase().includes(e.target.value.toLowerCase())
			) {
				newCatalogRecordsColumnsWithChecked.push(column);
			}
		});
		setSearchColumnsCatalogRecord(newCatalogRecordsColumnsWithChecked);
	};

	const toggleCheck = () => {
		setIsAllChecked(!isAllChecked);
		changeAllChecked(!isAllChecked, catalogRecordsColumnsWithChecked);
	};

	useEffect(() => {
		if (catalogRecordsColumns) {
			const newCatalogRecordsColumnsWithChecked = catalogRecordsColumns
				.map((column) => {
					return {
						key: column.id,
						title: column.attribute.displayName,
						checked: isAllChecked,
					};
				});
			setCatalogRecordsColumnsWithChecked(newCatalogRecordsColumnsWithChecked);
		}
	}, [ catalogRecordsColumns ]);

	return {
		isModalOpen,
		openModal,
		closeModal,
		catalogRecordsColumnsWithChecked,
		isAllChecked,
		toggleCheck,
		updateColumnCheckedState,
		searchColumn,
		searchColumnsCatalogRecord,
		exportItems,
	};
};
