import { Spin } from 'antd';
import moment, { Moment } from 'moment/moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetCatalogAttributeDeclarationsQuery, useGetCatalogItemsQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useHandleQueryError } from 'shared/hooks';
import { ChartGantt, IChartGanttItem } from 'shared/ui';
import { PeriodSelector } from 'shared/ui/components/ChartGantt/PeriodSelector';

const CatalogItemsGanttWidgetUi = ({
									   catalogId,
									   isSubordinatedCatalogsRecordsShown
								   }: any) => {
	const navigate = useNavigate();

	const { data: attributes } = useHandleQueryError(
		useGetCatalogAttributeDeclarationsQuery({
			catalogId: catalogId,
		}),
		(l) => l.catalogs.records.declarationsListErr);

	const {
		data: catalogItems,
	} = useHandleQueryError(
		useGetCatalogItemsQuery({
			catalogIds: [ catalogId ],
			limit: 1,
			page: 50,
			includeSubCatalogs: isSubordinatedCatalogsRecordsShown,
		}),
		(l) => l.catalogs.records.recordsListErr,
	);

	const [ ganttData, setGanttData ] = useState<IChartGanttItem[] | null>(null);
	const [ ganttMoments, setGanttMoments ] = useState<[ Moment, Moment ]>();

	useEffect(() => {
		if (catalogItems?.data && attributes) {
			if (attributes[0]?.catalogId !== catalogId) {
				return;
			}

			const dateTimeAttributes = attributes.filter((x) => x.attribute.type === 'DATE_TIME');

			if (dateTimeAttributes.length === 2) {
				setGanttData(
					catalogItems.data
						.map<IChartGanttItem>((x) => {
							const date1 = (x.values[dateTimeAttributes[0].id] as any)?.value;
							const date2 = (x.values[dateTimeAttributes[1].id] as any)?.value;
							const dates = [
								...(date1 ? [moment(date1)] : []),
								...(date2 ? [moment(date2)] : []),
							];
							return {
								id: x.id,
								title: x.displayName,
								dateFrom: moment.min(dates).startOf('day'),
								dateTo: moment.max(dates).endOf('day'),
							};
						})
						.filter((x) => !!x.dateFrom && !!x.dateTo)
				);
			} else {
				setGanttData([]);
			}
		}
	}, [catalogItems, attributes]);

	const onChangeMoments = useCallback(
		(startMoment: Moment, endMoment: Moment) => setGanttMoments([ startMoment, endMoment ]),
		[],
	);

	const onClickItem = useCallback(
		(id: string | number) =>
			navigate(`/${routes.catalogs.main}/${catalogId}/record/${id}`),
		[ navigate ],
	);

	if (!ganttData) {
		return <Spin size="large"/>;
	}

	return (
		<div>
			<PeriodSelector onChangeMoments={onChangeMoments}/>
			{ganttMoments && ganttMoments[0] && ganttMoments[1] && (
				<ChartGantt
					data={ganttData}
					startMoment={ganttMoments[0]}
					endMoment={ganttMoments[1]}
					onClickItem={onClickItem}
				/>
			)}
		</div>
	);
};

export const CatalogItemsGanttWidget = React.memo(CatalogItemsGanttWidgetUi);