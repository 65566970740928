import { RiDeleteBinLine } from '@remixicon/react';
import { App } from 'antd';
import React from 'react';
import { deleteAttributeDeclaration } from 'entities/catalogs/attributeDeclarations';
import { TaskType, useAsyncOperation } from 'entities/events';
import { useTransactions } from 'entities/transactions';
import { useDeleteCatalogAttributeDeclarationAsyncMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';

interface IDeleteAttributeDeclarationProps {
	id: string;
}

const DeleteAttributeDeclarationUi: React.FC<IDeleteAttributeDeclarationProps> = ({ id }) => {
	const { t } = useTypedTranslation();

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const { execute: deleteDeclaration } = useAsyncOperation(
		useDeleteCatalogAttributeDeclarationAsyncMutation,
		TaskType.DELETE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK
	);

	const { getTransactions } = useTransactions();

	const handleDelete = async (id: string) => {
		try {
			await deleteDeclaration({ ids: [ id ] });
			getTransactions();
			dispatch(deleteAttributeDeclaration(id));
		} catch (error) {
			errorHelper(t(l => l.common.statuses.error), error, notification);
		}
	};

	return (
		<DropdownLink
			title={t(l => l.common.buttons.delete)}
			icon={<RiDeleteBinLine size={16}/>}
			callback={() => handleDelete(id)}
		/>
	);
};

export const DeleteAttributeDeclaration = React.memo(DeleteAttributeDeclarationUi);
