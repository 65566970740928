import { App } from 'antd';
import { useNavigate, useParams } from 'react-router';
import { addCatalogRecord } from 'entities/catalogs/catalogRecords/catalogRecords.store';
import { useCreateCatalogItemMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useAddCatalogRecord = () => {
	const [fetchCreateCatalogRecord] = useCreateCatalogItemMutation();

	const { notification } = App.useApp();

	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const { catalogGroupId } = useParams();

	const createCatalogRecord = () => {
		fetchCreateCatalogRecord({
			catalogId: catalogGroupId,
			createCatalogItemRequest: {
				values: {},
				status: 'NON_NORMALIZED',
			},
		})
			.unwrap()
			.then((res) => {
				dispatch(addCatalogRecord(res));

				navigate(`${window.location.pathname}/record/${res.id}`);
			})
			.catch((err) => {
				errorHelper(
					'Ошибка при добавлении новой группы атрибутов!',
					err,
					notification
				);
			});
	};

	return {
		createCatalogRecord,
	};
};
