import { Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useGetCatalogAttributeDeclarationsQuery, useGetCatalogItemsQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useHandleQueryError } from 'shared/hooks';
import { Calendar, ICalendarItem } from 'shared/ui';

const CatalogItemsCalendarWidgetUi = ({
									   catalogId,
									   isSubordinatedCatalogsRecordsShown
								   }: any) => {
	const navigate = useNavigate();

	const { data: attributes } = useHandleQueryError(
		useGetCatalogAttributeDeclarationsQuery({
			catalogId: catalogId,
		}),
		(l) => l.catalogs.records.declarationsListErr);

	const {
		data: catalogItems
	} = useHandleQueryError(
		useGetCatalogItemsQuery({
			catalogIds: [ catalogId ],
			limit: 1,
			page: 50,
			includeSubCatalogs: isSubordinatedCatalogsRecordsShown,
		}),
		(l) => l.catalogs.records.recordsListErr,
	);

	const [calendarData, setCalendarData] = useState<any>(null);

	useEffect(() => {
		if (catalogItems?.data && attributes) {
			if (attributes[0]?.catalogId !== catalogId) {
				return;
			}

			const dateTimeAttributes = attributes.filter((x) => x.attribute.type === 'DATE_TIME');

			if (dateTimeAttributes.length === 1) {
				setCalendarData(
					catalogItems.data.map<ICalendarItem>((x) => {
						const date = (x.values[dateTimeAttributes[0].id] as any)?.value;
						return {
							id: x.id,
							title: x.displayName,
							date: date,
						};
					})
				);
			} else {
				setCalendarData([]);
			}
		}
	}, [catalogItems, attributes]);

	const onClickItem = useCallback(
		(id: string | number) =>
			navigate(`/${routes.catalogs.main}/${catalogId}/record/${id}`),
		[ navigate ],
	);

	if (!calendarData) {
		return <Spin size="large"/>;
	}

	return (
		<Calendar data={calendarData} onClickItem={onClickItem} />
	);
};

export const CatalogItemsCalendarWidget = React.memo(CatalogItemsCalendarWidgetUi);