import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useLazyGetClassifierQuery } from 'shared/api/generatedApi/mdmgApi';
import { useLazyGetClassifiersQuery } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppSelector, useAppDispatch } from 'shared/hooks';
import { useDidUpdateEffect } from 'shared/hooks/useDidUpdateEffect';
import { setCurrentClassifier, setClassifiersGroups } from './classifier.store';

export const useGetClassifiersGroups = () => {
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);

	const [getGroups, { isLoading }] = useLazyGetClassifiersQuery();
	const { notification } = App.useApp();
	const { classifiersGroups } = useAppSelector(
		(state) => state.entities.classifiers.classifierGroups
	);
	const { transactionRollback } = useAppSelector((state) => state.entities.transactions);
	const dispatch = useAppDispatch();

	const [searchParams] = useSearchParams();

	const getData = async () =>
		await getGroups({})
			.unwrap()
			.then((res) => dispatch(setClassifiersGroups(res)))
			.catch((e) => {
				errorHelper('Ошибка при получении списка классификаторов!', e, notification);
				setError(e);
			});

	useEffect(() => {
		getData();
	}, [transactionRollback]);

	useEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (!classifiersGroups && !searchValue) {
			getData();
		}
	}, []);

	useDidUpdateEffect(() => {
		const searchValue = searchParams.get('searchValue');
		if (!searchValue) {
			getData();
		}
	}, [searchParams.get('searchValue')]);

	return {
		loading: isLoading,
		error,
		classifiersGroups,
	};
};

export const useGetCurrentClassifierGroup = () => {
	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);
	const { classifierGroupId } = useParams();
	const {
		loading: groupLoading,
		error: groupError,
		classifiersGroups,
	} = useGetClassifiersGroups();
	const dispatch = useAppDispatch();
	const { currentClassifierGroup } = useAppSelector(
		(state) => state.entities.classifiers.classifierGroups
	);
	const [getGroup, { isLoading }] = useLazyGetClassifierQuery();
	const { notification } = App.useApp();

	useEffect(() => {
		if (!currentClassifierGroup) {
			if (classifierGroupId && classifiersGroups) {
				const currGroup = classifiersGroups.find((item) => item.id === classifierGroupId);
				if (currGroup) {
					dispatch(setCurrentClassifier(currGroup));
				} else {
					getGroup({ id: classifierGroupId })
						.unwrap()
						.then((res) => {
							dispatch(setCurrentClassifier(res));
						})
						.catch((e) => {
							errorHelper('Ошибка при получении классификатора!', e, notification);
							setError(e);
						});
				}
			}
		}
	}, [classifierGroupId, classifiersGroups, currentClassifierGroup]);

	return {
		currentClassifierGroup,
		loading: groupLoading || isLoading,
		error: groupError || error,
	};
};
