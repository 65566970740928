import { embedDashboard } from '@superset-ui/embedded-sdk';
import React, { useEffect } from 'react';
import { dashboardApi } from '../../api/dashboardApi';
import s from './EmbeddedDashboard.module.scss';

export const EmbeddedDashboard = () => {
  useEffect(() => {
    const mountPoint = document.getElementById('dashboard-container');
    if (mountPoint) {
      const config = dashboardApi.getDashboardConfig(mountPoint);
      embedDashboard(config);
    }
  }, []);

  return (
    <div id="dashboard-container" className={s.embeddedDashboard} />
  );
}; 
