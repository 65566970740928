import { useContext } from 'react';
import { StompContext } from './StompProvider';

export const useStomp = () => {
	const context = useContext(StompContext);

	if (!context) {
		throw new Error('useStomp must be used within a StompProvider');
	}

	const { subscribe } = context;

	return {
		subscribe,
	};
};