import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogAttributeDeclarationResponse } from 'shared/api/generatedApi/mdmgApi';

interface IAttributesState {
	attributeDeclarationsList: Array<CatalogAttributeDeclarationResponse>;
}

const initialState: IAttributesState = {
	attributeDeclarationsList: null,
};

const attributesSlice = createSlice({
	name: 'attributes',
	initialState,
	reducers: {
		setAttributeDeclarationsList(
			state,
			{ payload }: PayloadAction<Array<CatalogAttributeDeclarationResponse>>
		) {
			state.attributeDeclarationsList = payload;
		},
		deleteAttributeDeclaration(state, { payload }: PayloadAction<string>) {
			state.attributeDeclarationsList = state?.attributeDeclarationsList?.filter(
				(catalog) => catalog.id !== payload
			);
		},
		removeAttributeDeclarationList(state) {
			state.attributeDeclarationsList = null;
		},
	},
});

export const {
	setAttributeDeclarationsList,
	deleteAttributeDeclaration,
	removeAttributeDeclarationList,
} = attributesSlice.actions;

export const attributeDeclarationsListReducer = attributesSlice.reducer;
