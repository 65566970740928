import { Flex, Button } from 'antd';
import React, { PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { useTypedTranslation } from '../../../hooks';
import s from './detailedFooter.module.scss';

interface IdetailFooterProps {
	children?: ReactNode;
	isNavHidden?: boolean;
	navState?: {
		link: string;
		state: number;
	};
	customHandleBack?: () => void;
}

export const DetailFooter: React.FC<PropsWithChildren<IdetailFooterProps>> = ({
	children,
	isNavHidden,
	navState,
	customHandleBack,
}) => {
	const navigate = useNavigate();
	const { t } = useTypedTranslation();

	const handleBack = () => {
		if (customHandleBack) {
			customHandleBack();
			return;
		}

		if (navState) {
			navigate(navState.link, { state: navState.state });
			return;
		}
		navigate(-1);
	};

	return (
		<Flex className={s.detailFooter} justify={!isNavHidden ? 'space-between' : 'flex-end'}>
			{!isNavHidden && (
				<>
					<Button type="primary" onClick={handleBack}>
						{t((l) => l.common.buttons.back)}
					</Button>
				</>
			)}
			<Flex gap={4}>{children}</Flex>
		</Flex>
	);
};
