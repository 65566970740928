import { RiLinkM } from '@remixicon/react';
import { Flex, Skeleton, Typography } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { useGetCatalogsByAttributeQuery } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Chip } from 'shared/ui';

interface UsageAttributeInCatalogsProps {
	attributeId: string;
}

const UsageAttributeInCatalogsUi = ({ attributeId }: UsageAttributeInCatalogsProps) => {
	const navigate = useNavigate();
	const { t } = useTypedTranslation();

	const {
		data: usageList,
		isLoading: usageLoading,
		error: usageError,
	} = useGetCatalogsByAttributeQuery({
		attributeId: attributeId,
	});

	return (
		<Flex vertical gap={12}>
			<Typography.Title level={3}>
				{t((l) => l.attributes.applicabilityinCatalogs)}
			</Typography.Title>
			<Flex gap={4} wrap="wrap">
				{usageLoading && <Skeleton />}
				{usageError && (
					<Typography.Text>
						{t((l) => l.attributes.applicabilityListError)}
					</Typography.Text>
				)}
				{!usageList?.length && (
					<Typography.Text>{t((l) => l.attributes.unusedAttribute)}</Typography.Text>
				)}
				{usageList?.length > 0 &&
					usageList.map((item) => (
						<Chip
							color="primary"
							icon={<RiLinkM size={16} />}
							key={item.id}
							onClick={() => navigate(`/${routes.catalogs.main}/${item?.id}`)}
							style={{ color: colors.primary }}
						>
							{item?.displayName}
						</Chip>
					))}
			</Flex>
		</Flex>
	);
};

export const UsageAttributeInCatalogs = React.memo(UsageAttributeInCatalogsUi);
