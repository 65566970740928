import { Flex, Typography } from 'antd';
import React, { useEffect } from 'react';
import { DetailMeasuresGroup } from 'widgets/Metadata/Measures/DetailMeasuresGroup';
import { Transactions } from 'widgets/Transactions';
import { setBreadcrumbs } from 'entities/breadcrumbs';
import { removeCurrentMeasureGroup } from 'entities/metadata/measures';
import { routes } from 'shared/config';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';

const DetailMeasureGroupPageUi = () => {
	const { t } = useTypedTranslation();
	const dispatch = useAppDispatch();
	const { currentMeasureGroup } = useAppSelector((state) => state.entities.metadata.measures);

	useEffect(() => {
		return () => {
			dispatch(removeCurrentMeasureGroup());
		};
	}, []);

	useEffect(() => {
		if (currentMeasureGroup) {
			dispatch(
				setBreadcrumbs({
					displayName: t(l => l.breadcrumbs.metadata),
					url: `${routes.metadata.main}/${routes.metadata.measures}`,
					child: {
						displayName: `${currentMeasureGroup.displayName}`,
						url: window.location.pathname,
					},
				})
			);
		}
	}, [currentMeasureGroup]);

	return (
		<Flex vertical gap={24}>
			<Flex justify="space-between">
				<Flex vertical gap={12}>
					<Typography.Title level={1} style={{ color: colors.primary }}>
						{currentMeasureGroup?.displayName}
					</Typography.Title>
				</Flex>
				<Transactions />
			</Flex>

			<DetailMeasuresGroup />
		</Flex>
	);
};

export const DetailMeasureGroupPage = React.memo(DetailMeasureGroupPageUi);
