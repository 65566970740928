import { RiDownloadLine } from '@remixicon/react';
import { App, Button, Flex, Modal } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { TaskType, useAsyncOperation } from 'entities/events';
import { mdmgApi, useImportXlsxAsyncMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';
import { UploadBase64File } from 'shared/ui';

const ImportCatalogRecordUi = () => {
	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const { catalogGroupId } = useParams();

	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const [ base64, setBase64 ] = useState<Array<string>>([]);
	const [ loading, setLoading ] = useState<boolean>(false);

	const { execute: importRecord } = useAsyncOperation(
		useImportXlsxAsyncMutation,
		TaskType.IMPORT_BACKGROUND_TASK,
	);

	const handleImport = async (base64: string) => {
		try {
			setLoading(true);

			await importRecord({
				catalogId: catalogGroupId,
				xlsxImportCatalogItemsRequest: { content: base64 },
			});

			dispatch(mdmgApi.util.invalidateTags(['CatalogItems']));

			return true;
		} catch (e) {
			errorHelper('Ошибка при импорте записи!', e, notification);
			return false;
		} finally {
			setLoading(false);
		}
	};

	const showModal = () => setIsModalOpen(true);
	const handleOk = () => setIsModalOpen(false);

	const handleCancel = () => {
		setIsModalOpen(false);
		setBase64([]);
	};

	const handleClick = async () => {
		const res = await handleImport(base64[0]);
		if (res) {
			handleCancel();
		}
	};

	return (
		<>
			<Button icon={<RiDownloadLine style={{ rotate: '180deg' }}/>} onClick={showModal}>
				Импортировать
			</Button>
			<Modal
				title="Импорт из Excel"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Flex vertical gap={12}>
					<UploadBase64File setString={setBase64}/>
					<Flex gap={4} justify="flex-end">
						<Button onClick={handleCancel}>Отменить</Button>
						<Button
							type="primary"
							disabled={!base64.length}
							loading={loading}
							onClick={handleClick}
						>
							Импортировать
						</Button>
					</Flex>
				</Flex>
			</Modal>
		</>
	);
};

export const ImportCatalogRecord = React.memo(ImportCatalogRecordUi);
