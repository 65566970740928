import { Checkbox, Flex, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useEffect, useState } from 'react';
import { TransactionResponse } from 'shared/api/generatedApi/transactionServiceApi';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { DetailCard } from 'shared/ui';
import s from './TransactionsList.module.scss';

interface ITransactionsList {
	transactions: TransactionResponse[];
	cb: (ids: string[]) => void;
}

export const TransactionsList: React.FC<ITransactionsList> = ({ transactions, cb }) => {
	const [sortedTransactions, setSortedTransactions] = useState<TransactionResponse[]>([]);
	const [ids, setIds] = useState<string[]>([]);
	const { t } = useTypedTranslation();

	const handlerChange = (e: CheckboxChangeEvent) => {
		let newIds: string[];

		if (e.target.checked) {
			newIds = [...ids, e.target.name];
			setIds(newIds);
		} else {
			newIds = ids.filter((x) => x !== e.target.name);
			setIds(newIds);
		}

		cb(newIds);
	};

	useEffect(() => {
		if (!transactions) return;

		const sortedTransaction = !transactions.length
			? []
			: transactions?.sort((a, b) => {
					return (
						new Date(b.transaction.updatedAt).getTime() -
						new Date(a.transaction.updatedAt).getTime()
					);
				});

		setSortedTransactions(sortedTransaction);
	}, [transactions]);

	return (
		<div className={s.container}>
			<Flex vertical gap={0}>
				{sortedTransactions.map((x) => (
					<div className={s.item} key={x.transaction.id}>
						<DetailCard
							secondSection={
								<Flex vertical gap={12}>
									<Flex vertical gap={4}>
										<Typography.Paragraph>
											{x.transaction.displayName}
										</Typography.Paragraph>
										<Typography.Paragraph>
											<span style={{ color: colors.grayIcon }}>
												{new Date(x.transaction.updatedAt).toLocaleString(
													'ru-RU'
												)}{' '}
												•{' '}
												{'userName' in x.transaction
													? (x.transaction.userName as string)
													: t((l) => l.common.defaultNames.emptyName)}
											</span>
										</Typography.Paragraph>
									</Flex>
									<Checkbox
										name={x.transaction.id}
										onChange={handlerChange}
										checked={ids.includes(x.transaction.id)}
									>
										{t((l) => l.transactions.addToCompareTitle)}
									</Checkbox>
								</Flex>
							}
						/>
					</div>
				))}
			</Flex>
		</div>
	);
};
