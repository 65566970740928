import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AttributeDto } from 'shared/api/generatedApi/mdmgApi';

interface IAttributesList {
	metaAttributes: Array<AttributeDto>;
	currentAttribute: AttributeDto;
}

const initialState: IAttributesList = {
	metaAttributes: null,
	currentAttribute: null,
};

const metaAttributesSlice = createSlice({
	name: 'metaAttributes',
	initialState,
	reducers: {
		setMetaAttributes(state, { payload }: PayloadAction<AttributeDto[]>) {
			state.metaAttributes = payload;
		},
		updateMetaAttributes(state, { payload }: PayloadAction<AttributeDto[]>) {
			state.metaAttributes.push(...payload);
		},
		updateMetaAttributeItem(state, { payload }: PayloadAction<AttributeDto>) {
			state.metaAttributes = state?.metaAttributes?.map((item) => {
				if (item.id === payload.id) {
					return payload;
				}
				return item;
			});
		},
		deleteMetaAttributeItems(state, { payload }: PayloadAction<Array<string>>) {
			const deleteAttributes = (
				attributes: Array<AttributeDto>,
				ids: Array<string>
			): Array<AttributeDto> => {
				return attributes.filter((item) => !ids.includes(item.id));
			};
			state.metaAttributes = state.metaAttributes
				? [...deleteAttributes(state.metaAttributes, payload)]
				: null;
		},
		removeMetaAttributes(state) {
			state.metaAttributes = [];
		},
		setCurrentMetaAttribute(state, { payload }: PayloadAction<AttributeDto>) {
			state.currentAttribute = payload;
		},
		removeCurrentMetaAttribute(state) {
			state.currentAttribute = null;
		},
	},
});

export const {
	setMetaAttributes,
	updateMetaAttributes,
	updateMetaAttributeItem,
	deleteMetaAttributeItems,
	removeMetaAttributes,
	setCurrentMetaAttribute,
	removeCurrentMetaAttribute,
} = metaAttributesSlice.actions;

export const metaAttributesReducer = metaAttributesSlice.reducer;
