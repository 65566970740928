import { Flex, Typography } from 'antd';
import React from 'react';
import { EmbeddedDashboard } from 'features/data-quality';

export const DataQualityPage = () => {
  return (
    <Flex vertical gap={24}>
      <Flex justify="space-between" align="top">
        <Typography.Title level={1}>
          Качество данных
        </Typography.Title>
      </Flex>
      <EmbeddedDashboard />
    </Flex>
  );
}; 