import { transactionServiceApi as api } from './newCreateApiFile';
const injectedRtkApi = api.injectEndpoints({
	endpoints: (build) => ({
		getTransactionData: build.query<GetTransactionDataApiResponse, GetTransactionDataApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/transactions/${queryArg.id}`,
				params: { full: queryArg.full },
			}),
		}),
		updateTransaction: build.mutation<UpdateTransactionApiResponse, UpdateTransactionApiArg>({
			query: (queryArg) => ({
				url: `/api/v1/transactions/${queryArg.id}`,
				method: 'PUT',
				body: queryArg.updateTransactionRequest,
			}),
		}),
		deleteTransaction: build.mutation<DeleteTransactionApiResponse, DeleteTransactionApiArg>({
			query: (queryArg) => ({ url: `/api/v1/transactions/${queryArg.id}`, method: 'DELETE' }),
		}),
		commitTransaction: build.mutation<CommitTransactionApiResponse, CommitTransactionApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/transactions/commit',
				method: 'POST',
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getTransactionsData: build.query<GetTransactionsDataApiResponse, GetTransactionsDataApiArg>(
			{
				query: (queryArg) => ({
					url: '/api/v1/transactions',
					params: {
						userId: queryArg.userId,
						full: queryArg.full,
						status: queryArg.status,
					},
				}),
			}
		),
		createTransaction: build.mutation<CreateTransactionApiResponse, CreateTransactionApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/transactions',
				method: 'POST',
				body: queryArg.createTransactionRequest,
				headers: { Authorization: queryArg.authorization },
			}),
		}),
		getEventsByEntityId: build.query<GetEventsByEntityIdApiResponse, GetEventsByEntityIdApiArg>(
			{
				query: (queryArg) => ({
					url: `/api/v1/transactions/${queryArg.id}/events`,
					params: {
						limit: queryArg.limit,
						page: queryArg.page,
						sortBy: queryArg.sortBy,
						sortType: queryArg.sortType,
						entityIds: queryArg.entityIds,
						entityTypes: queryArg.entityTypes,
					},
				}),
			}
		),
		getBlockingEntities: build.query<GetBlockingEntitiesApiResponse, GetBlockingEntitiesApiArg>(
			{
				query: (queryArg) => ({
					url: '/api/v1/transactions/validation',
					headers: { Authorization: queryArg.authorization },
				}),
			}
		),
		getBlockedEntities: build.query<GetBlockedEntitiesApiResponse, GetBlockedEntitiesApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/transactions/locks',
				params: {
					parentEntityId: queryArg.parentEntityId,
					entityType: queryArg.entityType,
				},
			}),
		}),
		getClassifierItemEvents: build.query<
			GetClassifierItemEventsApiResponse,
			GetClassifierItemEventsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/events/classifier-items',
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					classifierId: queryArg.classifierId,
					parentItemId: queryArg.parentItemId,
					root: queryArg.root,
					ids: queryArg.ids,
					eventType: queryArg.eventType,
				},
			}),
		}),
		getCatalogItemEvents: build.query<
			GetCatalogItemEventsApiResponse,
			GetCatalogItemEventsApiArg
		>({
			query: (queryArg) => ({
				url: '/api/v1/events/catalog-items',
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					catalogId: queryArg.catalogId,
					ids: queryArg.ids,
					eventType: queryArg.eventType,
				},
			}),
		}),
		getAttributeEvents: build.query<GetAttributeEventsApiResponse, GetAttributeEventsApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/events/attributes',
				params: {
					limit: queryArg.limit,
					page: queryArg.page,
					sortBy: queryArg.sortBy,
					sortType: queryArg.sortType,
					type: queryArg['type'],
					ids: queryArg.ids,
					attributeGroupIds: queryArg.attributeGroupIds,
					eventType: queryArg.eventType,
				},
			}),
		}),
		removeLastEvent: build.mutation<RemoveLastEventApiResponse, RemoveLastEventApiArg>({
			query: (queryArg) => ({
				url: '/api/v1/transactions/events',
				method: 'DELETE',
				headers: { Authorization: queryArg.authorization },
			}),
		}),
	}),
	overrideExisting: false,
});
export { injectedRtkApi as transactionServiceApi };
export type GetTransactionDataApiResponse = /** status 200 OK */ TransactionResponse;
export type GetTransactionDataApiArg = {
	id: string;
	full?: boolean;
};
export type UpdateTransactionApiResponse = /** status 200 OK */ TransactionResponse;
export type UpdateTransactionApiArg = {
	id: string;
	updateTransactionRequest: UpdateTransactionRequest;
};
export type DeleteTransactionApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type DeleteTransactionApiArg = {
	id: string;
};
export type CommitTransactionApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type CommitTransactionApiArg = {
	authorization: string;
};
export type GetTransactionsDataApiResponse = /** status 200 OK */ TransactionResponse[];
export type GetTransactionsDataApiArg = {
	userId?: string;
	full?: boolean;
	status?: (
		| 'ACTIVE'
		| 'PAUSED'
		| 'COMMITED'
		| 'TO_COMMIT'
		| 'PROCESSING_COMMIT'
		| 'COMMIT_ERROR'
	)[];
};
export type CreateTransactionApiResponse = /** status 200 OK */ TransactionResponse;
export type CreateTransactionApiArg = {
	authorization?: string;
	createTransactionRequest: CreateTransactionRequest;
};
export type GetEventsByEntityIdApiResponse = /** status 200 OK */ SliceStateChangeEventDto;
export type GetEventsByEntityIdApiArg = {
	id: string;
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	entityIds?: string[];
	entityTypes?: string[];
};
export type GetBlockingEntitiesApiResponse = /** status 200 OK */ BlockingEventResponse[];
export type GetBlockingEntitiesApiArg = {
	authorization: string;
};
export type GetBlockedEntitiesApiResponse = /** status 200 OK */ BlockedEntityResponse[];
export type GetBlockedEntitiesApiArg = {
	parentEntityId: string;
	entityType:
		| 'CATALOG'
		| 'ATTRIBUTE'
		| 'ATTRIBUTE_GROUP'
		| 'ATTRIBUTE_DECLARATION'
		| 'EXCHANGE_CLASS'
		| 'EXCHANGE_KEY'
		| 'ITEM'
		| 'MEASUREMENT'
		| 'MEASUREMENT_GROUP'
		| 'CLASSIFIER'
		| 'CLASSIFIER_ATTRIBUTE_DECLARATION'
		| 'CLASSIFIER_ITEM'
		| 'RESTRICTION_TABLE_DECLARATION'
		| 'RESTRICTION_TABLE'
		| 'RESTRICTION_TABLE_ITEM'
		| 'FORMULA'
		| 'UNKNOWN';
};
export type GetClassifierItemEventsApiResponse = /** status 200 OK */ SliceStateChangeEventDto;
export type GetClassifierItemEventsApiArg = {
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	classifierId?: string;
	parentItemId?: string;
	root?: boolean;
	ids?: string[];
	eventType?: string;
};
export type GetCatalogItemEventsApiResponse = /** status 200 OK */ SliceStateChangeEventDto;
export type GetCatalogItemEventsApiArg = {
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	catalogId?: string;
	ids?: string[];
	eventType?: string;
};
export type GetAttributeEventsApiResponse = /** status 200 OK */ SliceStateChangeEventDto;
export type GetAttributeEventsApiArg = {
	limit?: number;
	page?: number;
	sortBy?: string;
	sortType?: string;
	type?: string;
	ids?: string[];
	attributeGroupIds?: string[];
	eventType?: string;
};
export type RemoveLastEventApiResponse = /** status 200 OK */
	| '100 CONTINUE'
	| '101 SWITCHING_PROTOCOLS'
	| '102 PROCESSING'
	| '103 EARLY_HINTS'
	| '103 CHECKPOINT'
	| '200 OK'
	| '201 CREATED'
	| '202 ACCEPTED'
	| '203 NON_AUTHORITATIVE_INFORMATION'
	| '204 NO_CONTENT'
	| '205 RESET_CONTENT'
	| '206 PARTIAL_CONTENT'
	| '207 MULTI_STATUS'
	| '208 ALREADY_REPORTED'
	| '226 IM_USED'
	| '300 MULTIPLE_CHOICES'
	| '301 MOVED_PERMANENTLY'
	| '302 FOUND'
	| '302 MOVED_TEMPORARILY'
	| '303 SEE_OTHER'
	| '304 NOT_MODIFIED'
	| '305 USE_PROXY'
	| '307 TEMPORARY_REDIRECT'
	| '308 PERMANENT_REDIRECT'
	| '400 BAD_REQUEST'
	| '401 UNAUTHORIZED'
	| '402 PAYMENT_REQUIRED'
	| '403 FORBIDDEN'
	| '404 NOT_FOUND'
	| '405 METHOD_NOT_ALLOWED'
	| '406 NOT_ACCEPTABLE'
	| '407 PROXY_AUTHENTICATION_REQUIRED'
	| '408 REQUEST_TIMEOUT'
	| '409 CONFLICT'
	| '410 GONE'
	| '411 LENGTH_REQUIRED'
	| '412 PRECONDITION_FAILED'
	| '413 PAYLOAD_TOO_LARGE'
	| '413 REQUEST_ENTITY_TOO_LARGE'
	| '414 URI_TOO_LONG'
	| '414 REQUEST_URI_TOO_LONG'
	| '415 UNSUPPORTED_MEDIA_TYPE'
	| '416 REQUESTED_RANGE_NOT_SATISFIABLE'
	| '417 EXPECTATION_FAILED'
	| '418 I_AM_A_TEAPOT'
	| '419 INSUFFICIENT_SPACE_ON_RESOURCE'
	| '420 METHOD_FAILURE'
	| '421 DESTINATION_LOCKED'
	| '422 UNPROCESSABLE_ENTITY'
	| '423 LOCKED'
	| '424 FAILED_DEPENDENCY'
	| '425 TOO_EARLY'
	| '426 UPGRADE_REQUIRED'
	| '428 PRECONDITION_REQUIRED'
	| '429 TOO_MANY_REQUESTS'
	| '431 REQUEST_HEADER_FIELDS_TOO_LARGE'
	| '451 UNAVAILABLE_FOR_LEGAL_REASONS'
	| '500 INTERNAL_SERVER_ERROR'
	| '501 NOT_IMPLEMENTED'
	| '502 BAD_GATEWAY'
	| '503 SERVICE_UNAVAILABLE'
	| '504 GATEWAY_TIMEOUT'
	| '505 HTTP_VERSION_NOT_SUPPORTED'
	| '506 VARIANT_ALSO_NEGOTIATES'
	| '507 INSUFFICIENT_STORAGE'
	| '508 LOOP_DETECTED'
	| '509 BANDWIDTH_LIMIT_EXCEEDED'
	| '510 NOT_EXTENDED'
	| '511 NETWORK_AUTHENTICATION_REQUIRED';
export type RemoveLastEventApiArg = {
	authorization: string;
};
export type TransactionDto = {
	id: string;
	transactionStatus?:
		| 'ACTIVE'
		| 'PAUSED'
		| 'COMMITED'
		| 'TO_COMMIT'
		| 'PROCESSING_COMMIT'
		| 'COMMIT_ERROR';
	userId: string;
	displayName?: string;
	description?: string;
	createdAt?: string;
	updatedAt?: string;
	committedAt?: string;
	lastCommitAttempt?: string;
};
export type StateChangeEventDto = {
	id: string;
	entityId: string;
	entityType:
		| 'CATALOG'
		| 'ATTRIBUTE'
		| 'ATTRIBUTE_GROUP'
		| 'ATTRIBUTE_DECLARATION'
		| 'EXCHANGE_CLASS'
		| 'EXCHANGE_KEY'
		| 'ITEM'
		| 'MEASUREMENT'
		| 'MEASUREMENT_GROUP'
		| 'CLASSIFIER'
		| 'CLASSIFIER_ATTRIBUTE_DECLARATION'
		| 'CLASSIFIER_ITEM'
		| 'RESTRICTION_TABLE_DECLARATION'
		| 'RESTRICTION_TABLE'
		| 'RESTRICTION_TABLE_ITEM'
		| 'FORMULA'
		| 'UNKNOWN';
	eventType:
		| 'CREATE'
		| 'UPDATE'
		| 'DELETE'
		| 'ROLLBACK_CREATE'
		| 'ROLLBACK_UPDATE'
		| 'ROLLBACK_DELETE'
		| 'BEGIN_TRANSACTION'
		| 'END_TRANSACTION';
	userId: string;
	parentEventId?: string;
	transactionId: string;
	data: {
		[key: string]: object;
	};
	createdAt: string;
	committedAt?: string;
};
export type TransactionResponse = {
	transaction: TransactionDto;
	events?: StateChangeEventDto[];
};
export type UpdateTransactionRequest = {
	displayName?: string;
	description?: string;
	transactionStatus?:
		| 'ACTIVE'
		| 'PAUSED'
		| 'COMMITED'
		| 'TO_COMMIT'
		| 'PROCESSING_COMMIT'
		| 'COMMIT_ERROR';
	userId?: string;
};
export type CreateTransactionRequest = {
	displayName: string;
	description?: string;
};
export type SliceMeta = {
	page: number;
	limit: number;
	total: number;
	sortBy: string;
	sortType: string;
};
export type SliceStateChangeEventDto = {
	data: StateChangeEventDto[];
	meta: SliceMeta;
};
export type BlockingEventResponse = {
	entityId: string;
	notification?: string;
};
export type BlockedEntityResponse = {
	transactionId: string;
	entityIds: string[];
};
export const {
	useGetTransactionDataQuery,
	useLazyGetTransactionDataQuery,
	useUpdateTransactionMutation,
	useDeleteTransactionMutation,
	useCommitTransactionMutation,
	useGetTransactionsDataQuery,
	useLazyGetTransactionsDataQuery,
	useCreateTransactionMutation,
	useGetEventsByEntityIdQuery,
	useLazyGetEventsByEntityIdQuery,
	useGetBlockingEntitiesQuery,
	useLazyGetBlockingEntitiesQuery,
	useGetBlockedEntitiesQuery,
	useLazyGetBlockedEntitiesQuery,
	useGetClassifierItemEventsQuery,
	useLazyGetClassifierItemEventsQuery,
	useGetCatalogItemEventsQuery,
	useLazyGetCatalogItemEventsQuery,
	useGetAttributeEventsQuery,
	useLazyGetAttributeEventsQuery,
	useRemoveLastEventMutation,
} = injectedRtkApi;
