import { RiArrowDownSLine, RiArrowUpSLine } from '@remixicon/react';
import {
	Checkbox,
	Collapse,
	Flex,
	Form,
	Input,
	Result,
	Skeleton,
	Tree,
	TreeProps,
	Typography,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useAttributesDeclarations } from 'entities/catalogs/attributeDeclarations/attributeDeclarations.model';
import { useCatalogRestrictionTableDeclarations } from 'entities/catalogs/catalogRestrictions';
import { useConstraintTables } from 'entities/metadata/constraintTables';
import {
	CatalogRestrictionTableDeclarationResponse,
	RestrictionTableResponse,
} from 'shared/api/generatedApi/mdmgApi';
import { colors } from 'shared/styles';
import { Placeholder, Search, SelectModal } from 'shared/ui';
import ItemActions from 'shared/ui/components/ItemActions';
import { DeleteCatalogRestrictionTable } from '../DeleteCatalogRestrictionTable';
import { useEditCatalogRestrictionsTables } from './EditCatalogRestrictionsTables.model';

const panelStyle: React.CSSProperties = {
	marginBottom: 24,
	backgroundColor: colors.whiteBase,
	borderRadius: '6px',
	border: '1px solid var(--grayTableBorder)',
};

interface ITableCollapse {
	id: string;
	restrictionTableId: string;
	displayName: string;
	required: boolean;
}

const EditCatalogRestrictionsTablesUi = () => {
	const [tables, setTables] = useState<Array<RestrictionTableResponse>>([]);
	const [requiredAttributes, setRequiredAttributes] = useState<Array<React.Key>>([]);
	const [uniqueAttributes, setUniqueAttributes] = useState<Array<React.Key>>([]);
	const { catalogRestrictionTablesDeclarations, isLoading, error, onChangeRequired } =
		useCatalogRestrictionTableDeclarations();
	const { getConstraintTables, loading, error: tablesErr } = useConstraintTables();
	const { attributes, loading: isAttributesLoading } = useAttributesDeclarations();

	const { edit, searchHandler, searchValue } = useEditCatalogRestrictionsTables();

	const restrictionTablesDtoToCollapseMapper = useCallback(
		(
			tables: RestrictionTableResponse[],
			declarations: CatalogRestrictionTableDeclarationResponse[]
		): ITableCollapse[] => {
			if (!tables || !catalogRestrictionTablesDeclarations) return [];
			return tables.map((table) => {
				const declaration = declarations.find(
					(decl) => decl.restrictionTable.id === table.id
				);
				return {
					displayName: table.displayName,
					id: declaration?.id,
					required: declaration?.required,
					restrictionTableId: table.id,
				};
			});
		},
		[tables, catalogRestrictionTablesDeclarations]
	);

	const handleChangeRequired = (declarationId: string, isChecked: boolean) => {
		onChangeRequired(declarationId, isChecked);
	};

	const onRequiredCheck: TreeProps['onCheck'] = (checkedKeysValue) => {
		setRequiredAttributes(checkedKeysValue as React.Key[]);
	};

	const onUniqueCheck: TreeProps['onCheck'] = (checkedKeysValue) => {
		setUniqueAttributes(checkedKeysValue as React.Key[]);
	};

	const onRequireFinish = () => {
		attributes.forEach((attr) => {
			if (requiredAttributes.includes(attr.attribute.id)) {
				edit({
					id: attr.id,
					updateCatalogAttributeDeclarationRequest: {
						restrictions: {
							...attr.attribute.restrictions,
							required: true as unknown as object,
						},
					},
				});
			} else if (attr.restrictions.required) {
				edit({
					id: attr.id,
					updateCatalogAttributeDeclarationRequest: {
						restrictions: {
							...attr.attribute.restrictions,
							required: false as unknown as object,
						},
					},
				});
			}
		});
	};

	const onUniqueFinish = () => {
		attributes.forEach((attr) => {
			if (uniqueAttributes.includes(attr.attribute.id)) {
				edit({
					id: attr.id,
					updateCatalogAttributeDeclarationRequest: {
						restrictions: {
							...attr.attribute.restrictions,
							unique: true as unknown as object,
						},
					},
				});
			} else if (attr.restrictions.unique) {
				edit({
					id: attr.id,
					updateCatalogAttributeDeclarationRequest: {
						restrictions: {
							...attr.attribute.restrictions,
							unique: false as unknown as object,
						},
					},
				});
			}
		});
	};

	const onRemoveUniqueAttr = (id) => {
		const attr = attributes.find((item) => item.attribute.id === id);
		edit({
			id: attr.id,
			updateCatalogAttributeDeclarationRequest: {
				restrictions: {
					...attr.attribute.restrictions,
					unique: false as unknown as object,
				},
			},
		});
		setUniqueAttributes((prev) => prev.filter((id) => id !== attr.attribute.id));
	};

	const onRemoveRequiredAttr = (id) => {
		const attr = attributes.find((item) => item.attribute.id === id);
		edit({
			id: attr.id,
			updateCatalogAttributeDeclarationRequest: {
				restrictions: {
					...attr.attribute.restrictions,
					required: false as unknown as object,
				},
			},
		});
		setRequiredAttributes((prev) => prev.filter((id) => id !== attr.attribute.id));
	};

	useEffect(() => {
		setRequiredAttributes(
			attributes
				.map((attr) => {
					if (
						'required' in attr.attribute.restrictions &&
						attr.attribute.restrictions.required
					) {
						return attr.attribute.id;
					}
				})
				.filter((item) => !!item)
		);
		setUniqueAttributes(
			attributes
				.map((attr) => {
					if (
						'unique' in attr.attribute.restrictions &&
						attr.attribute.restrictions.unique
					) {
						return attr.attribute.id;
					}
				})
				.filter((item) => !!item)
		);
	}, [attributes]);

	useEffect(() => {
		if (catalogRestrictionTablesDeclarations?.length > 0) {
			getConstraintTables(
				catalogRestrictionTablesDeclarations.map((item) => item.restrictionTable.id)
			).then((res) => {
				if (Array.isArray(res)) setTables(res);
			});
		}
	}, [catalogRestrictionTablesDeclarations]);

	if (error || tablesErr)
		return <Result title="Ошибка!" status="error" subTitle={JSON.stringify(error)} />;

	return (
		<Flex vertical gap={12}>
			{(isLoading || loading || isAttributesLoading) && (
				<Flex vertical style={{ width: '100%' }} gap={12}>
					<Skeleton.Input active style={{ width: '100%' }} />
					<Skeleton.Input active style={{ width: '100%' }} />
					<Skeleton.Input active style={{ width: '100%' }} />
					<Skeleton.Input active style={{ width: '100%' }} />
				</Flex>
			)}
			{catalogRestrictionTablesDeclarations?.length === 0 && !isLoading && !loading && (
				<Placeholder title="Добавьте таблицу допустимых значений чтобы начать редактирование" />
			)}
			{!error &&
				!tablesErr &&
				!loading &&
				!isLoading &&
				catalogRestrictionTablesDeclarations &&
				catalogRestrictionTablesDeclarations.length > 0 && (
					<Collapse
						bordered={false}
						expandIcon={({ isActive }) =>
							isActive ? <RiArrowUpSLine /> : <RiArrowDownSLine />
						}
						items={restrictionTablesDtoToCollapseMapper(
							tables,
							catalogRestrictionTablesDeclarations
						).map((item) => ({
							label: item.displayName,
							key: item.id,
							style: panelStyle,
							extra: (
								<ItemActions
									items={[
										{
											key: '1',
											label: (
												<DeleteCatalogRestrictionTable
													declarationId={item.id}
												/>
											),
										},
									]}
								/>
							),
							children: (
								<Flex vertical gap={12} key={item.id}>
									<Form layout="vertical">
										<Form.Item label="Наименование">
											<Input
												value={item.displayName}
												readOnly
												style={{ cursor: 'default' }}
											/>
										</Form.Item>
										<Form.Item>
											<Checkbox
												defaultChecked={item.required}
												onChange={(e) =>
													handleChangeRequired(item.id, e.target.checked)
												}
											>
												Обязательность исполнения
											</Checkbox>
										</Form.Item>
									</Form>
								</Flex>
							),
						}))}
					/>
				)}
			{attributes && (
				<Flex vertical gap={32}>
					<Flex vertical gap={12}>
						<Typography.Title level={1}>
							Проверка на обязательность заполнения
						</Typography.Title>

						<Flex vertical gap={5}>
							<Typography.Text>Обязательные атрибуты</Typography.Text>
							<SelectModal
								selectValues={attributes
									?.map((attr) => {
										if (
											'required' in attr.attribute.restrictions &&
											attr.attribute.restrictions.required
										) {
											return {
												label: attr.attribute.displayName,
												callback: () =>
													onRemoveRequiredAttr(attr.attribute.id),
											};
										}
									})
									.filter((item) => !!item)}
								modalTitle="Добавление атрибута"
								onOkCallback={onRequireFinish}
							>
								<Flex vertical gap={12}>
									<Search onChange={searchHandler} defaultValue={searchValue} />
									<Tree
										treeData={attributes.map((item) => ({
											title: item.attribute.displayName,
											value: item.attribute.id,
											key: item.attribute.id,
											parentId: item.id,
										}))}
										checkable
										checkedKeys={requiredAttributes}
										onCheck={onRequiredCheck}
									/>
								</Flex>
							</SelectModal>
						</Flex>
					</Flex>

					<Flex vertical gap={12}>
						<Typography.Title level={1}>
							Проверка на уникальность значения
						</Typography.Title>

						<Flex vertical gap={5}>
							<Typography.Text>Уникальные атрибуты</Typography.Text>
							<SelectModal
								selectValues={attributes
									?.map((attr) => {
										if (
											'unique' in attr.attribute.restrictions &&
											attr.attribute.restrictions.unique
										) {
											return {
												label: attr.attribute.displayName,
												callback: () =>
													onRemoveUniqueAttr(attr.attribute.id),
											};
										}
									})
									.filter((item) => !!item)}
								modalTitle="Добавление атрибута"
								onOkCallback={onUniqueFinish}
							>
								<Flex vertical gap={12}>
									<Search onChange={searchHandler} defaultValue={searchValue} />
									<Tree
										treeData={attributes.map((item) => ({
											title: item.attribute.displayName,
											value: item.attribute.id,
											key: item.attribute.id,
											parentId: item.id,
										}))}
										checkable
										defaultCheckedKeys={uniqueAttributes}
										onCheck={onUniqueCheck}
									/>
								</Flex>
							</SelectModal>
						</Flex>
					</Flex>
				</Flex>
			)}
		</Flex>
	);
};

export const EditCatalogRestrictionsTables = EditCatalogRestrictionsTablesUi;
