import { RiDeleteBin2Line } from '@remixicon/react';
import { App, Button, Checkbox, Flex, Form, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { TaskType, useAsyncOperation } from 'entities/events';
import { useDeleteAttributeGroupAsyncMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { DropdownLink } from 'shared/ui';

interface IDeleteAttributeGroupProps {
	groupId: string;
}

const DeleteAttributeGroupUi: React.FC<IDeleteAttributeGroupProps> = ({ groupId }) => {
	const { notification } = App.useApp();

	const [ form ] = Form.useForm();

	const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
	const [ isChecked, setIsChecked ] = useState<boolean>(false);
	const [ loading, setLoading ] = useState<boolean>(false);

	const { execute } = useAsyncOperation(
		useDeleteAttributeGroupAsyncMutation,
		TaskType.DELETE_ATTRIBUTE_GROUPS_BACKGROUND_TASK,
	);

	const onFinish = async () => {
		try {
			setLoading(true);
			await execute({
				ids: [ groupId ],
			});
			setLoading(false);
			setIsChecked(false);
			setIsModalOpen(false);
		} catch (error) {
			errorHelper('Ошибка при удалении группы атрибутов!', error, notification);
		}
	};

	const showModal = () => setIsModalOpen(true);
	const handleOk = () => setIsModalOpen(false);
	const handleCancel = () => setIsModalOpen(false);
	const toggleCheck = () => setIsChecked(!isChecked);

	return (
		<>
			<DropdownLink
				icon={<RiDeleteBin2Line size={16}/>}
				title="Удалить"
				callback={showModal}
			/>

			<Modal
				title="Удаление группы атрибутов"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item>
						<Typography.Text>
							Вы действительно хотите удалить данную группу атрибутов? Группа
							атрибутов и входящие в нее атрибуты будут удалены без возможности
							восстановления
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Checkbox checked={isChecked} onChange={toggleCheck}>
							Я согласен удалить группу атрибутов и входящие в нее атрибуты
						</Checkbox>
					</Form.Item>
					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={handleCancel}>
							Отменить
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!isChecked}
						>
							Удалить
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};

export const DeleteAttributeGroup = React.memo(DeleteAttributeGroupUi);
