import { App, Checkbox, Col, Flex, Form, Input, Result, Spin, Typography } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetCurrentMeausureGroup, useGetMeasuresGroup } from 'entities/metadata/measures';
import {
	MeasurementResponse,
	useGetCatalogsByAttributeQuery,
	useLazyGetMeasurementsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { CellInputParser } from 'shared/helpers/CellInputParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { NEW_ENTITY } from 'shared/helpers/Constants';
import { ItemValuesType } from 'shared/helpers/types';
import { useTypedTranslation } from 'shared/hooks';
import { DetailFooter, DropdownSelect } from 'shared/ui';
import { DropdownTreeSelect } from 'shared/ui/components/AppTreeSelect';
import { DeleteSimpleAttributeUi } from '../deleteSimpleAttribute';
import { UsageAttributeInCatalogs } from '../UsageAttributeInCatalogs';
import { useEditSimpleAttribute } from './editSimpleAttribute.model';

const selectOptions = [
	{ label: 'Строка', value: 'STRING' },
	{ label: 'Число с плавающей точкой', value: 'FLOAT' },
	{ label: 'Целое число', value: 'INTEGER' },
	{ label: 'Логический тип', value: 'BOOLEAN' },
	{ label: 'Многострочный текст', value: 'TEXT' },
	{ label: 'Дата', value: 'DATE_TIME' },
	{ label: 'Цвет', value: 'COLOR' },
	{ label: 'Формула', value: 'FORMULA' },
	{ label: 'Связь', value: 'RELATION' },
	{ label: 'Файл', value: 'FILE' },
];

const EditSimpleAttributeUi = () => {
	const navigate = useNavigate();

	const { metaAttributeId, metaAttributeGroupId } = useParams();

	const [form] = Form.useForm();

	const { formVals, isLoading, getAttributeError, handleUpdateAttribute } =
		useEditSimpleAttribute();

	const [measurements, setMeasuerments] = useState<MeasurementResponse[]>([]);
	const { notification } = App.useApp();
	const typeValue = Form.useWatch('type', form);
	const initialValuesRef = useRef(null);
	const { t } = useTypedTranslation();

	const { measuresList, loading, error } = useGetMeasuresGroup();
	const {
		currentMeasureGroup,
		getData,
		loading: measuresLoading,
		error: measuresError,
	} = useGetCurrentMeausureGroup();
	const [fetchMeasurements] = useLazyGetMeasurementsQuery();

	const { data: usageList } = useGetCatalogsByAttributeQuery({
		attributeId: metaAttributeId,
	});

	const handleBlur = async () => {
		const rawValues = form.getFieldsValue([
			'displayName',
			'description',
			'type',
			'list',
			'associatedAttributeIds',
			'measurementGroupId',
		]);

		let restrictions = {};

		if (typeValue === 'STRING') {
			restrictions = form.getFieldsValue(['mask', 'defaultValue', 'formulaId', 'maxLength']);
		} else if (typeValue === 'FLOAT') {
			restrictions = form.getFieldsValue([
				'accuracy',
				'measurementId',
				'defaultValue',
				'formulaId',
			]);
		} else if (typeValue === 'INTEGER') {
			restrictions = form.getFieldsValue(['defaultValue', 'formulaId']);
		} else if (['TEXT', 'DATE_TIME', 'COLOR'].includes(typeValue)) {
			restrictions = form.getFieldsValue(['formulaId', 'defaultValue']);
		} else if (typeValue === 'FORMULA') {
			restrictions = form.getFieldsValue(['formulaId']);
		} else if (typeValue === 'RELATION') {
			restrictions = form.getFieldsValue(['scopeId', 'defaultValue', 'formulaId']);
		}

		const combinedRawValues = {
			...rawValues,
			restrictions: {
				...restrictions,
			},
		};

		if (typeValue === CellTypesEnum.FLOAT) {
			if (!combinedRawValues?.measurementGroupId) {
				combinedRawValues.measurementGroupId = null;
				combinedRawValues.restrictions = {
					...combinedRawValues.restrictions,
					measurementId: null,
				};
			}

			if (
				combinedRawValues?.measurementGroupId &&
				combinedRawValues.restrictions.measurementId === undefined
			) {
				combinedRawValues.restrictions = {
					...combinedRawValues.restrictions,
					measurementId: null,
				};
			}
		}

		if (JSON.stringify(combinedRawValues) === JSON.stringify(initialValuesRef.current)) {
			return;
		}

		await handleUpdateAttribute(combinedRawValues);

		initialValuesRef.current = combinedRawValues;
	};

	const handleMeasurementGroup = async (e: string) => {
		if (!e) {
			form.setFieldValue('measurementId', '');
		} else {
			await fetchMeasurements({ measurementGroupId: e })
				.unwrap()
				.then((res) => {
					const initUnit =
						res?.find((x) => x.isDefault) || res !== undefined ? res[0] : '';
					form.setFieldValue('measurementId', initUnit ? initUnit.id : '');
				})
				.catch((err) => {
					errorHelper(
						t((l) => l.measures.error.gettingMeasurmentUnits),
						err,
						notification
					);
				});
		}

		handleBlur();
	};

	const handleEditComplexDefaultValue = (_, value: ItemValuesType) => {
		form.setFieldValue('defaultValue', value);
		handleBlur();
	};

	const handleBackClick = () => {
		navigate(`/${routes.metadata.main}/${routes.metadata.group}/${metaAttributeGroupId}`);
	};

	useEffect(() => {
		if (formVals) {
			form.setFieldsValue(formVals);
			form.setFieldValue(
				'associatedAttributeIds',
				formVals?.associatedAttributeIds?.map((item) => ({ label: item, value: item }))
			);
			if ('restrictions' in formVals) {
				form.setFieldsValue(formVals.restrictions);
			}
			if (
				formVals.restrictions &&
				'measurementId' in formVals.restrictions &&
				formVals.restrictions.measurementId
			) {
				if (form.getFieldValue('measurementGroupId')) {
					getData(form.getFieldValue('measurementGroupId')).then((res) => {
						setMeasuerments(res?.childrenMeasures as MeasurementResponse[]);

						const data = res?.childrenMeasures?.find(
							(item) =>
								typeof formVals?.restrictions?.measurementId === 'string' &&
								item.id === formVals.restrictions.measurementId
						);
						if (data) {
							form.setFieldValue('mesurmentId', {
								value: data.id,
							});
						} else {
							form.setFieldValue('mesurmentId', null);
						}
					});
				}
			}
			if (typeof formVals.description === 'object') {
				const v = (formVals.description as { value: string })?.value;
				form.setFieldValue('description', v);
			}

			if (typeValue === CellTypesEnum.FLOAT && form.getFieldValue('measurementGroupId')) {
				getData(form.getFieldValue('measurementGroupId'));
			}
			initialValuesRef.current = form.getFieldsValue();
		}
	}, [formVals]);

	const DefaultValueFormItem = (
		<Form.Item label={t((l) => l.common.defaultNames.defaultValue)}>
			{CellInputParser(
				metaAttributeId,
				form.getFieldValue('defaultValue'),
				typeValue,
				handleEditComplexDefaultValue
			)}
		</Form.Item>
	);

	if (isLoading) {
		return (
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<Spin size="large" />
			</div>
		);
	}

	if (getAttributeError) {
		return (
			<Result
				status="error"
				title={t((l) => l.attributes.receivingDataForEditingAttributeErr)}
				subTitle={JSON.stringify(getAttributeError)}
			/>
		);
	}

	return (
		<>
			<Col sm={24} md={24} lg={20} xl={14} xxl={14} style={{ marginBottom: 65 }}>
				<Flex vertical gap={12} style={{ width: '100%' }}>
					<Typography.Title level={2}>
						{t((l) => l.common.defaultNames.mainInfo)}
					</Typography.Title>
					<Form layout="vertical" form={form}>
						<Form.Item
							label={t((l) => l.common.defaultNames.name)}
							required
							name="displayName"
						>
							<Input onBlur={handleBlur} allowClear />
						</Form.Item>

						<Form.Item
							label={t((l) => l.common.defaultNames.description)}
							name="description"
						>
							<Input onBlur={handleBlur} allowClear />
						</Form.Item>

						<Form.Item name="list" valuePropName="checked">
							<Checkbox onChange={handleBlur}>
								{t((l) => l.common.defaultNames.isMulti)}
							</Checkbox>
						</Form.Item>

						<Form.Item
							label={t((l) => l.measures.group.groupTitle)}
							name="measurementGroupId"
						>
							{error ? (
								<Input
									value={t((l) => l.measures.error.gettingDataToSelectUnits)}
									disabled
								/>
							) : (
								<DropdownTreeSelect
									loading={loading}
									onChange={handleMeasurementGroup}
									treeData={measuresList?.map((item) => ({
										title: item.displayName,
										key: item.id,
										value: item.id,
									}))}
									value={formVals?.measurementGroupId}
									allowClear
								/>
							)}
						</Form.Item>

						<Form.Item
							label={t((l) => l.common.defaultNames.dataType)}
							required
							name="type"
						>
							<DropdownSelect
								options={selectOptions}
								allowClear
								onChange={handleBlur}
								disabled={usageList?.length > 0}
							/>
						</Form.Item>

						{typeValue === 'STRING' && (
							<>
								<Form.Item
									label={t((l) => l.common.defaultNames.inputMask)}
									name="mask"
								>
									<Input onBlur={handleBlur} allowClear />
								</Form.Item>
								{DefaultValueFormItem}
								{/* <Form.Item label={t(l=>l.common.defaultNames.formula)} name="formulaId">
									<Input onBlur={handleBlur} allowClear />
								</Form.Item> */}
								<Form.Item
									label={t((l) => l.common.defaultNames.maxLength)}
									name="maxLength"
								>
									<Input onBlur={handleBlur} allowClear />
								</Form.Item>
							</>
						)}
						{typeValue === 'FLOAT' && (
							<>
								<Form.Item
									label={t((l) => l.common.defaultNames.accuracy)}
									name="accuracy"
								>
									<Input onBlur={handleBlur} allowClear />
								</Form.Item>
								<Form.Item
									label={t((l) => l.measures.measurementUnit)}
									name="measurementId"
								>
									{measuresError && currentMeasureGroup ? (
										<Input
											value={t(
												(l) => l.measures.error.gettingDataToSelectUnits
											)}
											disabled
										/>
									) : (
										<DropdownSelect
											loading={measuresLoading}
											onChange={handleBlur}
											options={measurements?.map((item) => ({
												label: item.displayName,
												key: item.id,
												value: item.id,
											}))}
											value={formVals?.restrictions?.measurementId}
											disabled={!measurements?.length}
											allowClear
										/>
									)}
								</Form.Item>
								{DefaultValueFormItem}
								{/* <Form.Item label={t(l=>l.common.defaultNames.formula)} name="formulaId">
									<Input onBlur={handleBlur} allowClear />
								</Form.Item> */}
							</>
						)}
						{typeValue === 'INTEGER' && (
							<>
								{DefaultValueFormItem}
								{/* <Form.Item label={t(l=>l.common.defaultNames.formula)} name="formulaId">
									<Input onBlur={handleBlur} allowClear />
								</Form.Item> */}
							</>
						)}
						{(typeValue === 'TEXT' ||
							typeValue === 'DATE_TIME' ||
							typeValue === 'COLOR') && (
							<>
								{DefaultValueFormItem}
								{/* <Form.Item label={t(l=>l.common.defaultNames.formula)} name="formulaId">
									<Input onBlur={handleBlur} allowClear />
								</Form.Item> */}
							</>
						)}
						{/* {typeValue === 'FORMULA' && (
							<>
								<Form.Item label={t(l=>l.common.defaultNames.formula)} name="formulaId">
									<Input onBlur={handleBlur} allowClear />
								</Form.Item>
							</>
						)} */}
						{/* {typeValue === 'RELATION' && (
							<>
								<Form.Item label={t(l=>l.common.defaultNames.formula)} name="formulaId">
									<Input onBlur={handleBlur} allowClear />
								</Form.Item>
							</>
						)} */}
					</Form>

					{metaAttributeId !== NEW_ENTITY && (
						<UsageAttributeInCatalogs attributeId={metaAttributeId} />
					)}
				</Flex>
			</Col>

			<DetailFooter customHandleBack={handleBackClick}>
				{metaAttributeId !== NEW_ENTITY && (
					<DeleteSimpleAttributeUi ids={[metaAttributeId]} isButton={true} />
				)}
			</DetailFooter>
		</>
	);
};

export const EditSimpleAttribute = React.memo(EditSimpleAttributeUi);
