import { Badge, Flex } from 'antd';
import React from 'react';
import { ItemValuesType } from './types';

export enum CellTypesEnum {
	STRING = 'STRING',
	DOUBLE = 'DOUBLE',
	FLOAT = 'FLOAT',
	INT = 'INTEGER',
	BOOL = 'BOOL',
	BOOLEAN = 'BOOLEAN',
	TEXT = 'TEXT',
	DATETIME = 'DATE_TIME',
	COLOUR = 'COLOR',
	FILE = 'FILE',
	FORMULA = 'FORMULA',
	RELATION = 'RELATION',
}

export interface IFormula {
	rule: string;
	wizardEntries: { [key: string]: unknown }[];
	formulaTests: IFormulaTests;
}

export interface IFormulaTests {
	testItems: number[];
	preferredNodeIds: number[];
	rightItems: number[];
	selectedPairs: ILongPair[];
}

export interface ILongPair {
	first: number;
	second: number;
}

export const CellValueParser = (
	cell: ItemValuesType,
	type: CellTypesEnum,
	prevValue: string | React.ReactNode = ''
) => {
	// Нельзя проверять !cell, потому что cell может быть false для логического типа данных
	if (cell === undefined || cell === null) return '';
	switch (type) {
		case CellTypesEnum.STRING:
		case CellTypesEnum.TEXT:
			return `${prevValue ? prevValue + ' | ' : ''}${cell}`;

		case CellTypesEnum.INT:
			return `${prevValue ? prevValue + ' | ' : ''}${String(cell)}`;

		case CellTypesEnum.DOUBLE:
			return `${prevValue ? prevValue + ' | ' : ''}${cell}`;

		case CellTypesEnum.BOOL:
		case CellTypesEnum.BOOLEAN:
			return cell ? 'Да' : 'Нет';

		case CellTypesEnum.DATETIME:
			return `${prevValue ? prevValue + ' | ' : ''}${new Date(cell as string).toLocaleDateString('ru-RU')}`;

		case CellTypesEnum.COLOUR:
			return (
				<Flex gap={8}>
					{prevValue && <>{prevValue} |</>}
					<Badge
						styles={{ indicator: { width: 15, height: 15 } }}
						color={cell as string}
					/>
					{cell}
				</Flex>
			);

		case CellTypesEnum.FILE:
			return `${prevValue ? prevValue + ' | ' : ''}${cell}`;

		case CellTypesEnum.FORMULA:
			return `${prevValue ? prevValue + ' | ' : ''}${cell}`;

		default:
			return `${cell}`;
	}
};

export enum AttributeRestrictionEnum {
	mask = 'Маска ввода',
	defaultValue = 'Значение по умолчанию',
	accuracy = 'Точность',
	required = 'Обязательное',
	formulaId = 'Формула',
	scopeId = 'Область',
	measurementId = 'Единица измерения',
	maxLength = 'Максимальная длина',
	unique = 'Уникальное',
	removalChildren = 'Удалять потомков при удалении родителя',
}
