import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
	CatalogAttributeDeclarationResponse,
	useLazyGetCatalogAttributeDeclarationsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector, useTypedTranslation } from 'shared/hooks';
import {
	removeAttributeDeclarationList,
	setAttributeDeclarationsList,
} from './attributeDeclarations.store';

export const useAttributesDeclarations = () => {
	const { t } = useTypedTranslation();

	const [error, setError] = useState<FetchBaseQueryError | SerializedError>(null);

	const [attributes, setAttributes] = useState<Array<CatalogAttributeDeclarationResponse>>([]);

	const dispatch = useAppDispatch();

	const { attributeDeclarationsList } = useAppSelector(
		(state) => state.entities.catalogs.attributeDeclarationsList
	);

	const { transactionRollback } = useAppSelector((state) => state.entities.transactions);

	const [getDeclarations, { isLoading, error: getDeclarationsError }] =
		useLazyGetCatalogAttributeDeclarationsQuery();

	const { catalogGroupId } = useParams();

	const { notification } = App.useApp();

	const getAttributes = async (id?: string): Promise<CatalogAttributeDeclarationResponse[]> => {
		if (!id && !catalogGroupId) return;

		const list = await getDeclarations({
			catalogId: id ? id : catalogGroupId,
		});
		if ('error' in list) {
			errorHelper(
				t((l) => l.catalogs.attributesDeclarations.attributesDeclarationsErr),
				list.error,
				notification
			);
			setError(list.error);
		}
		if ('data' in list && list.data) {
			return list.data;
		}
	};

	const getCurrentDeclarations = () => {
		getAttributes()
			.then((res) => {
				dispatch(setAttributeDeclarationsList(res));
				setAttributes(res);
			})
			.catch((e) =>
				errorHelper(
					t((l) => l.catalogs.attributesDeclarations.attributesDeclarationsErr),
					e,
					notification
				)
			);
	};

	useEffect(() => {
		if (attributeDeclarationsList) setAttributes(attributeDeclarationsList);
		if (
			!attributeDeclarationsList ||
			(attributeDeclarationsList.length > 0 &&
				attributeDeclarationsList[0].catalogId !== catalogGroupId)
		) {
			getCurrentDeclarations();
		}

		return () => {
			setAttributes([]);
			dispatch(removeAttributeDeclarationList());
		};
	}, []);

	useEffect(() => {
		getCurrentDeclarations();
	}, [transactionRollback]);

	useEffect(() => {
		if (attributeDeclarationsList) {
			setAttributes(attributeDeclarationsList);
		}
	}, [attributeDeclarationsList]);

	useEffect(() => {
		if (!getDeclarationsError) setError(null);
	}, [getDeclarationsError]);

	useEffect(() => {
		dispatch(removeAttributeDeclarationList());
	}, [catalogGroupId]);

	return {
		attributes,
		getAttributes,
		loading: isLoading,
		error,
	};
};
