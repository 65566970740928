import { Spin } from 'antd';
import keycloak, { initializeKeycloak } from 'keycloak';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { providerStyles } from '../styles/providerStyles';

const clearIssQueryParam = () => {
	const url = new URL(window.location.href);
	if (url.searchParams.has('iss')) {
		url.searchParams.delete('iss');
		window.history.replaceState(null, null, url.toString());
	}
};

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
	const [authenticated, setAuthenticated] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		let refreshTimeout: any | undefined;

		initializeKeycloak()
			.then((authenticated) => {
				setAuthenticated(authenticated);
				setLoading(false);
				if (!authenticated) {
					window.location.reload();
				}

				if (authenticated) {
					clearIssQueryParam();

					refreshTimeout = setInterval(() => {
						keycloak
							.updateToken(30)
							.then((refreshed) => {
								if (refreshed) {
									console.debug('Token was successfully refreshed');
								}
							})
							.catch(() => {
								console.error('Failed to refresh token');
							});
					}, 10000);
				}
			})
			.catch(() => {
				setLoading(false);
			});

		return () => {
			refreshTimeout && clearInterval(refreshTimeout);
		};
	}, []);

	if (loading) {
		return <Spin style={providerStyles} size="large" />;
	}

	return authenticated ? <>{children}</> : <div>Not authenticated</div>;
};
