import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { DisplayTreeNode } from 'entities/catalogs/catalogGroups/catalog.model';
import { TaskType, useAsyncOperation } from 'entities/events';
import {
	useMetaAttributesGroups,
	AttributeGroupItem,
} from 'entities/metadata/attributesGroups/attributesGroups.model';
import {
	AttributeGroupResponse,
	AttributeGroupTreeNodeResponse,
	useCreateClassifierAttributeDeclarationsAsyncMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';

export const useAddAttributeTree = () => {
	const {
		attrList,
		currAttrGroup,
		getAttributes,
		loading,
		attributesGroupsError,
		currentAttrubuteGroupError,
	} = useMetaAttributesGroups();
	const [ tree, setTree ] = useState<Array<DisplayTreeNode>>([]);
	const [ selectedTreeItem, setSelectedTreeItem ] = useState<DisplayTreeNode | null>(null);

	const { classifierGroupId } = useParams();

	const { execute: addAttributeDeclarationAsync } = useAsyncOperation(
		useCreateClassifierAttributeDeclarationsAsyncMutation,
		TaskType.CREATE_CLASSIFIER_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK,
	);

	const formatToTree = (data: Array<AttributeGroupResponse | AttributeGroupTreeNodeResponse>) =>
		data?.map((attributeGroup) => ({
			...attributeGroup,
			key: attributeGroup.id,
			value: attributeGroup.id,
			label: attributeGroup.displayName,
			isLeaf: !attributeGroup.parent,
			...(attributeGroup?.parent &&
				'children' in attributeGroup &&
				attributeGroup?.children?.length && {
					children: formatToTree(attributeGroup.children),
				}),
		}));

	const findAttrGroupById = (
		tree: DisplayTreeNode[],
		attributeGroupGroupId: string,
	): DisplayTreeNode | null => {
		for (const item of tree) {
			if (item.id === attributeGroupGroupId) {
				return item;
			}
			if (item.children) {
				const found = findAttrGroupById(item.children, attributeGroupGroupId);
				if (found) {
					return found;
				}
			}
		}
		return null;
	};

	const selectCurrentAttributeGroup = (attributeGroup: DisplayTreeNode | null) => {
		if (attributeGroup) {
			const item = findAttrGroupById(tree, attributeGroup.id);
			setSelectedTreeItem(item);
		} else {
			setSelectedTreeItem(null);
		}
	};

	const addMenuToTreeNodeItems = (arr: Array<AttributeGroupItem>) => {
		return arr.map((item) => ({
			...item,
			title: item.displayName,
			...(item.children && { children: addMenuToTreeNodeItems(item.children) }),
		}));
	};

	const updateTreeData = (list, id, children) =>
		list.map((node) => {
			if (node.id === id) {
				return {
					...node,
					children,
				};
			}
			if ('children' in node && node.children) {
				return {
					...node,
					children: updateTreeData(node.children, id, children),
				};
			}
			return node;
		});

	const onExpandTree = async (parentId: string) => {
		const data = await getAttributes(parentId);

		if (data) {
			const newData = updateTreeData(tree, parentId, formatToTree([ currAttrGroup ])[0]);
			setTree(addMenuToTreeNodeItems(newData));
		}
	};

	const addClassifierAttributeDeclaration = async (arr) => {
		try {
			await addAttributeDeclarationAsync({
				classifierId: classifierGroupId,
				body: arr.map((item) => ({
					isCopy: false,
					attributeId: item.id,
				})),
			});

			return true;
		} catch (error) {
			errorHelper('Ошибка при добавлении атрибута в классификатор!', error, notification);
			return false;
		}
	};

	useEffect(() => {
		if (attrList.length) {
			const formattedTree = formatToTree(attrList);
			setTree(addMenuToTreeNodeItems(formattedTree));
		}
	}, [ attrList, currAttrGroup ]);

	return {
		tree,
		selectedTreeItem,
		selectCurrentAttributeGroup,
		onExpandTree,
		updateCatalogAttributes: addClassifierAttributeDeclaration,
		loading,
		error: attributesGroupsError || currentAttrubuteGroupError,
	};
};
