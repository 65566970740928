import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import type { MutationDefinition } from '@reduxjs/toolkit/query';
import { useEffect, useRef } from 'react';
import { BackgroundTaskStartDto } from 'shared/api/generatedApi/mdmgApi';
import { useAppDispatch } from 'shared/hooks/reduxHooks';
import { addBackgroundTask, completeBackgroundTask } from 'shared/ui';
import { TaskType } from './taskType';
import { RealtimeBaseEvent } from './types';
import { useRealtimeEvent } from './useRealtimeEvent';

interface AsyncOperationsDetails {
	id: any;
	correlationId: string;
	resolve: (value?: unknown) => void;
	reject: (reason?: any) => void;
}

const useAsyncOperation = <
	R extends MutationDefinition<any, any, any, ResultType>,
	ResultType extends BackgroundTaskStartDto
>(
	useAsyncMutation: UseMutation<R>,
	taskType: TaskType,
) => {
	const dispatch = useAppDispatch();

	const [ asyncMutation ] = useAsyncMutation();
	const promiseResolverMapRef = useRef<AsyncOperationsDetails[]>([]);

	useEffect(() => {
		return () => {
			promiseResolverMapRef.current.forEach(({ id, reject }) => {
				dispatch(completeBackgroundTask(id));
				reject();
			});
		};
	}, []);

	useRealtimeEvent(TaskType.PUSH_NOTIFICATION, (e: RealtimeBaseEvent) => {
		promiseResolverMapRef.current = promiseResolverMapRef.current
			.filter(({ id, correlationId, reject }) => {
				if (e.backgroundTaskId != correlationId) {
					return true;
				}
				dispatch(completeBackgroundTask(id));
				reject(e.payload);
				return false;
			});
	});

	useRealtimeEvent(taskType, (e: RealtimeBaseEvent) => {
		promiseResolverMapRef.current = promiseResolverMapRef.current
			.filter(({ id, correlationId, resolve }) => {
				if (e.backgroundTaskId != correlationId) {
					return true;
				}
				dispatch(completeBackgroundTask(id));
				resolve();
				return false;
			});
	});

	const execute = async (params: Parameters<typeof asyncMutation>[0]) => {
		const id = Math.random().toString(36);
		dispatch(addBackgroundTask(id));
		const res = await asyncMutation(params)
			.unwrap();
		const correlationId = (res as BackgroundTaskStartDto).id;
		return new Promise((resolve, reject) => {
			promiseResolverMapRef.current
				.push({
					id,
					correlationId,
					resolve,
					reject,
				});
		});
	};

	return {
		execute,
	};
};

export {
	useAsyncOperation,
};