import { Flex, Spin, Switch, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useGetCatalogQuery } from 'shared/api/generatedApi/mdmgApi';

export const ToggleSubordinatedCatalogsRecords = ({ catalogId, onChange }: any) => {
	const { pathname } = useLocation();

	const { data: currentCatalog, isFetching: loading } = useGetCatalogQuery({
		id: catalogId,
	});

	const disabled = !currentCatalog?.parent;

	const [ isSubordinatedCatalogsRecordsShown, setIsSubordinatedCatalogsRecordsShown ] =
		useState<boolean>(
			JSON.parse(localStorage.getItem(pathname))
				?.isSubordinatedCatalogsRecordsShown,
		);

	useEffect(() => {
		setIsSubordinatedCatalogsRecordsShown(
			JSON.parse(localStorage.getItem(pathname))?.isSubordinatedCatalogsRecordsShown,
		);
	}, [ pathname ]);

	useEffect(() => {
		onChange(isSubordinatedCatalogsRecordsShown);

		localStorage.setItem(
			pathname,
			JSON.stringify({
				...JSON.parse(localStorage.getItem(pathname)),
				isSubordinatedCatalogsRecordsShown: isSubordinatedCatalogsRecordsShown,
			}),
		);
	}, [ isSubordinatedCatalogsRecordsShown ]);

	const toggleSubordinatedCatalogsRecords = () => {
		setIsSubordinatedCatalogsRecordsShown(prev => !prev);
	};

	return (
		<Spin spinning={loading}>
			<Flex gap={4}>
				<Switch
					size="small"
					checked={isSubordinatedCatalogsRecordsShown}
					disabled={disabled}
					onChange={toggleSubordinatedCatalogsRecords}
				/>
				<Typography.Text disabled={disabled}>
					Отображать позиции подчиненных групп
				</Typography.Text>

			</Flex>
		</Spin>
	);
};
