import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassifierAttributeDeclarationResponse } from 'shared/api/generatedApi/mdmgApi';

interface IAttributesState {
	classifierAttributeDeclarations: Array<ClassifierAttributeDeclarationResponse>;
}

const initialState: IAttributesState = {
	classifierAttributeDeclarations: null,
};

const classifierAttributesSlice = createSlice({
	name: 'attributes',
	initialState,
	reducers: {
		setClassifierAttributeDeclarationsList(
			state,
			{ payload }: PayloadAction<Array<ClassifierAttributeDeclarationResponse>>
		) {
			state.classifierAttributeDeclarations = payload;
		},
		removeClassifierAttributeDeclarationList(state) {
			state.classifierAttributeDeclarations = null;
		},
	},
});

export const {
	setClassifierAttributeDeclarationsList,
	removeClassifierAttributeDeclarationList,
} = classifierAttributesSlice.actions;

export const classifierAttributesDeclarationsListReducer = classifierAttributesSlice.reducer;
