import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeasurementGroupResponse, MeasurementResponse } from 'shared/api/generatedApi/mdmgApi';

export type Measure = MeasurementGroupResponse & {
	childrenMeasures?: Array<MeasurementResponse>;
};

interface IMeasures {
	measuresList: Array<Measure>;
	currentMeasureGroup: Measure;
	currentMeasureUnit: MeasurementResponse;
}

const initialState: IMeasures = {
	measuresList: null,
	currentMeasureGroup: null,
	currentMeasureUnit: null,
};

const measuresSlice = createSlice({
	name: 'measures',
	initialState,
	reducers: {
		setMeasuresList(state, { payload }: PayloadAction<Array<Measure>>) {
			state.measuresList = payload;
		},
		editMeasuresList(state, { payload }: PayloadAction<Measure>) {
			state.measuresList = state.measuresList?.map((item) => {
				if (item.id === payload.id) {
					return payload;
				}
				return item;
			});
		},
		addMeasuresListItem(state, { payload }: PayloadAction<Measure>) {
			state.measuresList = [...state.measuresList, payload];
		},
		removeMeasuresItem(state, { payload }: PayloadAction<Measure>) {
			state.measuresList = state.measuresList.filter((measure) => measure.id !== payload.id);
		},
		setCurrentMeasureGroup(state, { payload }: PayloadAction<Measure>) {
			state.currentMeasureGroup = payload;
		},
		editCurrentMeasureGroup(state, { payload }: PayloadAction<Measure>) {
			if (state.measuresList)
				state.measuresList = state.measuresList.map((measure) => {
					if (measure.id === payload.id) {
						return payload;
					} else {
						return measure;
					}
				});
			state.currentMeasureGroup = payload;
		},
		removeCurrentMeasureGroup(state) {
			state.currentMeasureGroup = null;
		},
		setCurrentMeasureUnit(state, { payload }: PayloadAction<MeasurementResponse>) {
			state.currentMeasureUnit = { ...payload };
		},
	},
});

export const measuresReducer = measuresSlice.reducer;
export const {
	setMeasuresList,
	editMeasuresList,
	addMeasuresListItem,
	removeMeasuresItem,
	setCurrentMeasureGroup,
	editCurrentMeasureGroup,
	removeCurrentMeasureGroup,
	setCurrentMeasureUnit,
} = measuresSlice.actions;
