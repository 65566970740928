import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ClassifierWithChildren = {
	id: string;
	displayName?: string;
	parent: boolean;
	children?: ClassifierWithChildren[];
};

interface IClassifiersTreeState {
	classifiersItemsTree: Array<ClassifierWithChildren>;
	currentClassifierTreeItem: ClassifierWithChildren;
}

const initialState: IClassifiersTreeState = {
	classifiersItemsTree: null,
	currentClassifierTreeItem: null,
};

const deleteCatalog = (
	tree: ClassifierWithChildren[],
	id: string,
): ClassifierWithChildren[] => {
	const data = tree.filter((item) => {
		if (item.id !== id && item.children?.length) {
			return deleteCatalog(item.children, id);
		}
		return item.id !== id;
	});

	return data.map((item) => {
		if (item.children) {
			return {
				...item,
				children: deleteCatalog(item.children, id),
			};
		}
		return item;
	});
};

const setChildren = (
	items: ClassifierWithChildren[],
	parentItemId: string,
	children: ClassifierWithChildren[],
) => {
	if (!items?.length) {
		return [];
	}

	return items.reduce((acc, item) => {
		if (item.id === parentItemId) {
			acc.push({
				...item,
				children: children,
			});
		} else {
			acc.push({
				...item,
				children: setChildren(item.children, parentItemId, children),
			});
		}
		return acc;
	}, []);
};

const classifiersItemsTreeSlice = createSlice({
	name: 'classifiersItemsTree',
	initialState,
	reducers: {
		setClassifiersItemsTree(state, action: PayloadAction<ClassifierWithChildren[]>) {
			state.classifiersItemsTree = action.payload;
		},
		setCurrentClassifierTreeItem(state, action: PayloadAction<ClassifierWithChildren>) {
			state.currentClassifierTreeItem = action.payload;
		},
		addNewClassifierTreeItem(state, action: PayloadAction<ClassifierWithChildren>) {
			state.classifiersItemsTree = [
				...(state.classifiersItemsTree && state.classifiersItemsTree),
				action.payload,
			];
		},
		setChildrenForItem(state, { payload }: PayloadAction<{
			parentItemId: string;
			children: ClassifierWithChildren[]
		}>) {
			const { parentItemId, children } = payload;

			state.classifiersItemsTree = setChildren(state.classifiersItemsTree, parentItemId, children);
		},
		removeCurrentClassifierTreeItem(state) {
			state.classifiersItemsTree = null;
		},
		deleteClassifierTreeItemById(state, { payload }: PayloadAction<string>) {
			state.classifiersItemsTree = deleteCatalog(state.classifiersItemsTree, payload);
		},
	},
});

export const {
	setClassifiersItemsTree,
	setCurrentClassifierTreeItem,
	addNewClassifierTreeItem,
	removeCurrentClassifierTreeItem,
	deleteClassifierTreeItemById,
	setChildrenForItem,
} = classifiersItemsTreeSlice.actions;

export const classifiersItemsTreeReducer = classifiersItemsTreeSlice.reducer;
