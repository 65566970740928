import { Localization } from './types';

const localization: Localization = {
	common: {
		buttons: {
			confirm: 'Confirm',
			cancel: 'Cancel',
			back: 'Back',
			delete: 'Delete',
			edit: 'Edit',
			columnsSetup: 'Column Setup',
			create: 'Create',
			selectAll: 'Select All',
			save: 'Save',
		},
		statuses: {
			normalized: 'Normalized',
			nonNormalized: 'Non-Normalized',
			blocked: 'Blocked',
			deleted: 'Deleted',
			dupe: 'Duplicate',
			error: 'Error',
		},
		defaultNames: {
			emptyName: '<Name not filled in>',
			status: 'Status',
			name: 'Name',
			type: 'Type',
			isMulti: 'Multiple',
			description: 'Description',
			dataType: 'Data type',
			mainInfo: 'General Information',
			defaultValue: 'Default value',
			inputMask: 'Input mask',
			formula: 'Formula',
			maxLength: 'Maximum length',
			accuracy: 'Accuracy',
		},
		search: {
			nothingFound: 'Nothing found for your query',
			checkRequest: 'Check if your query is entered correctly or try modifying it',
		},
		inputs: {
			emptyValueLabel: 'Empty',
			trueValueLabel: 'True',
			falseValueLabel: 'False',
		},
		tables: {
			total: 'Total',
		},
		error: {
			message: 'Error message',
			emptyRequiresFields: 'Required fields must be filled in',
		},
	},
	catalogs: {
		mainInfo: 'General Information',
		catalogs: 'Catalogs',
		catalog: 'Catalog',
		catalogInfo: 'Catalog Details',
		attributesDeclarations: {
			attributesDeclarationsErr: 'Error fetching the list of declarations!',
			addAttribute: 'Add Attribute',
			addAttributeDeclModal: 'To add an attribute, you need to select it from the list',
			mappingCreated: 'Mapping created',
			mappingUpdated: 'Mapping updated',
			mappingDeleted: 'Mapping deleted',
		},
		deduplication: {
			main: 'Position Deduplication',
			dupesGroupsErr: 'Error getting duplicate groups',
			dupesSearchResult: 'Duplicate Search Results',
			deleteDupesForSelectedItems: 'Delete duplicates for selected positions',
			makeDupesForSelectedItems: 'Mark selected positions as "Duplicate"',
			searchSaved: 'Search variant saved',
			searchCompleted: 'Search variant completed',
			updateSearchErr: 'Error updating duplicate search variant',
			importSearch: 'Search during import',
			addCatalog: 'Add Catalog',
			attributesList: 'Attributes used for duplicate search',
			addAttribute: 'Attributes used for duplicate search',
			selectAttribute: 'To add an attribute, you need to select it from the list',
			startDupeSearch: 'Start search',
			dupeSearchResult: 'View duplicate search results',
			variantN: 'Variant №',
			relaunchSearch: 'Restart search',
			addNewVariant: 'Add new duplicate search variant',
			isOriginal: 'Is Original',
			setAsOriginal: 'Set as Original',
			position: 'Position',
			source: 'Source',
			unionAndDelete: 'Merge and delete duplicates',
			setStatus: 'Set status "Duplicate"',
			deleteDupes: 'Delete duplicates for the position',
			deleteSearchOption: 'Delete',
		},
		groups: {
			noGroups: 'No reference groups',
			selectGroup: 'Select a reference group to display a list of records',
			currCatalogErr: 'Error fetching the current catalog',
			catalogErr: 'Error fetching catalog',
			rootGroupErr: 'Error fetching root reference group!',
			listErr: 'Error fetching root reference group!',
			noGroupErr: "You haven't added any reference groups yet",
			createNewGroup: 'To create a new group, click the "Add Reference Group" button',
			createNestedGroup: 'Add nested catalog',
			addNewCatalog: 'Add new catalog',
		},
		records: {
			recordsValues: 'Attribute Values',
			attributesSetup: 'Attribute Setup',
			editRecord: 'Edit Record',
			recordCard: 'Catalog Position Card',
			recordsRelationsErr: 'Error fetching records for "Relation" type attribute!',
			recordsListErr: 'Error fetching the list of records!',
			declarationsListErr: 'Error fetching declarations',
			noRecords: 'The catalog contains no positions',
			addRecordTooltip: 'To add a new position, click the "Add Reference Record" button',
			historyTabTitle: 'History',
		},
		export: {
			exportCatalogTitle: 'Export to Excel',
			exportCatalogTooltip:
				'To export catalog records to Excel, you need to select the columns to export',
			exportCatalogBtn: 'Export',
			fetchErr: 'Error fetching data for export',
		},
		restrictions: {
			restrictions: 'Restrictions',
			restrictionsTableErr: 'Error editing the table of allowed values',
			declarationsTableErr: 'Error editing the table of allowed values',
			addTable: 'Add allowed values table',
			noTableErr: "You haven't created any restriction tables yet",
		},
	},
	attributes: {
		attributeGroupErr: 'Error getting attribute group',
		attributeErr: 'Error getting attribute',
		gettingAttributeApplicabilityErr: 'Error loading attribute applicability in directories',
		receivingDataForEditingAttributeErr: 'Error when receiving data for editing the attribute!',
		applicabilityinCatalogs: 'Applicability in catalogs',
		unusedAttribute: 'The attribute is not used in the catalogs',
		applicabilityListError: 'Error when getting the list of applicability in the catalogs!',
	},
	measures: {
		mainInfo: 'General Information',
		measurementUnit: 'Measurement unit',
		group: {
			displayName: "Measurement units group's name",
			description: 'Description',
			groupTitle: 'Measurement units group',
			groupsTitle: 'Measurement units groups',
		},
		unit: {
			displayName: 'Name',
			shortName: 'Designation',
			basicUnit: 'The basic measurement unit',
			formula: 'Conversion formula',
			inverseFormula: 'Inverse conversion formula',
			coefficient: 'Conversion factor',
			noCoefficient: '<Not specified>',
			isBasic: {
				true: 'Yes',
				false: 'No',
			},
			cardTitle: 'Measurment unit card',
		},
		error: {
			gettingMeasurmentUnits: 'Measurement units receiving error!',
			editingMeasurmentUnit: 'Measurement unit editing error!',
			designatingSecondBaseUnit:
				'The choice of the second basic measurements unit is impossible',
			gettingDataToSelectUnits: 'Could not get the data to select the Units of Measurement!',
		},
		actions: {
			delete: {
				unit: {
					modalTitle: 'Delete a measurement unit',
					modalMessage:
						'Do you really want to delete this unit of measurement? The measurement unit will be permanently deleted',
				},
				group: {
					modalTitle: 'Delete a measurement unit group',
					modalMessage:
						'Do you really want to delete this measurement units group? The group and its measurement units will be permanently deleted.',
					agreement: 'I agree to delete the group and its measurement units.',
				},
			},
			add: {
				unit: {
					btnTitle: 'Add a measurment units',
				},
				group: {
					btnTitle: 'Add a measurment units group',
					editGroupTitle: 'Edit measurment units group',
					createGroupTitle: 'Create measurment units group',
				},
			},
		},
		hint: {
			baseUnit:
				'There can be only one basic measurement unit in a group of measurement units. The remaining measurement units are linked to the basic unit through a conversion factor or conversion formula. The conversion factor of the basic measurement units is 1.',
		},
		noGroups: "You haven't added any unit groups yet.",
		createNewGroup:
			'To create a new group, click on the «Add a measurement units group» button',
	},
	breadcrumbs: {
		metadata: 'Metadata',
	},
	profile: {
		goToProfileButton: 'Profile',
		logoutButton: 'Logout',
		userName: 'User name',
	},
	transactions: {
		errorGetList: 'Error retrieving transaction list!',
		addToCompareTitle: 'Compare',
	},
};

export default localization;
