import { UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { type QueryDefinition } from '@reduxjs/toolkit/query';
import { App } from 'antd';
import { useEffect } from 'react';
import { errorHelper } from '../helpers';
import { Localization } from '../translations';
import { useTypedTranslation } from './useTypedTranslation';

const useHandleQueryError = <D extends QueryDefinition<any, any, any, any>>(
	query: UseQueryHookResult<D>,
	titleFn: (l: Localization) => string
) => {
	const { notification } = App.useApp();
	const { t } = useTypedTranslation();

	const { error } = query;

	useEffect(() => {
		if (!error) {
			return;
		}

		errorHelper(
			t(titleFn),
			error,
			notification,
		);
	}, [ error ]);

	return query;
};

export {
	useHandleQueryError,
};