import { RiInformationLine } from '@remixicon/react';
import { App, Checkbox, Flex, Form, Input, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useEffect } from 'react';
import { TaskType, useAsyncOperation } from 'entities/events';
import {
	MeasurementResponse,
	UpdateMeasurementRequest,
	useUpdateMeasurementAsyncMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { transactionServiceApi } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { Hint } from 'shared/ui';

interface IEditMeasureUnitProps {
	unit: MeasurementResponse;
}

const EditMeasureUnitUi: React.FC<IEditMeasureUnitProps> = ({ unit }) => {
	const { t } = useTypedTranslation();

	const { notification } = App.useApp();
	const dispatch = useAppDispatch();

	const [ form ] = Form.useForm();

	const { execute: editUnits } = useAsyncOperation(
		useUpdateMeasurementAsyncMutation,
		TaskType.UPDATE_MEASUREMENT_BACKGROUND_TASK,
	);

	const editInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
		let value;
		switch (e.target.id) {
			case 'name':
				value = {
					displayName: e.target.value,
				};
				break;
			case 'code':
				value = {
					shortName: e.target.value,
				};
				break;
			case 'factor':
				value = {
					coefficient: +e.target.value,
				};
				break;
			case 'formula':
				value = {
					formula: e.target.value,
				};
				break;
			case 'revFormula':
				value = {
					inverseFormula: e.target.value,
				};
				break;
		}

		return editMeasureUnit({
			...unit,
			...value,
		});
	};

	const editBasic = async (e: CheckboxChangeEvent) => {
		return editMeasureUnit({
			isDefault: e.target.checked,
			displayName: unit.displayName,
		});
	};

	const editMeasureUnit = async (data: UpdateMeasurementRequest) => {
		try {
			await editUnits({
				measurementGroupId: unit.measurementGroupId,
				id: unit.id,
				updateMeasurementRequest: data,
			});
		} catch (error) {
			resetForm();
			errorHelper(
				t((l) => l.measures.error.editingMeasurmentUnit),
				error,
				notification,
			);
		} finally {
			dispatch(transactionServiceApi.util.invalidateTags([ 'Transaction' ]));
		}
	};

	const resetForm = () => {
		form.setFieldsValue({
			name: unit?.displayName,
			isBasic: unit?.isDefault,
			code: unit?.shortName,
			factor: unit?.isDefault ? 1 : unit?.coefficient,
			formula: unit?.isDefault ? '—' : unit?.formula,
			revFormula: unit?.isDefault ? '—' : unit?.inverseFormula,
		});
	};

	useEffect(() => {
		resetForm();
	}, [ unit ]);

	return (
		<Flex vertical gap={12} style={{ maxWidth: 920, marginBottom: 65 }}>
			<Typography.Title level={2}>{t((l) => l.measures.mainInfo)}</Typography.Title>
			<Form layout="vertical" form={form}>
				<Flex align="center" style={{ marginBottom: 12 }}>
					<Form.Item
						valuePropName="checked"
						name="isBasic"
						style={{ display: 'flex', alignItems: 'center', margin: 0 }}
					>
						<Checkbox onChange={editBasic} checked={unit?.isDefault}>
							{t((l) => l.measures.unit.basicUnit)}
						</Checkbox>
					</Form.Item>
					<Hint placement="right" title={t((l) => l.measures.hint.baseUnit)}>
						<RiInformationLine size={16} color={colors.grayIcon}/>
					</Hint>
				</Flex>

				<Form.Item label={t((l) => l.measures.unit.displayName)} name="name">
					<Input allowClear onBlur={editInput}/>
				</Form.Item>

				<Form.Item label={t((l) => l.measures.unit.shortName)} name="code">
					<Input allowClear onBlur={editInput}/>
				</Form.Item>
				<Form.Item label={t((l) => l.measures.unit.coefficient)} name="factor">
					<Input
						allowClear
						onBlur={editInput}
						disabled={unit?.isDefault || !!unit?.inverseFormula || !!unit?.formula}
						suffix={
							<Hint title={t((l) => l.measures.unit.coefficient)}>
								<RiInformationLine size={16} color={colors.grayIcon}/>
							</Hint>
						}
					/>
				</Form.Item>
				{/* Закомментил пока не добавят формулы*/}
				{/* <Form.Item label={t(l=>l.measures.unit.formula)} name="formula">
				<Input
					allowClear
					disabled={unit?.isDefault || !!unit?.coefficient}
					onBlur={editInput}
					suffix={
						<Hint title={t(l=>l.measures.unit.formula)}>
							<RiInformationLine size={16} color={colors.grayIcon} />
						</Hint>
					}
				/>
			</Form.Item> */}

				{/* <Form.Item label={t(l=>l.measures.unit.inverseFormula)} name="revFormula">
				<Input
					allowClear
					onBlur={editInput}
					disabled={unit?.isDefault || !!unit?.coefficient}
					suffix={
						<Hint title={t(l=>l.measures.unit.inverseFormula)}>
							<RiInformationLine size={16} color={colors.grayIcon} />
						</Hint>
					}
				/>
			</Form.Item> */}
			</Form>
		</Flex>
	);
};

export const EditMeasureUnit = React.memo(EditMeasureUnitUi);
