import { RemixiconComponentType, RiCalendarLine, RiFileList2Line, RiTable3 } from '@remixicon/react';
import { Flex } from 'antd';
import React, { useMemo } from 'react';
import { CatalogItemsViewType } from 'entities/catalogs';
import {
	CatalogAttributeDeclarationResponse,
	useGetCatalogAttributeDeclarationsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { useEnumStateViaSearchParam, useHandleQueryError } from 'shared/hooks';
import { IconButton } from 'shared/ui';

const viewTypeSearchParamKey = 'view';

const useCatalogItemsViewTypeInternal = () => useEnumStateViaSearchParam(
	CatalogItemsViewType,
	viewTypeSearchParamKey,
	CatalogItemsViewType.Table,
);

const useCatalogItemsViewType = () => useCatalogItemsViewTypeInternal()[0];

const viewTypesIcons: Record<CatalogItemsViewType, RemixiconComponentType> = {
	[CatalogItemsViewType.Table]: RiTable3,
	[CatalogItemsViewType.Gantt]: RiFileList2Line,
	[CatalogItemsViewType.Calendar]: RiCalendarLine,
};

const isEnabledGanttView = (attributes: CatalogAttributeDeclarationResponse[] | null | undefined) => {
	const dateTimeAttributes = attributes?.filter((x) => x.attribute.type === 'DATE_TIME');
	return dateTimeAttributes?.length === 2;
};

const isEnabledCalendarView = (attributes: CatalogAttributeDeclarationResponse[] | null | undefined) => {
	const dateTimeAttributes = attributes?.filter((x) => x.attribute.type === 'DATE_TIME');
	return dateTimeAttributes?.length === 1;
};

const ChangeCatalogItemsViewType = React.memo(({ catalogId }: any) => {
	const [ catalogRecordsViewType, setCatalogRecordsViewType ] = useCatalogItemsViewTypeInternal();

	const { data: attributes } = useHandleQueryError(
		useGetCatalogAttributeDeclarationsQuery({
			catalogId: catalogId,
		}),
		(l) => l.catalogs.records.declarationsListErr);

	const viewTypesButtonFactory = (viewType: CatalogItemsViewType) => ({
		type: viewType,
		icon: viewTypesIcons[viewType],
		onClick: () => {
			setCatalogRecordsViewType(viewType);
		},
	});

	const activeCatalogRecordsViewTypes = useMemo(() => {
		return [
			viewTypesButtonFactory(CatalogItemsViewType.Table),
			...(isEnabledGanttView(attributes)
				? [ viewTypesButtonFactory(CatalogItemsViewType.Gantt) ]
				: []),
			...(isEnabledCalendarView(attributes)
				? [ viewTypesButtonFactory(CatalogItemsViewType.Calendar) ]
				: []),
		];
	}, [ attributes, viewTypesButtonFactory ]);

	if (!activeCatalogRecordsViewTypes.length) {
		return;
	}

	return (
		<Flex gap={4}>
			{activeCatalogRecordsViewTypes.map((item) => (
				<IconButton key={item.type}
							Icon={item.icon}
							active={catalogRecordsViewType === item.type}
							onClick={item.onClick}
				/>
			))}
		</Flex>
	);
});

export {
	ChangeCatalogItemsViewType,
	useCatalogItemsViewType
};