import { RiSettings3Line } from '@remixicon/react';
import keycloak from 'keycloak';
import React from 'react';
import { useTypedTranslation } from 'shared/hooks';
import { styles } from './GoToUserProfileButton.styles';

export function GoToUserProfileButton() {
    const { t } = useTypedTranslation();

    const handleClick = () => {
        window.open(keycloak.createAccountUrl());
    };

    return (
        <a onClick={handleClick} style={styles}>
            <RiSettings3Line />
            {t(l => l.profile.goToProfileButton)}
        </a>
    );
}
