import { RiDeleteBinLine } from '@remixicon/react';
import { Form, Modal, Typography, Checkbox, Flex, Button, App } from 'antd';
import React, { useEffect, useState } from 'react';
import { TaskType, useAsyncOperation } from 'entities/events';
import { useDeleteAttributeAsyncMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { DropdownLink } from 'shared/ui';

interface IDeleteSimpleAttributeProps {
	ids: Array<string>;
	isButton?: boolean;
}

export const DeleteSimpleAttributeUi = ({
											ids,
											isButton,
										}: IDeleteSimpleAttributeProps) => {
	const { notification } = App.useApp();

	const [ form ] = Form.useForm();

	const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
	const [ isChecked, setIsChecked ] = useState<boolean>(false);
	const [ isDisabled, setIsDisabled ] = useState<boolean>(true);
	const [ loading, setLoading ] = useState(false);

	const { execute } = useAsyncOperation(
		useDeleteAttributeAsyncMutation,
		TaskType.DELETE_ATTRIBUTES_BACKGROUND_TASK,
	);

	const onFinish = async () => {
		try {
			setLoading(true);

			await execute({ ids });

			setIsChecked(false);
			setIsModalOpen(false);
			setIsDisabled(true);
		} catch (err) {
			errorHelper('Ошибка при удалении атрибута!', err, notification);
		} finally {
			setLoading(false);
		}
	};

	const showModal = () => setIsModalOpen(true);
	const handleOk = () => setIsModalOpen(false);
	const handleCancel = () => setIsModalOpen(false);
	const toggleCheck = () => setIsChecked(!isChecked);

	useEffect(() => {
		setIsDisabled(!ids?.length);
	}, [ ids ]);

	return (
		<>
			{isButton ? (
				<Button
					icon={<RiDeleteBinLine size={16}/>}
					onClick={showModal}
					type="text"
					disabled={isDisabled}
				>
					Удалить
				</Button>
			) : (
				<DropdownLink
					icon={<RiDeleteBinLine size={16}/>}
					title="Удалить"
					callback={showModal}
				/>
			)}

			<Modal
				title={
					ids?.length === 1 ? 'Удаление простого атрибута' : 'Удаление атрибутов'
				}
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={onFinish}>
					<Form.Item>
						<Typography.Text>
							{ids?.length === 1
								? 'Вы действительно хотите удалить данный атрибут? Атрибут и связанные декларации в справочниках будут удалены без возможности восстановления.'
								: 'Вы действительно хотите удалить данные атрибуты? Атрибуты и связанные декларации в справочниках будут удалены без возможности восстановления.'}
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Checkbox checked={isChecked} onChange={toggleCheck}>
							{ids?.length === 1
								? 'Я согласен удалить атрибут и связанные декларации в справочниках'
								: 'Я согласен удалить атрибуты и связанные декларации в справочниках'}
						</Checkbox>
					</Form.Item>
					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={handleCancel}>
							Отменить
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!isChecked}
						>
							Удалить
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};
