import { RiDeleteBinLine } from '@remixicon/react';
import { App, Button, Checkbox, Flex, Form, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { DisplayTreeNode } from 'entities/catalogs/catalogGroups/catalog.model';
import { deleteCatalogById, updateCatalogGroup } from 'entities/catalogs/catalogGroups/catalog.store';
import { TaskType, useAsyncOperation } from 'entities/events';
import { useTransactions } from 'entities/transactions';
import { useDeleteCatalogsAsyncMutation } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { errorHelper } from 'shared/helpers';
import { findCatalogById } from 'shared/helpers/findCatalogById';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { DropdownLink } from 'shared/ui';

interface IDeleteCatalogUi {
	id: string;
}

const DeleteCatalogUi: React.FC<IDeleteCatalogUi> = ({ id }) => {
	const [ isModalOpen, setIsModalOpen ] = useState<boolean>(false);
	const [ isChecked, setIsChecked ] = useState<boolean>(false);
	const [ loading, setLoading ] = useState<boolean>(false);
	const [ form ] = Form.useForm();

	const { notification } = App.useApp();
	const { getTransactions } = useTransactions();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { catalogGroupId } = useParams();
	const { catalogGroups: catalogs } = useAppSelector(
		(state) => state.entities.catalogs.catalogGroups,
	);

	const { execute: deleteCatalogAsync } = useAsyncOperation(
		useDeleteCatalogsAsyncMutation,
		TaskType.DELETE_CATALOGS_BACKGROUND_TASK,
	);

	const deleteCatalog = async () => {
		setLoading(true);

		const deletingCatalog = findCatalogById(catalogs as DisplayTreeNode[], id);

		const catalogId = (): string => {
			if (catalogGroupId === id) {
				return deletingCatalog.parentId || '';
			}
			if (findCatalogById([ deletingCatalog ], catalogGroupId) !== null) {
				return '';
			}
			return catalogGroupId || '';
		};

		try {
			await deleteCatalogAsync({ ids: [ id ] });

			dispatch(deleteCatalogById(id));
			navigate(`/${routes.catalogs.main}/${catalogId()}`);
			getTransactions();

			if (deletingCatalog.parentId) {
				const parentCatalog = findCatalogById(
					catalogs as DisplayTreeNode[],
					deletingCatalog.parentId,
				);
				const children = parentCatalog.children.filter((item) => item.id !== id);
				dispatch(
					updateCatalogGroup({
						...parentCatalog,
						...{
							children: [ ...children ],
							parent: children?.length > 0,
							isLeaf: !(children?.length > 0),
						},
					}),
				);
			}

			setIsChecked(false);
			setIsModalOpen(false);

		} catch (error) {
			errorHelper('Ошибка удаления справочника', error, notification);
		} finally {
			setLoading(false);
		}
	};

	const showModal = () => setIsModalOpen(true);

	const handleOk = () => setIsModalOpen(false);

	const handleCancel = () => {
		setIsChecked(false);
		setIsModalOpen(false);
	};

	const toggleCheck = () => setIsChecked(!isChecked);

	return (
		<>
			<DropdownLink
				icon={<RiDeleteBinLine size={16}/>}
				title="Удалить"
				callback={showModal}
			/>

			<Modal
				title="Удаление справочника"
				open={isModalOpen}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
			>
				<Form layout="vertical" form={form} onFinish={deleteCatalog}>
					<Form.Item>
						<Typography.Text>
							Вы действительно хотите удалить справочник? Справочник и входящие в него
							справочники будут удалены без возможности восстановления.
						</Typography.Text>
					</Form.Item>
					<Form.Item>
						<Checkbox checked={isChecked} onChange={toggleCheck}>
							Я согласен удалить справочник и входящие в него справочники
						</Checkbox>
					</Form.Item>
					<Flex gap={4} justify="flex-end">
						<Button type="text" onClick={handleCancel}>
							Отменить
						</Button>
						<Button
							htmlType="submit"
							type="primary"
							loading={loading}
							disabled={!isChecked}
						>
							Удалить
						</Button>
					</Flex>
				</Form>
			</Modal>
		</>
	);
};

export const DeleteCatalog = React.memo(DeleteCatalogUi);
