import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICatalogRecordsColumns } from '../types';

interface IUi {
	subMenuCollapsed: boolean;
	hasSider: boolean;
	catalogRecordsOriginalColumns: ICatalogRecordsColumns | null;
	catalogRecordsAjustedColumns: ICatalogRecordsColumns | null;
	backgroundTasks: Array<string>;
}

const initialState: IUi = {
	subMenuCollapsed: true,
	hasSider: false,
	catalogRecordsOriginalColumns: null,
	catalogRecordsAjustedColumns: null,
	backgroundTasks: [],
};

const uiSlice = createSlice({
	name: 'ui',
	initialState,
	reducers: {
		setSubMenuCollapsed(state, { payload }: PayloadAction<boolean>) {
			state.subMenuCollapsed = payload;
		},
		addSider(state) {
			state.hasSider = true;
		},
		removeSider(state) {
			state.hasSider = false;
		},

		setCatalogRecordsOriginalColumns(state, action: PayloadAction<ICatalogRecordsColumns>) {
			state.catalogRecordsOriginalColumns = action.payload;
		},
		removeStoreCatalogRecordsColumns(state) {
			state.catalogRecordsOriginalColumns = null;
		},

		setCatalogRecordsAjustedColumns(state, action: PayloadAction<ICatalogRecordsColumns>) {
			state.catalogRecordsAjustedColumns = action.payload;
		},
		removeCatalogRecordsAjustedColumns(state) {
			state.catalogRecordsAjustedColumns = null;
		},
		addBackgroundTask(state, action: PayloadAction<string>) {

			const set = new Set(state.backgroundTasks);
			set.add(action.payload);

			state.backgroundTasks = Array.from(set);
		},
		completeBackgroundTask(state, action: PayloadAction<string>) {
			const set = new Set(state.backgroundTasks);
			set.delete(action.payload);

			state.backgroundTasks = Array.from(set);
		}
	},
});

export const {
	setSubMenuCollapsed,
	addSider,
	removeSider,
	setCatalogRecordsOriginalColumns,
	removeStoreCatalogRecordsColumns,
	setCatalogRecordsAjustedColumns,
	removeCatalogRecordsAjustedColumns,
	addBackgroundTask,
	completeBackgroundTask,
} = uiSlice.actions;
export const uiReducer = uiSlice.reducer;
