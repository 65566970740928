import { RiFolderSettingsLine } from '@remixicon/react';
import {
	App,
	Button,
	ColorPicker,
	DatePicker,
	Flex,
	Form,
	Input,
	Modal,
	Spin,
	TreeSelectProps,
	Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useCatalogGroups } from 'entities/catalogs/catalogGroups/catalog.model';
import { useGetClassifiersGroups } from 'entities/classifiers/classifiersGroups/classifier.model';
import { TaskType, useAsyncOperation } from 'entities/events';
import { useGetCurrentMeausureGroup } from 'entities/metadata/measures';
import {
	CatalogAttributeDeclarationResponse,
	useLazyGetCatalogItemsQuery,
	useLazyGetClassifierItemsQuery,
	useUpdateCatalogAttributeDeclarationAsyncMutation,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper, isEmpty } from 'shared/helpers';
import { translateType } from 'shared/helpers/CellTypeParser';
import { CellTypesEnum } from 'shared/helpers/CellValueParser';
import { ItemValues } from 'shared/helpers/types';
import { useTypedTranslation } from 'shared/hooks';
import { colors } from 'shared/styles';
import { BooleanInput, DropdownTreeSelect, DropdownLink, DropdownSelect } from 'shared/ui/components';

interface ISetupAttribute {
	attributeDeclaration: CatalogAttributeDeclarationResponse;
}

type DefaultValue = string | number | boolean;

type Restrictions = {
	scopeIds: string[] | null;
	defaultValue: DefaultValue | DefaultValue[] | null; // todo: точно ли здесь может быть массив?
	mask: string | null;
	maxLength: string | number | null;
	accuracy: string | number | null;
	measurmentId: string | null;
}

const SetupAttributeDeclarationUi: React.FC<ISetupAttribute> = ({ attributeDeclaration }) => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();

	const { attribute, restrictions } = attributeDeclaration as unknown as {
		attribute: Omit<typeof attributeDeclaration['attribute'], 'restrictions'> & { restrictions: Restrictions };
		restrictions: Restrictions;
	};

	const [ form ] = Form.useForm();

	const [ isLoading, setIsLoading ] = useState(false);
	const [ isModalOpen, setIsModalOpen ] = useState(false);
	const [ linkData, setLinkData ] = useState<Omit<DefaultOptionType, 'label'>[]>([]);
	const [ scopeIdsList, setScopeIdsList ] = useState<Omit<DefaultOptionType, 'label'>[]>([]);
	const [ selectedScopeIds, setSelectedScopeIds ] = useState<Array<string>>([]);

	const [ getCatalogItems, { isFetching: isGetCatalogItemsLoading } ] =
		useLazyGetCatalogItemsQuery();
	const [ getClassifierItemsList, { error: itemsListError, isLoading: itemsListLoading } ] =
		useLazyGetClassifierItemsQuery();

	const { execute: addUpdateEvent3 } = useAsyncOperation(
		useUpdateCatalogAttributeDeclarationAsyncMutation,
		TaskType.UPDATE_CATALOG_ATTRIBUTE_DECLARATIONS_BACKGROUND_TASK,
	);

	const isRelationToClassifier =
		attribute.type === CellTypesEnum.RELATION &&
		attribute.restrictions.scopeIds?.length > 0;

	const isRelationToCatalogs =
		attribute.type === CellTypesEnum.RELATION &&
		!isRelationToClassifier;

	const {
		currentMeasureGroup,
		getData,
		loading: measuresLoading,
		error: measuresError,
	} = useGetCurrentMeausureGroup();

	const {
		catalogList,
		getCatalogs,
		error: catalogListErr,
		loading: catalogListLoading,
	} = useCatalogGroups();

	const {
		classifiersGroups,
		loading: classifierGroupsLoading,
		error: catalogError,
	} = useGetClassifiersGroups();

	const editData = async (attributeDeclaration: CatalogAttributeDeclarationResponse, v: ItemValues) => {
		try {
			setIsLoading(true);

			await addUpdateEvent3({
				id: attributeDeclaration.id,
				updateCatalogAttributeDeclarationRequest:
					{
						restrictions: {
							...v,
						} as unknown as { [key: string]: object; },
					},
			});
		} catch (error) {
			errorHelper(
				'Ошибка при редактировании декларации атрибута справочника!',
				error,
				notification,
			);
		} finally {
			setIsLoading(false);
		}
	};

	const showModal = () => setIsModalOpen(true);
	const handleCancel = () => setIsModalOpen(false);
	const handleChange = (value: Array<string>) => setSelectedScopeIds(value);

	const onFinish = async (values) => {
		const v = values.defaultValue;

		await editData(attributeDeclaration, {
			...values,
			...((attribute.type === CellTypesEnum.TEXT || attribute.type === CellTypesEnum.STRING) && {
				defaultValue: (v == null || v === '')
					? null
					: v,
			}),
			...(attribute.type === CellTypesEnum.COLOUR && {
				defaultValue: v == null
					? null
					: `#${v}`,
			}),
			...(attribute.type === CellTypesEnum.DATETIME && {
				defaultValue: (v == null || v === '')
					? null
					: dayjs(values.defaultValue).format(),
			}),
			...(attribute.type === CellTypesEnum.INT && {
				defaultValue: (v == null || v === '')
					? null
					: Number(v),
			}),
			...(attribute.type === CellTypesEnum.FLOAT && {
				defaultValue: (v == null || v === '')
					? null
					: parseFloat(v),
			}),
			...(attribute.type === CellTypesEnum.RELATION && {
				defaultValue: v
					?.map((item) => item.value)
					?.filter(x => !!x),
				scopeIds: selectedScopeIds,
			}),
		});
		setIsModalOpen(false);
	};

	const onLoadData: TreeSelectProps['loadData'] = ({ id }) => {
		return getClassifierItemsList({
			parentItemId: id,
		})
			.unwrap()
			.then((res) => {
				setLinkData((prev) => {
					return [
						...prev,
						...res.data.map((c) => ({
							id: c.id,
							pId: c.parentItemId,
							value: c.id,
							title: c.displayName || t(l => l.common.defaultNames.emptyName),
							isLeaf: !c.parent,
						})),
					];
				});
			});
	};

	const onLoadScopeIds: TreeSelectProps['loadData'] = ({ id }) => {
		return getCatalogs(id).then((res) => {
			setScopeIdsList((prev) => {
				return [
					...prev,
					...res.map((c) => ({
						id: c.id,
						pId: c.parentId,
						value: c.id,
						title: c.displayName || t(l => l.common.defaultNames.emptyName),
						isLeaf: !c.parent,
					})),
				];
			});
		});
	};

	useEffect(() => {
		const rawDefaultValue = restrictions.defaultValue;
		const relatedClassifierIds = attribute.restrictions.scopeIds;
		const relatedCatalogIds = restrictions.scopeIds;

		let defaultValue: any = rawDefaultValue;

		if (attribute.type === CellTypesEnum.DATETIME) {
			if (defaultValue != null) {
				const value = [ rawDefaultValue ].flat()[0];
				if (typeof value == 'string') {
					defaultValue = dayjs(new Date(value));
				}
			}
		} else if (attribute.type === 'RELATION') {
			if (defaultValue == null) {
				defaultValue = [];
			}
		}

		if (relatedClassifierIds?.length) {
			const classifierId = relatedClassifierIds[0];

			if (classifiersGroups) {
				const classifier = classifiersGroups
					.find((item) => item.id === classifierId);
				form.setFieldValue('scopeId', [
					{
						label: classifier.displayName,
						value: classifier.id,
					},
				]);
			}

			getClassifierItemsList({
				classifierId: classifierId,
				root: true,
			})
				.unwrap()
				.then((res) => {
					const options = res.data
						.map((c) => ({
							id: c.id,
							pId: c.parentItemId,
							value: c.id,
							title: c.displayName || t(l => l.common.defaultNames.emptyName),
							isLeaf: !c.parent,
						}));
					setLinkData(options);

					if (rawDefaultValue && Array.isArray(rawDefaultValue)) {
						form.setFieldValue(
							'defaultValue',
							options
								.filter((opt) =>
									rawDefaultValue.includes(opt.id),
								),
						);
					} else {
						form.setFieldValue('defaultValue', []);
					}
				});
		} else {
			if (isEmpty(scopeIdsList)) {
				setScopeIdsList(
					catalogList.map((item) => ({
						id: item.id,
						pId: item.parentId,
						value: item.id,
						title: item.displayName,
						isLeaf: !item.parent,
					})),
				);
				if (relatedCatalogIds && catalogList) {
					const ids = relatedCatalogIds
						.filter((id) => !!id);
					setSelectedScopeIds(ids);
				}
			}
		}

		form.setFieldsValue({
			mask: restrictions.mask,
			maxLength: restrictions.maxLength,
			accuracy: restrictions.accuracy,
			measurmentId: restrictions.measurmentId,
			defaultValue: defaultValue,
		});
	}, [ attributeDeclaration, classifiersGroups, catalogList ]);

	useEffect(() => {
		if (selectedScopeIds?.length > 0) {
			if (attribute.restrictions.scopeIds?.length > 0) {
				const promises = selectedScopeIds
					?.map((item) =>
						getClassifierItemsList({
							classifierId: item,
							root: true,
						}),
					);
				Promise.all(promises)
					.then((res) => {
						const data = res
							.flat(Infinity)
							.map((v) =>
								v.data.data.map((c) => ({
									id: c.id,
									pId: c.parentItemId,
									value: c.id,
									title: c.displayName,
									isLeaf: !c.parent,
								})),
							)
							.flat();

						setLinkData(data);
					});
			} else {
				getCatalogItems({
					catalogIds: selectedScopeIds,
				}).unwrap()
					.then(res => {
						const { data } = res;
						if (data) {
							setLinkData(data
								.map((c) => ({
									id: c.id,
									value: c.id,
									title: c.displayName || t(l => l.common.defaultNames.emptyName),
									isLeaf: true,
								})));
						}
					});
			}
		}
	}, [ selectedScopeIds ]);

	useEffect(() => {
		if (
			attribute.type === CellTypesEnum.FLOAT &&
			!currentMeasureGroup &&
			attribute.measurementGroupId
		) {
			getData(attribute.measurementGroupId);
		}
	}, [ currentMeasureGroup, attributeDeclaration ]);

	useEffect(() => {
		if (restrictions.defaultValue && linkData) {
			const opts = linkData.filter((item) =>
				(restrictions.defaultValue as Array<string>).includes(item.id),
			);
			opts
				? form.setFieldValue('defaultValue', opts)
				: form.setFieldValue('defaultValue', []);
		}
	}, [ linkData ]);

	return (
		<>
			<DropdownLink
				icon={<RiFolderSettingsLine size={16}/>}
				title="Настроить декларацию атрибута"
				callback={showModal}
			/>

			<Modal
				title={
					<Typography.Title level={1}>
						<Flex gap={4} justify="flex-start" vertical>
							Управление декларацией атрибута:
							<span style={{ color: colors.primary }}>
								{translateType(attribute?.type, 'AttributeTransfer')}
							</span>
						</Flex>
					</Typography.Title>
				}
				open={isModalOpen}
				footer={null}
				closable={false}
			>
				<Spin spinning={isLoading}>
					<Form layout="vertical" form={form} onFinish={onFinish}>
						{attribute.type === CellTypesEnum.STRING && (
							<>
								<Form.Item label="Маска ввода" name="mask">
									<Input/>
								</Form.Item>
								<Form.Item label="Максимальная длина" name="maxLength">
									<Input/>
								</Form.Item>
							</>
						)}
						{(attribute.type === CellTypesEnum.STRING || attribute.type === CellTypesEnum.TEXT) && (
							<Form.Item label="Значение по умолчанию" name="defaultValue">
								<Input allowClear/>
							</Form.Item>
						)}
						{attribute.type === CellTypesEnum.RELATION && (
							<Form.Item label="Значение по умолчанию" name="defaultValue">
								<DropdownTreeSelect
									info={
										!(restrictions.scopeIds as string[])?.length &&
										'Для выбора значения по умолчанию, пожалуйста, заполните поле "Область"'
									}
									treeCheckStrictly
									treeDataSimpleMode
									loading={itemsListLoading || isGetCatalogItemsLoading}
									loadData={onLoadData}
									errorText={
										itemsListError || itemsListError
											? 'Не удалось загрузить список записей классификаторов!'
											: ''
									}
									treeData={linkData}
									error={!!itemsListError || !!itemsListError}
								/>
							</Form.Item>
						)}
						{(attribute.type === CellTypesEnum.FLOAT || attribute.type === CellTypesEnum.INT) && (
							<Form.Item label="Значение по умолчанию" name="defaultValue">
								<Input type="number" allowClear/>
							</Form.Item>
						)}

						{attribute.type === CellTypesEnum.FLOAT && (
							<>
								<Form.Item label="Точность" name="accuracy">
									<Input/>
								</Form.Item>
								<Form.Item label="Единица измерения" name="measurmentId">
									<DropdownSelect
										loading={measuresLoading}
										error={
											!!measuresError || !attribute?.measurementGroupId
										}
										errorText={
											!attribute?.measurementGroupId
												? 'У атрибута нет назначенной группы единиц измерения!'
												: 'Не удалось загрузить список единиц измерения!'
										}
										disabled={!!measuresError}
										options={currentMeasureGroup?.childrenMeasures?.map(
											(measure) => ({
												label: measure.displayName,
												value: measure.id,
											}),
										)}
									/>
								</Form.Item>
							</>
						)}
						{attribute.type === CellTypesEnum.BOOLEAN && (
							<Form.Item
								label="Значение по умолчанию"
								name="defaultValue"
							>
								<BooleanInput/>
							</Form.Item>
						)}
						{attribute.type === CellTypesEnum.DATETIME && (
							<Form.Item label="Значение по умолчанию" name="defaultValue">
								<DatePicker/>
							</Form.Item>
						)}

						{isRelationToClassifier && (
							<Form.Item label="Область" name="scopeId">
								<DropdownSelect
									mode="multiple"
									options={
										!catalogError && attribute.restrictions.scopeIds
											? classifiersGroups?.map((item) => ({
												label: item.displayName,
												value: item.id,
											}))
											: catalogList.map((item) => ({
												label: item.displayName,
												value: item.id,
											}))
									}
									onChange={handleChange}
									loading={classifierGroupsLoading || catalogListLoading}
									error={!!catalogError}
									disabled={
										!!(attribute.restrictions.scopeIds as unknown as Array<string>)?.length
									}
								/>
							</Form.Item>
						)}

						{isRelationToCatalogs && (
							<Form.Item label="Область">
								<DropdownTreeSelect
									treeDataSimpleMode
									treeCheckStrictly
									loading={catalogListLoading}
									value={selectedScopeIds}
									loadData={onLoadScopeIds}
									onChange={(v) => {
										setSelectedScopeIds(v.map((item) => item.value));
									}}
									errorText={
										catalogListErr
											? 'Не удалось загрузить список справочников!'
											: ''
									}
									treeData={scopeIdsList}
									error={!!catalogListErr}
								/>
							</Form.Item>
						)}

						{attribute.type === CellTypesEnum.COLOUR && (
							<Form.Item
								label="Значение по умолчанию"
								name="defaultValue"
								getValueFromEvent={(color) => {
									return color.cleared
										? null
										: color.toHex();
								}}
							>
								<ColorPicker size="small" showText allowClear format="hex"/>
							</Form.Item>
						)}

						<Flex justify="flex-end" gap={4}>
							<Button type="text" onClick={handleCancel}>
								Отменить
							</Button>
							<Button type="primary" htmlType="submit" loading={isLoading}>
								Сохранить
							</Button>
						</Flex>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export const SetupAttributeDeclaration = React.memo(SetupAttributeDeclarationUi);
