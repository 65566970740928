import { RiDeleteBinLine } from '@remixicon/react';
import { App, Button } from 'antd';
import React, { useState } from 'react';
import { TaskType, useAsyncOperation } from 'entities/events';
import { useDeleteClassifierAsyncMutation } from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { DropdownLink } from 'shared/ui';

interface IDeleteClassifierProps {
	id: string;
	isButton?: boolean;
}

const DeleteClassifierUi: React.FC<IDeleteClassifierProps> = ({ isButton, id }) => {
	const { notification } = App.useApp();

	const [ loading, setLoading ] = useState<boolean>(false);

	const { execute: deleteItem } = useAsyncOperation(
		useDeleteClassifierAsyncMutation,
		TaskType.DELETE_CLASSIFIER_BACKGROUND_TASK
	);

	const handleDelete = async (): Promise<boolean> => {
		try {
			setLoading(true);

			await deleteItem({ ids: [id] });

			return true;
		} catch (error) {
			errorHelper('Не удалось удалить классификатор!', error, notification);
			return false;
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			{isButton ? (
				<Button
					icon={<RiDeleteBinLine size={16} />}
					onClick={handleDelete}
					loading={loading}
				>
					Удалить
				</Button>
			) : (
				<DropdownLink
					loading={loading}
					disabled={loading}
					icon={<RiDeleteBinLine size={16} />}
					title="Удалить"
					callback={handleDelete}
				/>
			)}
		</>
	);
};

export const DeleteClassifier = React.memo(DeleteClassifierUi);
