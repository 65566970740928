import { RiEditLine } from '@remixicon/react';
import { App } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { DeleteCatalogRecordUi } from 'features/catalogs/CatalogRecords/DeleteCatalogRecord';
import {
	CatalogAttributeDeclarationResponse,
	CatalogItemResponse,
	useLazyGetCatalogItemQuery,
	useLazyGetClassifierItemQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { CellTypesEnum, CellValueParser } from 'shared/helpers/CellValueParser';
import { ItemValuesType } from 'shared/helpers/types';
import { useTypedTranslation } from 'shared/hooks';
import { Chip, DropdownLink } from 'shared/ui';
import { ChipStatus } from 'shared/ui/components/Chip/chipStylehelper';
import ItemActions from 'shared/ui/components/ItemActions';
import i18next from '../../../i18n';

export type ICatalogRecord = {
	id: string;
	recStatus: JSX.Element;
	menu: JSX.Element;
	key: string;
};
export type ICatalogRecords = Array<ICatalogRecord>;

export interface IRecordValue {
	[key: string]: {
		attributeId: string;
		attributeName: string;
		value: ItemValuesType;
	};
}

export interface IMappedRecordValue {
	[key: string]: string | JSX.Element;
}

export type ItemStatusDto = 'NORMALIZED' | 'NON_NORMALIZED' | 'BLOCKED' | 'DELETED' | 'DUPLICATE';

export const chipTitle = (str: string): string => {
	switch (str) {
		case 'NORMALIZED':
			return i18next.t('common.statuses.normalized');

		case 'NON_NORMALIZED':
			return i18next.t('common.statuses.nonNormalized');

		case 'BLOCKED':
			return i18next.t('common.statuses.blocked');

		case 'DELETED':
			return i18next.t('common.statuses.deleted');

		case 'DUPLICATE':
			return i18next.t('common.statuses.dupe');

		default:
			'';
	}
};

export const useRecordsDtoToTableMapper = () => {
	const { t } = useTypedTranslation();
	const { notification } = App.useApp();
	const navigate = useNavigate();

	const [ getCatalogItem, { isFetching: isCatalogItemLoading } ] = useLazyGetCatalogItemQuery();
	const [ getClassifierItem, { isFetching: isClassifierItemLoading } ] = useLazyGetClassifierItemQuery();

	const convertValues = async (
		values: IRecordValue,
		attributes: CatalogAttributeDeclarationResponse[]
	): Promise<IMappedRecordValue> => {
		const result = {};
		const attributeDeclarationsList = attributes;

		if (values) {
			await Promise.all(
				Object.keys(values).map(async (id) => {
					const declaration = attributeDeclarationsList?.find((decl) => decl.id === id);
					if (declaration) {
						if (
							declaration.attribute.type === CellTypesEnum.RELATION &&
							values[id].value
						) {
							try {
								const request = (declaration.attribute.restrictions.scopeIds as string[])?.length > 0
									? getClassifierItem
									: getCatalogItem;
								const res = await request({
									id: values[id].value as unknown as string,
								}).unwrap();
								result[values[id].attributeId] = CellValueParser(
									res.displayName.length > 0
										? res.displayName
										: t((l) => l.common.defaultNames.emptyName),
									declaration.attribute.type as CellTypesEnum,
								);
							} catch (err) {
								errorHelper(
									t((l) => l.catalogs.records.recordsRelationsErr),
									err,
									notification,
								);
							}
						} else {
							result[values[id].attributeId] = CellValueParser(
								values[id].value,
								declaration.attribute.type as CellTypesEnum,
							);
						}
					}
				}),
			);
		}

		return result;
	};

	const mapRecordsDtoToTable = async (
		recordsDto: CatalogItemResponse[] | null,
		attributes: CatalogAttributeDeclarationResponse[]
	): Promise<ICatalogRecords> => {
		if (!recordsDto) {
			return null;
		}
		return await Promise.all(
			recordsDto.map(async (item) => ({
				id: item.id,
				key: item.id,
				catalogId: item.catalogId,
				recStatus: (
					<Chip status={item.status.toLowerCase() as ChipStatus}>
						{chipTitle(item.status)}
					</Chip>
				),
				displayName: item.displayName,
				menu: (
					<ItemActions
						items={[
							{
								key: '0',
								label: <DeleteCatalogRecordUi recordId={item.id}/>,
							},
							{
								key: '1',
								label: (
									<DropdownLink
										icon={<RiEditLine size={16}/>}
										title={t((l) => l.common.buttons.edit)}
										callback={() =>
											navigate(
												`${window.location.pathname}/record/${item.id}`,
											)
										}
									/>
								),
							},
						]}
					/>
				),
				...(await convertValues(item?.values as IRecordValue, attributes)),
			}))
		);
	};

	return {
		mapRecordsDtoToTable,
		isItemLoading: isCatalogItemLoading || isClassifierItemLoading,
	};
};