import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AttributeGroupResponse, AttributeGroupTreeNodeResponse } from 'shared/api/generatedApi/mdmgApi';

interface IAttributesGroups {
	metaAttributeGroups: Array<AttributeGroupResponse>;
	currentAttributeGroup: AttributeGroupResponse;
}

const initialState: IAttributesGroups = {
	metaAttributeGroups: [],
	currentAttributeGroup: null,
};

const attributesGroupsSlice = createSlice({
	name: 'attributesGroups',
	initialState,
	reducers: {
		setMetaAttributesGroups(state, { payload }: PayloadAction<Array<AttributeGroupResponse>>) {
			state.metaAttributeGroups = payload;
		},
		addMetaAttributesGroup(state, { payload }: PayloadAction<AttributeGroupResponse>) {
			state.metaAttributeGroups = [payload, ...state.metaAttributeGroups];
		},
		updateMetaAttributesGroup(state, { payload }: PayloadAction<AttributeGroupResponse>) {
			const updateItem = (
				arr: Array<AttributeGroupResponse>,
				item: AttributeGroupResponse
			): Array<AttributeGroupResponse> => {
				return arr.map((attr) => {
					if (attr.id === item.id) {
						return item;
					}
					if (
						'children' in attr &&
						attr.children &&
						Array.isArray(attr.children) &&
						attr.children.length
					) {
						return {
							...attr,
							children: updateItem(attr.children, item),
						};
					}
					return attr;
				});
			};
			state.metaAttributeGroups = updateItem(state.metaAttributeGroups, payload);
		},

		updateMetaAttributesGroupChildTreeItem(
			state,
			{ payload }: PayloadAction<{ item: AttributeGroupResponse; parentId: string }>
		) {
			const updateAttrGroupById = (
				arr: AttributeGroupTreeNodeResponse[],
				parentId: string,
				node: AttributeGroupTreeNodeResponse
			): AttributeGroupTreeNodeResponse[] => {
				return arr.map((item) => {
					if (item.id === parentId) {
						return {
							...item,
							parent: true,
							isLeaf: false,
							children: item.children ? [...item.children, node] : [node],
						};
					}

					if (item.children) {
						return {
							...item,
							children: updateAttrGroupById(item.children, parentId, node),
						};
					}
					return item;
				});
			};

			state.metaAttributeGroups = updateAttrGroupById(
				state.metaAttributeGroups,
				payload.parentId,
				payload.item
			);
		},
		deleteMetaAttributesGroup(state, { payload }: PayloadAction<string>) {
			const removeAttrGroupById = (
				arr: AttributeGroupTreeNodeResponse[],
				idToRemove: string
			): AttributeGroupTreeNodeResponse[] => {
				return arr
					.filter((item) => item.id !== idToRemove)
					.map((item) => {
						if (item.children) {
							return {
								...item,
								children: removeAttrGroupById(item.children, idToRemove),
							};
						}
						return item;
					});
			};

			state.metaAttributeGroups = removeAttrGroupById(state.metaAttributeGroups, payload);
		},
		setCurrentAttributeGroup(state, action: PayloadAction<AttributeGroupResponse>) {
			state.currentAttributeGroup = action.payload;
		},

		removeCurrentAttributeGroup(state) {
			state.currentAttributeGroup = null;
		},
	},
});

export const {
	setMetaAttributesGroups,
	addMetaAttributesGroup,
	updateMetaAttributesGroup,
	deleteMetaAttributesGroup,
	setCurrentAttributeGroup,
	updateMetaAttributesGroupChildTreeItem,
	removeCurrentAttributeGroup,
} = attributesGroupsSlice.actions;

export const attributesGroupsReducer = attributesGroupsSlice.reducer;
