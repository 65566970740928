import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClassifierItemResponse } from 'shared/api/generatedApi/mdmgApi';

export type ClassifierItem = ClassifierItemResponse & { children?: Array<ClassifierItem> };

interface IClassifierItemsListState {
	classifierItemsList: Array<ClassifierItemResponse>;
	classifierItemsTotal: number | null;
	currentClassifierItem: ClassifierItemResponse;
}

const initialState: IClassifierItemsListState = {
	classifierItemsList: null,
	classifierItemsTotal: null,
	currentClassifierItem: null,
};

const cascadeDeleteItems = <T extends { id: string, parentItemId?: string; }>(
	items: T[], id: string,
): [ T[], number ] => {
	const itemsForDelete = [ id ];
	for (let idx = 0; idx < itemsForDelete.length; idx++) {
		const parentId = itemsForDelete[idx];
		const childrenIds = items
			.filter(x => x.parentItemId == parentId)
			.map(x => x.id);
		itemsForDelete.push(...childrenIds);
	}
	return [
		items
			.filter(x =>
				!itemsForDelete.includes(x.id),
			),
		itemsForDelete.length,
	];
};

const classifierItemsSlice = createSlice({
	name: 'classifierItems',
	initialState,
	reducers: {
		setClassifierItems(state, action: PayloadAction<ClassifierItemResponse[]>) {
			state.classifierItemsList = [ ...action.payload ];
		},
		setClassifierItemsTotal(state, action: PayloadAction<number>) {
			state.classifierItemsTotal = action.payload;
		},
		setCurrentClassifierItem(state, { payload }: PayloadAction<ClassifierItemResponse>) {
			state.currentClassifierItem = payload;
			if (!!state.classifierItemsList && state.classifierItemsList.length && !!payload) {
				state.classifierItemsList = state.classifierItemsList.map((item) => {
					if (item?.id === payload.id) {
						return payload;
					}
					return item;
				});
			}
		},
		updateClassifierChildTreeItem(
			state,
			{ payload }: PayloadAction<{ item: ClassifierItemResponse; parentItemId: string }>,
		) {
			const updateCatalogGroupById = (
				arr: ClassifierItemResponse[],
				parentId: string,
			): ClassifierItemResponse[] => {
				return arr
					? arr.map((item) => {
						if (item.id === parentId) {
							return {
								...item,
								parent: true,
								isLeaf: false,
							};
						}

						return item;
					})
					: [];
			};

			state.classifierItemsList = updateCatalogGroupById(
				state.classifierItemsList,
				payload.parentItemId,
			);
		},
		addNewClassifierItem(state, action: PayloadAction<ClassifierItemResponse>) {
			state.classifierItemsList = [
				...(state.classifierItemsList && state.classifierItemsList),
				action.payload,
			];
			state.classifierItemsTotal = state.classifierItemsTotal + 1;
		},
		deleteClassifierItemById(state, { payload }: PayloadAction<string>) {
			if (state.currentClassifierItem?.id == payload) {
				state.currentClassifierItem = null;
			}

			state.classifierItemsList = state.classifierItemsList
				.filter((item) => item.id !== payload);

			const [ newItemsList, deletedItems ] = cascadeDeleteItems(state.classifierItemsList, payload);
			state.classifierItemsList = newItemsList;
			state.classifierItemsTotal = state.classifierItemsTotal - deletedItems;
		},
		removeCurrentClassifierItem(state) {
			state.currentClassifierItem = null;
		},
		removeClassifierItems(state) {
			state.classifierItemsList = null;
			state.classifierItemsTotal = null;
		},
	},
});

export const {
	setClassifierItems,
	setClassifierItemsTotal,
	setCurrentClassifierItem,
	updateClassifierChildTreeItem,
	deleteClassifierItemById,
	addNewClassifierItem,
	removeCurrentClassifierItem,
	removeClassifierItems,
} = classifierItemsSlice.actions;

export const classifierItemsReducer = classifierItemsSlice.reducer;
