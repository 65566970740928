import { RiAddCircleLine } from '@remixicon/react';
import { Button } from 'antd';
import React from 'react';
import { useTypedTranslation } from 'shared/hooks';
import { useCreateMeasureUnit } from './createMeasureUnit.model';

const CreateMeasureUnitUi = () => {
	const { t } = useTypedTranslation();
	const { createMeasureUnit, isLoading } = useCreateMeasureUnit();
	return (
		<Button
			type="default"
			icon={<RiAddCircleLine />}
			loading={isLoading}
			onClick={createMeasureUnit}
		>
			{t((l) => l.measures.actions.add.unit.btnTitle)}
		</Button>
	);
};

export const CreateMeasureUnit = React.memo(CreateMeasureUnitUi);
