import { Result, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { setAttributeDeclarationsList } from 'entities/catalogs/attributeDeclarations';
import { ICatalogRecords, useRecordsDtoToTableMapper } from 'entities/catalogs/catalogRecords/catalogRecords.model';
import {
	CatalogItemResponse,
	useGetCatalogAttributeDeclarationsQuery,
	useGetCatalogItemsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { useGetSearchResultQuery } from 'shared/api/generatedApi/searchApi';
import { routes } from 'shared/config';
import { useAppDispatch, useAppSelector, useHandleQueryError, usePagination, useTypedTranslation } from 'shared/hooks';
import { ICatalogRecordsColumns } from 'shared/types';
import { ColumnsAdjustmentWidget, Hint, setCatalogRecordsOriginalColumns, WorkTable } from 'shared/ui';

interface CatalogItemsTableWidgetProps {
	catalogId: string;
	isSubordinatedCatalogsRecordsShown: boolean;
}

const CatalogItemsTableWidget = React.memo(({
												catalogId,
												isSubordinatedCatalogsRecordsShown,
											}: CatalogItemsTableWidgetProps) => {
	const { t } = useTypedTranslation();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();

	const { mapRecordsDtoToTable } = useRecordsDtoToTableMapper();

	const [ searchParams ] = useSearchParams();
	const { pagination, handlePaginationChange, toTablePaginationProps } = usePagination();

	const [ catalogRecordsColumns, setCatalogRecordsColumns ] = useState<ICatalogRecordsColumns>([]);

	const searchValue = searchParams.get('searchValue');
	const isSearchValueEmpty = [ null, undefined, '' ].includes(searchValue);

	const {
		data: catalogItems,
		error: fetchGetItemsError,
		isFetching: isGetItemsLoading,
	} = useHandleQueryError(
		useGetCatalogItemsQuery({
			catalogIds: [ catalogId ],
			limit: pagination?.pageSize,
			page: pagination?.current,
			includeSubCatalogs: isSubordinatedCatalogsRecordsShown,
		}, {
			skip: !isSearchValueEmpty || !pagination,
		}),
		(l) => l.catalogs.records.recordsListErr,
	);

	const {
		data: catalogSearchItems,
		error: catalogSearchItemsError,
		isFetching: isSearchItemsLoading,
	} = useHandleQueryError(
		useGetSearchResultQuery({
			entityType: 'item',
			textRequest: searchValue,
			parentId: catalogId,
			includeSubCatalogs: isSubordinatedCatalogsRecordsShown,
		}, {
			skip: isSearchValueEmpty,
		}),
		(l) => l.catalogs.records.recordsListErr);

	const { data: attributeDeclarations, isLoading: isDeclarationsLoading } = useHandleQueryError(
		useGetCatalogAttributeDeclarationsQuery({
			catalogId: catalogId,
		}),
		(l) => l.catalogs.records.declarationsListErr);

	const ajustedColumns = useAppSelector(
		(state) => state.shared.ui.catalogRecordsAjustedColumns,
	);

	const [ catalogRecordsTableRows, setCatalogRecordsTableRows ] = useState<ICatalogRecords | null>(
		null,
	);

	useEffect(() => {
		if (attributeDeclarations) {
			const newColumns = [
				{
					title: t((l) => l.common.defaultNames.status),
					dataIndex: 'recStatus',
					key: 'recStatus',
					width: '175px',
				},
				{
					title: t((l) => l.common.defaultNames.name),
					dataIndex: 'displayName',
					key: 'displayName',
				},
				...Array.from(
					new Set(attributeDeclarations.map((decl) => decl.attribute.id)),
				).map((atrId) => {
					const decl = attributeDeclarations.find(
						(item) => item.attribute.id === atrId,
					);
					return {
						title: decl.attribute.displayName,
						dataIndex: decl.attribute.id,
						key: decl.id,
						width: 'auto',
					};
				}),
				{
					title: <ColumnsAdjustmentWidget/>,
					dataIndex: 'menu',
					key: 'menu',
					width: '50px',
				},
			];

			setCatalogRecordsColumns(
				ajustedColumns
					? ajustedColumns
						.map((item) =>
							item.key === 'menu'
								? { ...item, title: <ColumnsAdjustmentWidget/> }
								: { ...item, title: <Hint title={item.title}>{item.title}</Hint> },
						)
					: newColumns
						.map((item) =>
							item.key === 'menu'
								? item
								: { ...item, title: <Hint title={item.title}>{item.title}</Hint> },
						),
			);
			dispatch(setAttributeDeclarationsList(attributeDeclarations));

			const columns = newColumns.map((item) =>
				item.key === 'menu'
					? { ...item, title: t((l) => l.common.buttons.columnsSetup) }
					: item,
			);
			dispatch(setCatalogRecordsOriginalColumns(columns));
		}
	}, [ attributeDeclarations, ajustedColumns ]);

	useEffect(() => {
		if (!attributeDeclarations) {
			return;
		}

		if (!isSearchValueEmpty && catalogSearchItems) {
			if (catalogSearchItems) {
				mapRecordsDtoToTable(catalogSearchItems as CatalogItemResponse[], attributeDeclarations)
					.then((res) =>
						setCatalogRecordsTableRows(res),
					);
			}
		} else {
			if (catalogItems) {
				mapRecordsDtoToTable(catalogItems.data, attributeDeclarations)
					.then((res) =>
						setCatalogRecordsTableRows(res),
					);
			}
		}

	}, [ catalogItems, catalogSearchItems, attributeDeclarations ]);

	const catalogRecordLoading = isGetItemsLoading || isDeclarationsLoading || isSearchItemsLoading;
	const catalogRecordError = catalogSearchItemsError || fetchGetItemsError;

	const onClickItem = useCallback(
		(id: string | number, rowCatalogId?: string | number | undefined) =>
			navigate(`/${routes.catalogs.main}/${rowCatalogId || catalogId}/record/${id}`),
		[ navigate ],
	);

	if (catalogRecordLoading) {
		return <Spin size="large"/>;
	}

	if (catalogRecordError) {
		return <Result title={t((l) => l.catalogs.records.recordsListErr)}
					   subTitle={JSON.stringify(catalogRecordError)}
		/>;
	}

	return (
		<WorkTable columns={catalogRecordsColumns}
				   dataSource={catalogRecordsTableRows}
				   loading={catalogRecordLoading}
				   noFilters={true}
				   onRow={(r) => ({
					   onClick: () => {
						   onClickItem(r.id, r.catalogId);
					   },
				   })}
				   onChange={(pagination) => handlePaginationChange(pagination)}
				   pagination={toTablePaginationProps(isSearchValueEmpty
					   ? catalogItems?.meta?.total
					   : catalogSearchItems?.length)}
		/>
	);
});

export {
	CatalogItemsTableWidget,
};