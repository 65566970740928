import { Flex } from 'antd';
import React from 'react';
import { AddClassifierAttributeDeclaration } from 'features/classifiers/ClassifierRecords/AddClassifierAttributeDeclaration/AddClassifierAttributeDeclaration.ui';
import { ClassifierAttributeDeclarationsList } from 'features/classifiers/ClassifierRecords/ClassifierAttributeDeclarationsList';

const ClassifierAttributesWidgetUi: React.FC = () => {
	return (
		<Flex vertical gap={24}>
			<AddClassifierAttributeDeclaration />
			<ClassifierAttributeDeclarationsList />
		</Flex>
	);
};

export const ClassifierAttributesWidget = React.memo(ClassifierAttributesWidgetUi);
