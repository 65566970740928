import { Localization } from './types';

const localization: Localization = {
	common: {
		buttons: {
			confirm: 'Подтвердить',
			cancel: 'Отменить',
			back: 'Назад',
			delete: 'Удалить',
			edit: 'Редактировать',
			columnsSetup: 'Настройка колонок',
			create: 'Создать',
			selectAll: 'Выбрать все',
			save: 'Сохранить',
		},
		statuses: {
			normalized: 'Нормализована',
			nonNormalized: 'Не нормализована',
			blocked: 'Заблокирована',
			deleted: 'Удалена',
			dupe: 'Дубликат',
			error: 'Ошибка',
		},
		defaultNames: {
			emptyName: '<Название не заполнено>',
			status: 'Статус',
			name: 'Название',
			type: 'Тип',
			isMulti: 'Множественный',
			description: 'Описание',
			dataType: 'Тип данных',
			mainInfo: 'Общая информация',
			defaultValue: 'Значение по умолчанию',
			inputMask: 'Маска ввода',
			formula: 'Формула',
			maxLength: 'Максимальная длина',
			accuracy: 'Точность',
		},
		search: {
			nothingFound: 'По вашему запросу ничего не найдено',
			checkRequest: 'Проверьте, правильно ли введен ваш запрос, или попробуйте его изменить',
		},
		inputs: {
			emptyValueLabel: 'Отсутствует',
			trueValueLabel: 'Правда',
			falseValueLabel: 'Ложь',
		},
		tables: {
			total: 'Всего',
		},
		error: {
			message: 'Текст ошибки',
			emptyRequiresFields: 'Необходимо заполнить обязательные поля',
		},
	},
	catalogs: {
		mainInfo: 'Общая информация',
		catalogs: 'Справочники',
		catalog: 'Справочник',
		catalogInfo: 'Сведения о справочнике',
		attributesDeclarations: {
			attributesDeclarationsErr: 'Ошибка при получении списка деклараций!',
			addAttribute: 'Добавить атрибут',
			addAttributeDeclModal: 'Для добавления атрибута необходимо выбрать атрибут из списка',
			mappingCreated: 'Маппинг создан',
			mappingUpdated: 'Маппинг обновлен',
			mappingDeleted: 'Маппинг удален',
		},
		deduplication: {
			main: 'Дедубликация позиций',
			dupesGroupsErr: 'Ошибка при получении групп дубликатов',
			dupesSearchResult: 'Результаты поиска дубликатов',
			deleteDupesForSelectedItems: 'Удалить дубликаты для выбранных позиций',
			makeDupesForSelectedItems: 'Установить статус «Дубликат» для выбранных позиций',
			searchSaved: 'Вариант поиска сохранен',
			searchCompleted: 'Вариант поиска произведен',
			updateSearchErr: 'Ошибка обновления варианта поиска дубликатов',
			importSearch: 'Поиск при импорте',
			addCatalog: 'Добавление справочника',
			attributesList: 'Атрибуты, по которым осуществляется поиск дубликатов',
			addAttribute: 'Атрибуты, по которым осуществляется поиск дубликатов',
			selectAttribute: 'Для добавления атрибута необходимо выбрать атрибут из списка',
			startDupeSearch: 'Запустить поиск',
			dupeSearchResult: 'Просмотреть результаты поиска дубликатов',
			variantN: 'Вариант №',
			relaunchSearch: 'Перезапустить поиск',
			addNewVariant: 'Добавить вариант поиска дубликатов',
			isOriginal: 'Является оригиналом',
			setAsOriginal: 'Принять за оригинал',
			position: 'Позиция',
			source: 'Источник',
			unionAndDelete: 'Объединить и удалить дубликаты',
			setStatus: 'Установить статус «Дубликат»',
			deleteDupes: 'Удалить дубликаты для позиции',
			deleteSearchOption: 'Удалить',
		},
		groups: {
			noGroups: 'Нет справочных групп',
			selectGroup: 'Выберите справочную группу для отображения списка записей',
			currCatalogErr: 'Ошибка получения текущего справочника',
			catalogErr: 'Ошибка получения справочника',
			rootGroupErr: 'Ошибка при получении корневой группы справочников!',
			listErr: 'Ошибка при получении корневой группы справочников!',
			noGroupErr: 'Вы не добавили еще ни одной справочной группы',
			createNewGroup:
				'Для создания новой группы нажмите на кнопку «Добавить справочную группу»',
			createNestedGroup: 'Добавить вложенный справочник',
			addNewCatalog: 'Добавить новый справочник',
		},
		records: {
			recordsValues: 'Значения атрибутов',
			attributesSetup: 'Настройка атрибутов',
			editRecord: 'Редактирование записи',
			recordCard: 'Карточка позиции справочника',
			recordsRelationsErr: 'Ошибка получения записей для атрибута типа "Связь"!',
			recordsListErr: 'Ошибка при получении списка записей!',
			declarationsListErr: 'Ошибка получения деклараций',
			noRecords: 'Справочник не содержит позиций',
			addRecordTooltip:
				'Для добавления новой позиции нажмите на кнопку «Добавить справочную запись»',
			historyTabTitle: 'История изменений',
		},
		export: {
			exportCatalogTitle: 'Экспорт в Excel',
			exportCatalogTooltip:
				'Для экспорта справочных записей в Excel Вам необходимо выбрать экспортируемые колонки',
			exportCatalogBtn: 'Экспортировать',
			fetchErr: 'Ошибка получения данных для экспорта',
		},
		restrictions: {
			restrictions: 'Ограничения',
			restrictionsTableErr: 'Ошибка редактирования таблицы допустимых значений',
			declarationsTableErr: 'Ошибка редактирования таблицы допустимых значений',
			addTable: 'Добавление таблиц допустимых значений',
			noTableErr: 'Вы ещё не создали ни одной ограничительной таблицы',
		},
	},
	attributes: {
		attributeGroupErr: 'Ошибка получения группы атрибутов',
		attributeErr: 'Ошибка получения атрибута',
		gettingAttributeApplicabilityErr:
			'Ошибка при загрузке применимости атрибута в справочниках!',
		receivingDataForEditingAttributeErr:
			'Ошибка при получении данных для редактирования атрибута!',
		applicabilityinCatalogs: 'Применямость в справочниках',
		unusedAttribute: 'Атрибут в справочниках не применяется',
		applicabilityListError: 'Ошибка при получении списка применимости в справочниках!',
	},
	measures: {
		mainInfo: 'Общая информация',
		measurementUnit: 'Единица измерения',
		group: {
			displayName: 'Наименование группы единицы измерения',
			description: 'Описание',
			groupTitle: 'Группа единиц измерения',
			groupsTitle: 'Группы единиц измерения',
		},
		unit: {
			displayName: 'Наименование',
			shortName: 'Обозначение',
			basicUnit: 'Базовая ЕИ',
			formula: 'Формула пересчета',
			inverseFormula: 'Обратная формула пересчета',
			coefficient: 'Коэффициент пересчета',
			noCoefficient: '<Не задан>',
			isBasic: {
				true: 'Да',
				false: 'Нет',
			},
			cardTitle: 'Карточка единицы измерения',
		},
		error: {
			gettingMeasurmentUnits: 'Ошибка при получении единиц измерения!',
			editingMeasurmentUnit: 'Ошибка при изменении значения ЕИ!',
			designatingSecondBaseUnit: 'Выбор второй базовой ЕИ невозможен',
			gettingDataToSelectUnits: 'Не удалось получить данные для выбора Единиц измерения!',
		},
		actions: {
			delete: {
				unit: {
					modalTitle: 'Удаление единицы измерения',
					modalMessage:
						'Вы действительно хотите удалить данную единицу измерения? Единица измерения будет удалена без возможности восстановления',
				},
				group: {
					modalTitle: 'Удаление группы единиц измерения',
					modalMessage:
						'Вы действительно хотите удалить данную группу единиц измерения? Группа и входящие в нее единицы измерения будут удалены без возможности восстановления.',
					agreement:
						'Я согласен удалить группу единиц измерения и входящие в нее единицы измерения',
				},
			},
			add: {
				unit: {
					btnTitle: 'Добавить единицу измерения',
				},
				group: {
					btnTitle: 'Добавить группу единиц измерения',
					editGroupTitle: 'Редактирование группы единицы измерения',
					createGroupTitle: 'Создание группы единицы измерения',
				},
			},
		},
		hint: {
			baseUnit:
				'В группе единиц измерения может быть только одна базовая ЕИ. Остальные ЕИ связываются с базовой через коэффициент пересчёта или формулу пересчёта. Для базовых ЕИ коэффициент пересчёта равен 1.',
		},
		noGroups: 'Вы не добавили еще ни одной группы единиц измерения',
		createNewGroup:
			'Для создания новой группы нажмите на кнопку «Добавить группу единиц измерения»',
	},
	breadcrumbs: {
		metadata: 'Метаданные',
	},
	profile: {
		goToProfileButton: 'Профиль',
		logoutButton: 'Выйти',
		userName: 'Имя пользователя',
	},
	transactions: {
		errorGetList: 'Ошибка при получении списка транзакций!',
		addToCompareTitle: 'Добавить к сравнению',
	},
};

export default localization;
