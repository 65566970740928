import { exportApi } from './generatedApi/exportApi';
import { mdmgApi } from './generatedApi/mdmgApi';
import { transactionServiceApi } from './generatedApi/transactionServiceApi';

mdmgApi.enhanceEndpoints({
	endpoints: {
		createCatalog: {
			invalidatesTags: [ 'Catalog' ],
		},
		getCatalog: {
			providesTags: [ 'Transactional', 'Catalog' ],
		},
		createCatalogItem: {
			invalidatesTags: [ 'CatalogItems' ],
		},
		getCatalogItems: {
			providesTags: [ 'Transactional', 'CatalogItems' ],
		},
		getCatalogItem: {
			providesTags: [ 'Transactional', 'CatalogItems' ],
		},
		getClassifier: {
			providesTags: [ 'Transactional', 'Classifier' ],
		},
		getClassifiers: {
			providesTags: [ 'Transactional', 'Classifier' ],
		},
		getClassifierItem: {
			providesTags: [ 'Transactional', 'ClassifierItems' ],
		},
		getClassifierItems: {
			providesTags: [ 'Transactional', 'ClassifierItems' ],
		},
		getCatalogAttributeDeclaration: {
			providesTags: [ 'Transactional', 'CatalogAttributeDeclarations' ],
		},
		getCatalogAttributeDeclarations: {
			providesTags: [ 'Transactional', 'CatalogAttributeDeclarations' ],
		},
		getClassifierAttributeDeclarations: {
			providesTags: [ 'Transactional', 'ClassifierAttributeDeclarations' ],
		},
		getAttributeGroup1: {
			providesTags: [ 'Transactional', 'AttributeGroups' ],
		},
		createAttribute: {
			invalidatesTags: [ 'Transactional', 'Attributes' ],
		},
		getAttributes: {
			providesTags: [ 'Transactional', 'Attributes' ],
		},
		getAttribute: {
			providesTags: [ 'Transactional', 'Attributes' ],
		},
		getMeasurement: {
			providesTags: [ 'Transactional', 'MeasureUnits' ],
		},
		getMeasurements: {
			providesTags: [ 'Transactional', 'MeasureUnits' ],
		},
		importXlsx: {
			invalidatesTags: [ 'CatalogItems' ],
		},
	},
});

transactionServiceApi.enhanceEndpoints({
	endpoints: {
		getTransactionData: {
			providesTags: [ 'Transaction' ],
		},
		getTransactionsData: {
			providesTags: [ 'Transaction' ],
		},
	},
});

exportApi.enhanceEndpoints({
	endpoints: {
		exportItemsXlsx: {
			query: (queryArg) => ({
				url: `/api/v1/catalogs/${queryArg.id}/items/export`,
				params: { attributeDeclarationIds: queryArg.attributeDeclarationIds },
				cache: 'no-cache',
				responseHandler: async (response) => {
					const blobFile = await response.blob();
					return {
						fileUrl: window.URL.createObjectURL(blobFile),
						filenameHeader: response.headers.get('Content-Disposition'),
					};
				},
			}),
		},
	},
});