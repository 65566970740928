import { App } from 'antd';
import React from 'react';
import { StompProvider } from 'shared/stomp/StompProvider';
import { AuthProvider } from './AuthProvider';
import { AppRouter } from './RouterProvider';
import { StoreProvider } from './StoreProvider';
import { ThemeProvider } from './ThemeProvider';

export const Providers = () => (
	<AuthProvider>
		<StoreProvider>
			<ThemeProvider>
				<StompProvider config={{ brokerURL: process.env.REACT_APP_STOMP_URL }}>
					<App>
						<AppRouter />
					</App>
				</StompProvider>
			</ThemeProvider>
		</StoreProvider>
	</AuthProvider>
);
