import { App } from 'antd';
import { setTransactionRollback } from 'entities/transactions/transactions.store';
import { mdmgApi } from 'shared/api/generatedApi/mdmgApi';
import { useDeleteTransactionMutation } from 'shared/api/generatedApi/transactionServiceApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch } from 'shared/hooks';

export const useDeleteTransaction = () => {
	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const [ fetchDeleteTransaction ] = useDeleteTransactionMutation();

	const deleteTransaction = async (id: string) => {
		try {
			const result = await fetchDeleteTransaction({ id });

			if ('error' in result) {
				errorHelper('Ошибка при удалении транзакции!', result.error, notification);
			}

			if ('data' in result) {
				dispatch(setTransactionRollback());
				dispatch(mdmgApi.util.invalidateTags([ 'Transactional' ]));
				notification.success({ message: 'Транзакция удалена' });
			}
		} catch (err) {
			errorHelper('Ошибка при удалении транзакции!', err, notification);
		}
	};

	return { deleteTransaction };
};
