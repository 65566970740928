import { Card, Flex } from 'antd';
import { MenuProps } from 'antd/lib';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Hint } from '../Hint';
import ItemActions from '../ItemActions';
import { cardGridFirstStyles, cardGridLastItem, cardGridMiddleStyles } from './detailCard.styles';

interface IDetailCardProps {
	firstSection?: ReactNode;
	secondSection: ReactNode;
	dropdownButtons?: MenuProps['items'];
	link?: string;
	tooltip?: string;
}

const DetailCardUi: React.FC<IDetailCardProps> = ({
	firstSection,
	secondSection,
	dropdownButtons,
	link,
	tooltip,
}) => {
	return (
		<Hint title={tooltip ? tooltip : ''}>
			<Card hoverable style={{ width: '325px', height: 'fit-content' }}>
				<Flex>
					{firstSection && (
						<Card.Grid hoverable={false} style={cardGridFirstStyles}>
							{link ? <Link to={link}>{firstSection}</Link> : <>{firstSection}</>}
						</Card.Grid>
					)}

					<Card.Grid hoverable={false} style={cardGridMiddleStyles}>
						{link ? <Link to={link}>{secondSection}</Link> : <>{secondSection}</>}
					</Card.Grid>
					{dropdownButtons && (
						<Card.Grid hoverable={false} style={cardGridLastItem}>
							<ItemActions items={dropdownButtons} />
						</Card.Grid>
					)}
				</Flex>
			</Card>
		</Hint>
	);
};

export const DetailCard = React.memo(DetailCardUi);
