import { Flex, Typography } from 'antd';
import React, { useEffect } from 'react';
import { ClassifiersListWidget } from 'widgets/Classifiers/ClassifiersListWidget';
import { Transactions } from 'widgets/Transactions';
import { CreateClassifierGroup } from 'features/classifiers/createClassifierGroup';
import { SearchClassifiersGroup } from 'features/classifiers/searchClassifiersGroup';
import { useAppDispatch } from 'shared/hooks';
import { setSubMenuCollapsed } from 'shared/ui';

export const ClassifierPage: React.FC = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setSubMenuCollapsed(false));
		return () => {
			dispatch(setSubMenuCollapsed(true));
		};
	}, []);

	return (
		<Flex vertical gap={24}>
			<Flex justify="space-between" vertical style={{ width: '100%' }}>
				<Flex vertical gap={12} style={{ width: '100%' }}>
					<Flex vertical gap={12} style={{ width: '100%' }}>
						<Flex justify="space-between">
							<Typography.Title level={1}>Записи классификатора</Typography.Title>
							<Transactions />
						</Flex>
						<SearchClassifiersGroup />
						<CreateClassifierGroup />
					</Flex>
				</Flex>

				<ClassifiersListWidget />
			</Flex>
		</Flex>
	);
};
