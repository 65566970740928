import { Flex } from 'antd';
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { AddAttributeGroup } from 'features/metadata/addAttributeGroup/addAttributeGroup.ui';
import { DeleteAttributeGroup } from 'features/metadata/deleteAttributeGroup';
import { EditAttributeGroup } from 'features/metadata/editAttributeGroup';
import { DisplayTreeNode } from 'entities/catalogs/catalogGroups/catalog.model';
import {
	AttributeGroupItem,
	useMetaAttributesGroups,
} from 'entities/metadata/attributesGroups/attributesGroups.model';
import { AttributeGroupResponse, AttributeGroupTreeNodeResponse } from 'shared/api/generatedApi/mdmgApi';
import { routes } from 'shared/config';
import { truncateString } from 'shared/helpers';
import { useAppSelector } from 'shared/hooks';
import ItemActions from 'shared/ui/components/ItemActions';

export const useMetadataTreeData = () => {
	const { attrList, currAttrGroup, getAttributes, getGroups, loading, attributesGroupsError } =
		useMetaAttributesGroups();

	const [selectedTreeItem, setSelectedTreeItem] = useState<DisplayTreeNode | null>(null);
	const navigate = useNavigate();
	const { metaAttributeGroupId } = useParams();

	const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

	const [searchParams] = useSearchParams();

	const { transactionRollback } = useAppSelector((state) => state.entities.transactions);

	const handleExpand = (ids: React.Key[]) => setExpandedKeys(ids);

	const formatToTree = (data: Array<AttributeGroupResponse | AttributeGroupTreeNodeResponse>) => {
		return data?.map((attributeGroup) => {
			return {
				...attributeGroup,
				key: attributeGroup.id,
				title: truncateString(attributeGroup?.displayName, 30),
				isLeaf: !attributeGroup.parent,
				...(attributeGroup?.parent &&
					'children' in attributeGroup &&
					attributeGroup?.children?.length && {
						children: formatToTree(attributeGroup.children),
					}),
			};
		});
	};

	const findAttrGroupById = (
		tree: DisplayTreeNode[],
		attributeGroupGroupId: string
	): DisplayTreeNode | null => {
		for (const item of tree) {
			if (item.id === attributeGroupGroupId) {
				return item;
			}
			if (item.children) {
				const found = findAttrGroupById(item.children, attributeGroupGroupId);
				if (found) {
					return found;
				}
			}
		}
		return null;
	};

	const selectCurrentAttributeGroup = (attributeGroup: DisplayTreeNode | null) => {
		const searchValue = searchParams.get('searchParentValue');
		if (attributeGroup) {
			navigate(
				`/${routes.metadata.main}/${routes.metadata.group}/${attributeGroup.id}?${searchValue ? `searchParentValue=${searchValue}` : ''}`
			);
			const item = findAttrGroupById(formatToTree(attrList), attributeGroup.id);
			setSelectedTreeItem(item);
		} else {
			navigate(
				`/${routes.metadata.main}/${routes.metadata.group}?${searchValue ? `searchParentValue=${searchValue}` : ''}`
			);
			setSelectedTreeItem(null);
		}
	};

	const findExpandedSearchKeys = (treeData) => {
		const keys = [];

		const traverse = (nodes) => {
			for (const node of nodes) {
				if (node.children) {
					keys.push(node.key);
					traverse(node.children);
				}
			}
			return false;
		};

		traverse(treeData);
		return keys;
	};

	const addMenuToTreeNodeItems = (arr: Array<AttributeGroupItem>) => {
		return arr.map((item) => ({
			...item,
			title: (
				<Flex justify="space-between" align="center" style={{ width: '100%' }}>
					{truncateString(item.displayName, 30)}
					<ItemActions
						items={[
							{
								label: <EditAttributeGroup group={item} />,
								key: '1',
							},
							{
								label: <AddAttributeGroup groupId={item.id} />,
								key: '2',
							},
							{
								label: <DeleteAttributeGroup groupId={item.id} />,
								key: '3',
							},
						]}
					/>
				</Flex>
			),
			...(item.children && { children: addMenuToTreeNodeItems(item.children) }),
		}));
	};

	const findExpandedKeys = (treeData, targetKey) => {
		let keys = [];

		const traverse = (nodes, parentKeys = []) => {
			for (const node of nodes) {
				const currentKeys = [...parentKeys, node.key];
				if (node.key === targetKey) {
					keys = currentKeys;
					return true;
				}
				if (node.children && traverse(node.children, currentKeys)) {
					return true;
				}
			}
			return false;
		};

		traverse(treeData);
		return keys;
	};

	const onExpandTree = async (parentId: string, hasChildren: boolean = false) => {
		if (hasChildren) return;
		await getAttributes(parentId);
	};

	useEffect(() => {
		if (attrList?.length) {
			const formattedTree = formatToTree(attrList);
			if (searchParams.get('searchParentValue')) {
				setExpandedKeys(findExpandedSearchKeys(formattedTree));
				currAttrGroup && setSelectedTreeItem(formatToTree([currAttrGroup])[0]);
			} else if (currAttrGroup) {
				setSelectedTreeItem(formatToTree([currAttrGroup])[0]);
				setExpandedKeys(findExpandedKeys(formattedTree, currAttrGroup.id));
			}
		}
	}, [attrList]);

	useEffect(() => {
		if (!metaAttributeGroupId) {
			setSelectedTreeItem(null);
		}
	}, [metaAttributeGroupId, selectedTreeItem]);

	useEffect(() => {
		getGroups();
	}, [transactionRollback]);

	return {
		selectedTreeItem,
		selectCurrentAttributeGroup,
		onExpandTree,
		loading,
		error: attributesGroupsError,
		expandedKeys,
		handleExpand,
		formatToTree,
		addMenuToTreeNodeItems,
		attrList,
	};
};
