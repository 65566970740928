const SUPERSET_DOMAIN = process.env.REACT_APP_SUPERSET_DOMAIN ?? '';
const DASHBOARD_ID = process.env.REACT_APP_SUPERSET_DASHBOARD_ID ?? '';
const TOKEN_ENDPOINT = process.env.REACT_APP_SUPERSET_TOKEN_ENDPOINT ?? '';

export const dashboardApi = {
  fetchGuestToken: () => 
    fetch(TOKEN_ENDPOINT)
      .then(res => res.json())
      .then(res => res.token),

  getDashboardConfig: (mountPoint: HTMLElement) => ({
    id: DASHBOARD_ID,
    supersetDomain: SUPERSET_DOMAIN,
    dashboardUiConfig: {
      hideTitle: true,
      hideChartControls: false,
      filters: {
        expanded: false
      },
    },
    mountPoint,
    fetchGuestToken: dashboardApi.fetchGuestToken,
  })
};
