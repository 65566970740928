const changeSearchParam = (searchParams, key, value) => {
	const newSearchParams = new URLSearchParams(searchParams);
	if (value !== undefined && value !== null) {
		newSearchParams.set(key, String(value));
	} else {
		newSearchParams.delete(key);
	}
	return newSearchParams;
};

const changeSearchParams = (searchParams, params: Record<string, string | number | null | undefined>) => {
	const newSearchParams = new URLSearchParams(searchParams);

	Object
		.entries(params)
		.forEach(([ key, value ]) => {
			if (value !== undefined && value !== null) {
				newSearchParams.set(key, String(value));
			} else {
				newSearchParams.delete(key);
			}
		});
	return newSearchParams;
};

export {
	changeSearchParam,
	changeSearchParams,
};