import { App } from 'antd';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import {
	useLazyGetRestrictionTableQuery,
	useLazyGetRestrictionTableItemsQuery,
} from 'shared/api/generatedApi/mdmgApi';
import { errorHelper } from 'shared/helpers';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setConstraintTableInfo, setConstraintTableItems } from './constraintTableDetails.store';

export const useConstraintTableDetails = () => {
	const { constraintTableId } = useParams();

	const { notification } = App.useApp();

	const dispatch = useAppDispatch();

	const [fetchRestrictionTable, { isFetching: isTableInfoLoading }] =
		useLazyGetRestrictionTableQuery();

	const [fetchRestrictionTableItems, { isFetching: isTableItemsLoading }] =
		useLazyGetRestrictionTableItemsQuery();

	const {
		table,
		columns,
		tableItems: restrictionTableItems,
	} = useAppSelector((state) => state.entities.metadata.constraintTableDetails);

	const { transactionRollback } = useAppSelector((state) => state.entities.transactions);

	const getRestrictionTable = async () => {
		await fetchRestrictionTable({
			id: constraintTableId,
		})
			.unwrap()
			.then((res) => dispatch(setConstraintTableInfo(res)))
			.catch((err) =>
				errorHelper('Ошибка получения ограничительной таблицы!', err, notification)
			);
	};

	const getRestrictionTableItems = async () => {
		await fetchRestrictionTableItems({ restrictionTableId: constraintTableId })
			.unwrap()
			.then((res) => {
				dispatch(setConstraintTableItems(res));
			})
			.catch((err) =>
				errorHelper('Ошибка получения позиций ограничительной таблицы!', err, notification)
			);
	};

	const getRestrictionTableData = () => {
		getRestrictionTable();
		getRestrictionTableItems();
	};

	useEffect(() => {
		if (!restrictionTableItems) getRestrictionTableItems();
		if (!table || table.id !== constraintTableId) {
			getRestrictionTable();
		}
	}, [table]);

	useEffect(() => {
		getRestrictionTableData();
	}, [transactionRollback]);

	return {
		tableColumns: columns,
		restrictionTableItems,
		isTableInfoLoading: isTableInfoLoading || isTableItemsLoading,
	};
};
