import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CatalogItemResponse } from 'shared/api/generatedApi/mdmgApi';
import { PaginationModel } from 'shared/models';

interface ICatalogRecordsState {
	catalogRecords: CatalogItemResponse[] | null;
	pagination: PaginationModel;
}

const initialState: ICatalogRecordsState = {
	catalogRecords: null,
	pagination: {
		pageSizeOptions: [20, 50, 100, 200],
		current: 1,
		pageSize: 20,
		total: 0,
	},
};

interface IDeleteRecordAction {
	id: string;
}

const catalogRecordsSlice = createSlice({
	initialState,
	name: 'catalogRecords',
	reducers: {
		deleteCatalogRecord(state, action: PayloadAction<IDeleteRecordAction>) {
			state.catalogRecords = state.catalogRecords.filter(
				(record) => record.id !== action.payload.id
			);
		},
		addCatalogRecord(state, action: PayloadAction<CatalogItemResponse>) {
			state.catalogRecords = [
				action.payload,
				...(state.catalogRecords ? state.catalogRecords : []),
			];
		},
		decreasePaginationTotal(state) {
			state.pagination = {
				...state.pagination,
				total: state.pagination.total - 1,
			};
		},
	},
});

export const {
	deleteCatalogRecord,
	addCatalogRecord,
	decreasePaginationTotal,
} = catalogRecordsSlice.actions;

export const catalogRecordsReducer = catalogRecordsSlice.reducer;
